import React from 'react';
import { Link, Tooltip } from "@mui/material";
import { useTable } from 'react-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Warning from '@mui/icons-material/WarningAmberOutlined';

import { env } from '../env';


export default function EmplAttachmentsTable({ attachments, employerId, onEditAttachment, onDeleteAttachment, role }) {
  const locale = Intl.NumberFormat().resolvedOptions().locale;

  // 1. Create Columns
  const columns = React.useMemo(() => {
    const getUTCDate = (value) => {
      if (!value) return '';
      const date = new Date(value);
      const utc = convertDateToUTC(date);
      return `${utc.toLocaleDateString(locale, {dateStyle:'medium'})}`;
    }

    const convertDateToUTC = (date) => {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    return [{
       Header: 'Name',
       accessor: 'name',
       Cell: ({value, row}) => {
         const url = `${env.baseUrl}/v1/employer/${employerId}/attachment/${row.original.id}/download`;
         return <Link href={url} target="_blank" rel="noreferrer" download underline="hover">{value}</Link>;
       }
     },
     {
       Header: 'Category',
       accessor: 'category',
       Cell: ({value, row}) => {
         let category = value;
         const invoice = row.original.invoice;
         if (invoice) {
           category += ` ${invoice}`;
         }
         return <>{category}</>;
       }
     },
     {
       Header: 'Notes',
       accessor: 'notes'
     },
     {
       Header: 'Terms',
       accessor: 'terms'
     },
     {
       Header: 'Executed',
       accessor: 'execution',
       Cell: ({value, row}) => {
         if (!value) return '--';
         return getUTCDate(value);
       }
     },
     {
       Header: 'Expiration',
       accessor: 'expiration',
       Cell: ({value, row}) => {
         if (!value) return '--';
         const { category, name } = row.original;
         const expiration = new Date(value);
         const expirationDate = getUTCDate(expiration);
         const now = Date.now();
         const twoWeeks = 2*7*24*60*60*1000;

         if (expiration.getTime() < now) {
           const msg = `${category || ''} ${name} expired on ${expirationDate}`;
           return <Tooltip title={msg} arrow><span style={{ display: 'flex', alignItems: 'center' }}><Warning sx={{color:'red'}}/> <strong>{expirationDate}</strong></span></Tooltip>;

         } else if (expiration.getTime() < (now + twoWeeks)) { 
           const msg = `${category || ''} ${name} will be expired on ${expirationDate}`;
           return <Tooltip title={msg} arrow><span style={{ display: 'flex', alignItems: 'center' }}><Warning sx={{color:'orange'}}/> <strong>{expirationDate}</strong></span></Tooltip>;
         } 
         return expirationDate;
       }
     }, {
       Header: 'Actions',
       Cell: ({row}) => {
         return (role === 'Manager' || role === 'Administrator')
           ? <div>
               <EditIcon onClick={(event) => onEditAttachment(event, row.original, row.index)} sx={{ cursor: 'pointer' }}/>
               <DeleteIcon onClick={(event) => onDeleteAttachment(event, row.original, row.index)} sx={{ cursor: 'pointer', ml: '0.5rem' }}/>
             </div>
           : <EditIcon onClick={(event) => onEditAttachment(event, row.original, row.index)} sx={{ cursor: 'pointer' }}/>;
       }
      }];
    }, [employerId, onEditAttachment, onDeleteAttachment, locale, role]
  );

  // 2. Call Use Table
  const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data: attachments });

    // 3. Return the HTML Table
    return (<>
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                        );
                    }
                )}
            </tbody>
        </table>

        { attachments && attachments.length === 0 &&
          <p className="empty-message">No Attachments</p>
        }
    </>);
}
 
