import React, { Component } from 'react';
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import styled from 'styled-components';

import './Office.css';

const H3 = styled.h1`
  font-size: 18px;
  margin: 0;
`;

export default class Office extends Component {

  render() {
    const o = this.props.office;

    let coordinator = `${o.first_name || ''} ${o.last_name || ''}`;
    if (o.mail)
      coordinator += ` <${o.email}>`;
    const onlineSubs = o.online_subs ? <Checked sx={{opacity:'0.5'}}/> : <Unchecked sx={{opacity:'0.5'}}/>;
    const blindFeelers = o.blind_feelers ? <Checked sx={{opacity:'0.5'}}/> : <Unchecked sx={{opacity:'0.5'}}/>;

    let address2 = o.city || '';
    if (address2) address2 += ',';
    if (o.state) address2 += ` ${o.state}`;
    if (o.zipcode) address2 += ` ${o.zipcode}`;
    if (o.country && !o.country.startsWith('US')) address2 += ` ${o.country}`;

    return <>
      <table className="office-profile">
        <tbody>
          <tr>
            <td valign="top">
              <div>{o.parent_company}</div>
              { o.parent_name &&
                <div>{o.parent_name}</div>
              }
              <div>{o.address}</div>
              <div>{o.address2}</div>
              <div>{o.phone}</div>
              <br/>
              <div>{coordinator}</div>
              <div>{o.email_to}</div>
              { o.email_cc &&
                <div>CC: {o.email_cc}</div>
              }
              { o.email_bcc &&
                <div>BCC: {o.email_bcc}</div>
              }
           </td>

           <td valign="top">
              <p className="display-row">{onlineSubs} Online Subs Required?</p>
              <p className="display-row">{blindFeelers} Blind Feelers Required?</p>

              { o.practice_areas &&
                <div className="employer-practice-areas">
                  <H3>Practice Areas</H3>
                  <p>{o.practice_areas}</p>
                </div>
              }
            </td>
         </tr>
       </tbody>
     </table>

      { o.description &&
        <div className="employer-description">
          <H3>Description</H3>
          <p>{o.description}</p>
        </div>
      }
    </>;
  }

}

