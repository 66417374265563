import React, { Component } from 'react';
import { red } from '@mui/material/colors';
import { Box, Button, Container, FormControl, InputLabel, Select, MenuItem, TextField, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import md5 from 'md5';

import { env } from '../env';
import { checkStatus } from '../checkStatus';


const defaultState = {
  first_name: null,
  last_name: null,
  address: null,
  city: null,
  state: null,
  country: 'USA',
  phone: null,
  email: null,
  law_school: null,
  practice_areas: null,
  notes: null,
  username: null,
  password: null,
  signature: null,
  role: 'Recruiter',
  emailError: null,
  usernameError: null,
  passwordError: null,
  forward: null,
  errorMessage: null,
};


export default class AddRecruiter extends Component {
 
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { name,value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  validate() {
    if (!this.state.email) {
      this.setState({ emailError: 'Email is required' });
      return false;
    } else
      this.setState({ emailError: null });

    const reg = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (reg.test(this.state.email) === false) {
      this.setState({ emailError: 'Email is invalid' });
      return false;
    } else
      this.setState({ emailError: null });

    if (!this.state.username) {
      this.setState({ usernameError: 'Username is required' });
      return false;
    } else
      this.setState({ usernameError: null });

    if (!this.state.password) {
      this.setState({ passwordError: 'Password is required' });
      return false;
    } else
      this.setState({ passwordError: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      const url = `${env.baseUrl}/v1/recruiter`;
      const { first_name, last_name, address, city, state, zipcode, country, email, phone, law_school, practice_areas, notes, username, password, signature, role } = this.state;
  
      const digest = md5(password);

      const payload = {
        enabled: true,
        first_name: first_name?.trim(),
        last_name: last_name?.trim(),
        address: address?.trim(),
        city: city?.trim(),
        state: state?.trim(),
        zipcode: zipcode?.trim(),
        country: country?.trim(),
        email: email?.trim(),
        phone: phone?.trim(),
        law_school,
        practice_areas,
        notes,
        username,
        digest,
        signature,
        role,
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers:{ 'Content-Type':'application/json' },
        credentials:'include',
      })
        .then(result => checkStatus(result))
        .then((result) => {
          this.setState({ forward: '/recruiters' });
        })
        .catch(error => {
          console.error(error);
          this.setState({ errorMessage: error.message || 'Add recruiter failed' });
        });
    }
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }
    const bgClass = this.props.bg || 'none';
    return <section id="add-recruiter" className={bgClass}>
      <Container sx={{
        maxWidth: { xs: 'calc(100% - 1rem)', sm: 'calc(100% - 1rem)', md: 800, lg: 800, xl: 800 },
        margin: { xs: '0 0.5rem', sm: '0 0.5rem', md: '0 auto', lg: '0 auto', xl: '0 auto' },
        pb: '2rem',
      }} disableGutters={true}>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField name="email" label="Email" variant="outlined" fullWidth required onChange={this.handleInputChange} error={!this.state.email || !this.state.email.includes('@')}  />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.emailError }</Typography>

          <TextField name="username" label="Username for login" variant="outlined" fullWidth required onChange={this.handleInputChange} error={!this.state.username} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.usernameError }</Typography>
          <TextField name="password" label="Password for login" variant="outlined" fullWidth required onChange={this.handleInputChange} error={!this.state.password} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.passwordError }</Typography>

          <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true} >
            <TextField name="first_name" label="First name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
            <TextField name="last_name" label="Last name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
          </Container>

          <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }} disableGutters={true}>
            <TextField name="first_name" label="First name" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%' }} />
            <TextField name="last_name" label="Last name" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%', ml: '1.65rem !important' }} />
          </Container>

          <TextField name="address" label="Address" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="city" label="City" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="state" label="State/Province" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="country" label="Country" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="phone" label="Phone" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="law_school" label="Law School" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="practice_areas" label="Practice Areas" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <TextField name="description" label="Description" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="notes" label="Notes" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="signature" label="Signature" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />

          <FormControl variant="standard" sx={{ m: '0.5rem 0', minWidth: '12rem' }}>
            <InputLabel id="role_label" className="select-label" sx={{ ml: '1rem' }}>Role</InputLabel>
            <Select
              name="role"
              labelId="role_label"
              id="role-select"
              variant="outlined" onChange={this.handleInputChange}
              value={this.state.role}
            >
              <MenuItem key={1} value="Recruiter">Recruiter</MenuItem>
              <MenuItem key={2} value="Manager">Manager</MenuItem>
              <MenuItem key={3} value="Administrator">Administrator</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', color: red[900] }}>
            <Button type="submit" variant="contained">Add</Button>
            <Typography>{ this.state.errorMessage }</Typography>
          </Box>
        </Box>

      </Container>

    </section>;

  }
}

