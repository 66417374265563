import { Component } from 'react';
import { useParams } from "react-router";

import { env } from '../env';


const defaultState = {
  attachment: null,
  errorMessage: null,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class AttachmentPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
  }

  async componentDidMount() {
    const url = `${env.baseUrl}/v1/attachment/${this.props.params.id}`;
    fetch(url, {credentials:'include'})
      .then(response => response.blob())
      .then(response => {
        this.setState({ attachment: response });
      });
  }

  render() {
    const a = this.state.attachment;
    if (!a || a === []) {
      return <p>Unable to find attachment</p>;
    }
    return <>
      <h1>Attachment {a.id}</h1>
    </>;
  }
}

export default withParams(AttachmentPage);

