import React, { Component } from 'react';
import { Link } from '@mui/material';
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import styled from 'styled-components';

import './Employer.css';


const H3 = styled.h3`
  font-size: 18px;
  margin: 1rem 0 0;
`;

export default class Employer extends Component {

  getUTCDate(value) {
    if (!value) return '';
    const date = new Date(value);
    const utc = this.convertDateToUTC(date);
    return `${utc.toLocaleDateString(this.locale, {dateStyle:'medium'})}`;
  }

  convertDateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  render() {
    const e = this.props.employer;

    const name1 = `${e.first_name || ''} ${e.last_name || ''}`.trim();
    const name2 = `${e.first_name2 || ''} ${e.last_name2 || ''}`.trim();
    const name3 = `${e.first_name3 || ''} ${e.last_name3 || ''}`.trim();
    const name4 = `${e.first_name4 || ''} ${e.last_name4 || ''}`.trim();

    const coordinator = e.email_to ? (name1 ? `${name1} <${e.email_to}>` : e.email_to) : '';
    const coord2 = e.email_to2 ? (name2 ? `${name2} <${e.email_to2}>` : e.email_to2) : '';
    const coord3 = e.email_to3 ? (name3 ? `${name3} <${e.email_to3}>` : e.email_to3) : '';
    const coord4 = e.email_to4 ? (name4 ? `${name4} <${e.email_to4}>` : e.email_to4) : '';

    const onlineSubs = e.online_subs ? <Checked className="online-subs-checked"/> : <Unchecked sx={{opacity:'0.5'}}/>;
    const blindFeelers = e.blind_feelers ? <Checked className="blind-feelers-checked"/> : <Unchecked sx={{opacity:'0.5'}}/>;
    let address2 = e.city || '';
    if (e.city && e.state) address2 += ',';
    if (e.state) address2 += ` ${e.state}`;
    if (e.zipcode) address2 += ` ${e.zipcode}`;
    if (e.country && !e.country.startsWith('US')) address2 += ` ${e.country}`;

    let website = e.website;
    if (website && !website.startsWith('http')) {
      website = `https://${website}`;
    }

    return (<>
      <div className="employer">
      <div className="employer-profile">
        { (!!e.address || !!address2 || !!e.phone) && 
          <div className="profile-left">
            <p className="indent">{e.address}</p>
            <p className="indent">{address2}</p>
            <p className="indent">{e.phone}</p>
            { !!website &&
              <p className="indent"><Link href={website} target="_blank" underline="hover">{e.website}</Link></p>
            }
          </div>
        }

        <div className="profile-right">
          <p className="display-row">{onlineSubs} Online Subs Required?</p>
          <p className="display-row">{blindFeelers} Blind Feelers Required?</p>
        </div>
      </div>

      <H3>Recruiting Coordinators</H3>
      <p className="indent">{coordinator}</p>
      <p className="indent">{coord2}</p>
      <p className="indent">{coord3}</p>
      <p className="indent">{coord4}</p>
      { !!e.email_cc &&
         <p className="indent">CC: {e.email_cc}</p>
      }
      { !!e.email_bcc &&
         <p className="indent">BCC: {e.email_bcc}</p>
      }

      { !!e.practice_areas &&
        <div>
          <H3>Practice Areas</H3>
          <p className="indent">{e.practice_areas}</p>
        </div>
      }

      { !!e.description &&
        <div className="employer-description">
          <H3>Description</H3>
          <pre className="indent">{e.description}</pre>
        </div>
      }

      </div>
    </>);
  }
}

