import { Component } from 'react';
import Job from '../components/Job';

export default class JobPage extends Component {
  render() {
    return <>
      <h1>Firm Prospect Job</h1>
      <Job />
    </>;
  }
}

