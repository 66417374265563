import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "@mui/material";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { ThumbUp, ThumbDown } from '@mui/icons-material';

import PaginatedTable from './PaginatedTable';
import './EmployersTable.css';


const statusStyles = {
  display : 'flex',
  alignItems: 'center',
};

export default function SubmissionEmployersTable({ employers, fetchData, loading, totalCount, pageCount, pageSize, showAllEmployers }) {

  const navigate = useNavigate();

  // 1. Create Columns
  const columns = React.useMemo( () => [
    {
      Header: 'Company/Firm',
      accessor: 'company',
      Cell: ({value, row}) => {
        const { id, parent_id } = row.original;
        //if (parent_id) return '';
        const employerId = parent_id  || id;
        const url = `/employer/${employerId}`;
        const cname = row.original.status === 'On Hold' ? 'on-hold-link' : '';
        return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{value}</Link>
      }
    },
    {
      Header: showAllEmployers ? 'Office Name' : '# Offices',
      accessor: showAllEmployers ? 'office_name' : 'office_count',
      Cell: ({value, row}) => {
        if (!showAllEmployers) return row.original.office_count;
        const { id, parent_id } = row.original;
        if (!parent_id) return '--';
        const url = `/office/${id}`;
        const cname = row.original.status === 'On Hold' ? 'on-hold-link' : '';
        return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{value}</Link>
      }
    },
    {
      Header: 'Coordinators',
      accessor: 'email_to',
      Cell: ({value, row}) => {
        const { first_name, last_name, email_to } = row.original;
        const { first_name2, last_name2, email_to2 } = row.original;
        const { first_name3, last_name3, email_to3 } = row.original;
        const { first_name4, last_name4, email_to4 } = row.original;

        const name1 = `${first_name || ''} ${last_name || ''}`.trim();
        const name2 = `${first_name2 || ''} ${last_name2 || ''}`.trim();
        const name3 = `${first_name3 || ''} ${last_name3 || ''}`.trim();
        const name4 = `${first_name4 || ''} ${last_name4 || ''}`.trim();

        let coords = email_to ? (name1 ? `${name1} <${email_to}>` : email_to) : '';
        if (email_to2) {
          coords += email_to2 ? (name2 ? `\n${name2} <${email_to2}>` : `\n${email_to2}`) : '';
        }
        if (email_to3) {
          coords += email_to3 ? (name3 ? `\n${name3} <${email_to3}>` : `\n${email_to3}`) : '';
        }
        if (email_to4) {
          coords += email_to4 ? (name4 ? `\n${name4} <${email_to4}>` : `\n${email_to4}`) : '';
        }
        return <div className="wrap-cell">{ coords }</div>;
      }
    },
    {
      Header: 'Online Subs?',
      accessor: 'online_subs',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'Blind Feelers?',
      accessor: 'blind_feelers',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value, row}) => {
         switch (value) {
           case 'Unknown':
             return <span style={statusStyles}><Unchecked sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
           case 'Active':
             return <span style={statusStyles}><ThumbUp sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
           case 'On Hold':
             return <span style={statusStyles}><ThumbDown sx={{opacity:'0.5', color: 'white', mr: '0.25rem' }}/> {value}</span>;
           case null:
             return <span style={statusStyles}>--</span>;
           default:
             return <span style={statusStyles}><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
         }
      }
    }],
  [showAllEmployers, navigate]
 );

  const getRowClasses = (row) => {
    let classes = '';
    if (row.original.online_subs) {
      classes += ' online-subs';
    }
    if (row.original.blind_feelers) {
      classes += ' blind-feelers';
    }
    if (row.original.status === 'Active') {
      classes += ' active';
    }
    if (row.original.status === 'On Hold') {
      classes += ' on-hold';
    }
    return (classes) ? ({ className: classes }) : {};
  }

  // 3. Return the HTML Table
  return (<>
    <PaginatedTable
      data={employers}
      columns={columns}
      fetchData={fetchData}
      loading={loading}
      totalCount={totalCount}
      pageCount={pageCount}
      pageSize={pageSize}
      target="Employers"
      getRowClasses={getRowClasses}
    />
  </>);
}

