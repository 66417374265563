import { Component } from 'react';
import { Box, Button, CircularProgress, Container, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, InputAdornment, InputLabel, Menu, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { Clear, ArrowDropDown, ThumbUp, ThumbDown, Warning } from '@mui/icons-material';
import styled from 'styled-components';
import { amber, green, red } from '@mui/material/colors';
import { v4 as uuid } from 'uuid';

import Candidate from '../components/Candidate';
import EditCandidate from '../components/EditCandidate';
import Attachments from '../components/Attachments';
import AddAttachment from '../components/AddAttachment';
import SubmittedEmployers from '../components/SubmittedEmployers';
import CandidateApproval from '../components/CandidateApproval';
import EmployerSubmissions from '../components/EmployerSubmissions';
import SubmissionsTable from '../components/SubmissionsTable';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';
import { formatBytes } from '../utils/formatBytes';


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const H1 = styled.h1`
  margin: 0;
`;
const H2 = styled.h2`
  margin: 1rem 0 0.25rem;
`;
const P = styled.p`
  margin-bottom: 0.25rem;
`;
const SearchForm = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const BlindSubmissionButton = (props) => {
  const navigate = useNavigate();
  const candidateId = props.id;
  return (
    <Button onClick={()=> navigate(`/blind-submit-candidate/${candidateId}`)} variant="contained" color="warning" sx={{ mt: '0.5rem' }}>Blind Submission</Button>
  )
}
const BlindSubmissionMenuItem = (props) => {
  const navigate = useNavigate();
  const candidateId = props.id;
  return (
    <MenuItem onClick={()=> navigate(`/blind-submit-candidate/${candidateId}`)} variant="contained" color="warning" sx={{ mt: '0.5rem' }}>Blind Submission</MenuItem>
  )
}

const SubmitCandidateButton = (props) => {
  const navigate = useNavigate();
  const candidateId = props.id;
  return (
    <Button onClick={()=> navigate(`/submit-candidate/${candidateId}`)} variant="contained" color="warning" sx={{ mt: '0.5rem' }}>Submit Candidate to Employers</Button>
  )
}
const SubmitCandidateMenuItem = (props) => {
  const navigate = useNavigate();
  const candidateId = props.id;
  return (
    <MenuItem onClick={()=> navigate(`/submit-candidate/${candidateId}`)}>Submit Candidate</MenuItem>
  )
}

const FollowUpButton = (props) => {
  const navigate = useNavigate();
  const candidateId = props.id;
  return (
    <Button onClick={()=> navigate(`/follow-up-candidate/${candidateId}`)} variant="contained" color="warning" sx={{ mt: '0.5rem' }}>Follow Up</Button>
  )
}
const FollowUpMenuItem = (props) => {
  const navigate = useNavigate();
  const candidateId = props.id;
  return (
    <MenuItem onClick={()=> navigate(`/follow-up-candidate/${candidateId}`)} divider={true}>Follow Up</MenuItem>
  )
}

const defaultState = {
  user: null,
  loading: true,
  searchEmployerName: '',
  searchAddress: '',
  searchOfficeName: '',
  candidate: null,
  status: 'Unknown',
  notes: '',

  showAllEmployers: false,
  employers: [],

  approvalList: [],
  employerSubmissions: [],
  submittedEmployers: [],

  approved: [],
  submitted: [],

  approval: [],
  approvalPage: [],
  approvalPageSize: 10,
  approvalPageIndex: 0,
  approvalTotalCount: 0,

  recruiterId: 'open',
  secondaryRecruiterId: 'open',
  recruiters: [],

  showUploadAttachment: false,
  attachmentCategory: 'None',
  attachments: [],
  totalAttachmentsSize: 0,
  attachButtonText: 'Add Attachment',

  submissions: [],
  loadingSubmissions: true,
  submissionTotalCount: 0,
  submissionPageCount: 0,
  submissionPageSize: 100,

  role: null,
  editMode: false,
  anchorEL: null,
  open: false,
  openDeleteDialog: false,
};

class CandidatePage extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.setApproval = this.setApproval.bind(this);
    this.onApproved = this.onApproved.bind(this);
    this.onDeclined = this.onDeclined.bind(this);
    this.onStatus = this.onStatus.bind(this);
    this.notifyOnline = this.notifyOnline.bind(this);
    this.notifySubmit = this.notifySubmit.bind(this);
    this.onSubmittedStatus = this.onSubmittedStatus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onShowUploadAttachment = this.onShowUploadAttachment.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.saveStatus = this.saveStatus.bind(this);
    this.openActionsMenu = this.openActionsMenu.bind(this);
    this.closeActionsMenu = this.closeActionsMenu.bind(this);
    this.onSaveCandidate = this.onSaveCandidate.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onUpdateCandidate = this.onUpdateCandidate.bind(this);
    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.onDeleteConfirmationClose = this.onDeleteConfirmationClose.bind(this);
    this.onDeleteCandidate = this.onDeleteCandidate.bind(this);
    this.onDeleteAttachment = this.onDeleteAttachment.bind(this);
    this.onSearchCandidateApproval = this.onSearchCandidateApproval.bind(this);
    this.onAllEmployers = this.onAllEmployers.bind(this);
    this.onApprovalList = this.onApprovalList.bind(this);
    this.onClearSearchEmployerName = this.onClearSearchEmployerName.bind(this);
    this.onClearSearchAddress = this.onClearSearchAddress.bind(this);
    this.onClearSearchOfficeName = this.onClearSearchOfficeName.bind(this);
    this.onSelectPrimaryRecruiter = this.onSelectPrimaryRecruiter.bind(this);
    this.onSelectSecondaryRecruiter = this.onSelectSecondaryRecruiter.bind(this);
    this.onSelectRecruiter = this.onSelectRecruiter.bind(this);
    this.deleteCandidateRecruiter = this.deleteCandidateRecruiter.bind(this);
    this.getSubmissions = this.getSubmissions.bind(this);
    this.getCandidateApproval = this.getCandidateApproval.bind(this);
  }

  onSelectPrimaryRecruiter(event) {
    this.onSelectRecruiter(event, 'primary');
  }

  onSelectSecondaryRecruiter(event) {
    this.onSelectRecruiter(event, 'secondary');
  }

  onSelectRecruiter(event, action) {
    const targetName = event.target.name;
    const recruiterId = event.target.value;
    const candidateId = this.props.params.id;
    const isPrimary = targetName === 'recruiter';

    if (recruiterId === 'open') {
      const deleteId = isPrimary ? this.state.recruiterId : this.state.secondaryRecruiterId;
      this.deleteCandidateRecruiter(candidateId, deleteId, isPrimary);
      return;
    }

    const url = `${env.baseUrl}/v1/candidate/${candidateId}/recruiter/${recruiterId}/${action}`;
    fetch(url, {
      method: 'POST',
      credentials: 'include',
    })
      .then(result => checkStatus(result))
      .then(response => {
        if (isPrimary)
          this.setState({ recruiterId });
        else
          this.setState({ secondaryRecruiterId: recruiterId });
      })
      .catch(error => {
        console.error(error);
        if (isPrimary)
          this.setState({ errorMessage: 'Select Recruiter failed' });
        else
          this.setState({ errorMessage: 'Select Secondary Recruiter failed' });
      });
  }

  deleteCandidateRecruiter(candidateId, recruiterId, isPrimary) {
    const url = `${env.baseUrl}/v1/candidate/${candidateId}/recruiter/${recruiterId}`;
    fetch(url, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then(result => checkStatus(result))
      .then(response => {
        if (isPrimary) {
          if (this.state.secondaryRecruiterId === 'open')
            this.setState({ recruiterId: 'open' });
          else
            this.setState({ recruiterId: this.state.secondaryRecruiterId, secondaryRecruiterId: 'open' });
        } else
          this.setState({ secondaryRecruiterId: 'open' });
      })
      .catch(error => {
        console.error(error);
        if (isPrimary)
          this.setState({ errorMessage: 'Delete Recruiter failed' });
        else
          this.setState({ errorMessage: 'Delete Secondary Recruiter failed' });
      });
  }

  async componentDidMount() {
    // Get the recruiter's role to use for permissions
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({ user });

    const candidateId = this.props.params.id;
    const url = `${env.baseUrl}/v1/candidate/${candidateId}`;
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        if (data?.length) {
          const candidate = data[0];
          const secondary = data.length === 2 ? data[1] : null;
          const status = candidate.status || 'Unknown';
          const notes = candidate.notes || '';
          const recruiterId = candidate.recruiter_id || 'open';
          const secondaryRecruiterId = secondary?.recruiter_id || 'open';
          return this.setState({ candidate, status, notes, recruiterId, secondaryRecruiterId });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message, error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    this.getEmployerSubmissions();
    this.getSubmittedEmployers();
    this.getSubmissions();

    const attachmentsUrl = `${env.baseUrl}/v1/candidate/${candidateId}/attachment`;
    fetch(attachmentsUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        let totalAttachmentsSize = 0;
        for (const attachment of data) {
          totalAttachmentsSize += attachment.size;
        }
        //console.log(`attachments (${totalAttachmentsSize}): `, data);
        return this.setState({ attachments: data, totalAttachmentsSize });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });

    const recruitersUrl = `${env.baseUrl}/v1/recruiter`;
    fetch(recruitersUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        const found = items.data.find(r => r.email === user.email);
        return this.setState({ recruiters: items.data, role: found?.role });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });
  }

  getSubmissions() {
    const candidateId = this.props.params.id;
    const submissionsUrl = `${env.baseUrl}/v1/candidate/${candidateId}/submission`;
    fetch(submissionsUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        return this.setState({ submissions: items.data });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });
  }

  getApprovalListUrl(candidateId, pageIndex, pageSize) {
    let url = `${env.baseUrl}/v1/candidate/${candidateId}/approval-list`;

    let queryParams = '';
    if (this.state.searchEmployerName) {
      if (queryParams) queryParams += '&';
      queryParams += `searchEmployer=${this.state.searchEmployerName}`;
    }
    if (this.state.searchAddress) {
      if (queryParams) queryParams += '&';
      queryParams += `searchAddress=${this.state.searchAddress}`;
    }
    if (this.state.searchOfficeName) {
      if (queryParams) queryParams += '&';
      queryParams += `searchOffice=${this.state.searchOfficeName}`;
    }
    if (!this.state.showAllEmployers) {
      if (queryParams) queryParams += '&';
      queryParams += `showOffices=false`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    return url;
  }

  getCandidateApproval({ pageSize, pageIndex }) {
    if (this.state.showAllEmployers) {
      this.getAllEmployers({ pageSize, pageIndex });
    } else {
      this.getApprovalList({ pageSize, pageIndex });
    }
  }

  getApprovalList({ pageSize, pageIndex }) {
    const candidateId = this.props.params.id;
    const url = this.getApprovalListUrl(candidateId, pageIndex, pageSize);
    //console.log('getCandidateApproval: ', url);

    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        //console.log('approvalList: ', data);
        this.setState({ approvalList: data }, () => {
          this.setApproval(data);
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });
  }


  getAllEmployersUrl(candidateId, pageSize, pageIndex) {
    let url = `${env.baseUrl}/v1/candidate/${candidateId}/employer`;

    let queryParams = '';
    if (this.state.searchEmployerName) {
      if (queryParams) queryParams += '&';
      queryParams += `searchEmployer=${this.state.searchEmployerName}`;
    }
    if (this.state.searchAddress) {
      if (queryParams) queryParams += '&';
      queryParams += `searchAddress=${this.state.searchAddress}`;
    }
    if (this.state.searchOfficeName) {
      if (queryParams) queryParams += '&';
      queryParams += `searchOffice=${this.state.searchOfficeName}`;
    }
    if (!this.state.showAllEmployers) {
      if (queryParams) queryParams += '&';
      queryParams += `showOffices=false`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    return url;
  }

  getAllEmployers({ pageSize, pageIndex }) {
    const candidateId = this.props.params.id;
    const url = this.getAllEmployersUrl(candidateId, pageSize, pageIndex);
    //console.log('getAllEmployers: ', url);

    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        //console.log('employers: ', data);
        this.setState({ employers: data }, () => {
          this.setApproval(data);
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });
  }

  setApproval(data) {
    // initialize candidate_status for mui select
    for (const e of data) {
      if (e.candidate_status === null)
        e.candidate_status = '';
    }

    const pageIndex = this.state.approvalPageIndex;
    const pageSize = this.state.approvalPageSize;
    const totalCount = data.length ? data[0].count : 0;

    const approvalPage = data;
    const approvalTotalCount = totalCount;
    const approvalPageCount = Math.ceil(approvalTotalCount / pageSize);

    //console.log(`setApproval: pageIndex=${pageIndex}, pageSize=${pageSize}, totalCount=${totalCount}: `, approvalPage);

    this.setState({ approvalPage, approvalPageSize: pageSize, approvalPageIndex: pageIndex, approvalPageCount, approvalTotalCount });
  }

  getEmployerSubmissions() {
    const candidateId = this.props.params.id;
    const url = `${env.baseUrl}/v1/candidate/${candidateId}/submission-list`;
    //console.log(`getEmployerSubmissions: `, url);

    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        //console.log('employerSubmissions: ', data);
        this.setState({ employerSubmissions: data });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });
  }

  getSubmittedEmployers() {
    const candidateId = this.props.params.id;
    const url = `${env.baseUrl}/v1/candidate/${candidateId}/follow-up-list`;
    //console.log(`getSubmittedEmployers: `, url);

    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        //console.log('submittedEmployers: ', data);
        this.setState({ submittedEmployers: data });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });
  }

  onApproved(employer) {
    //console.log('onApproved: ', employer);
    const employerSubmissions = [...this.state.employerSubmissions];
    const index = this.state.employerSubmissions.findIndex(e => e.id === employer.id);
    if (index >= 0) {
      employerSubmissions.splice(index, 1, employer);
      this.setState({ employerSubmissions });
      //console.log('employerSubmissions: ', employerSubmissions);
    } else {
      employerSubmissions.push(employer);
      this.setState({ employerSubmissions });
      //console.log('employerSubmissions: ', employerSubmissions);
    }
  }

  onDeclined(employer) {
    //console.log('onDeclined: ', employer);
    const index = this.state.employerSubmissions.findIndex(e => e.id === employer.id);
    if (index >= 0) {
      const employerSubmissions = [...this.state.employerSubmissions];
      employerSubmissions.splice(index, 1);
      this.setState({ employerSubmissions });
      //console.log('employerSubmissions: ', employerSubmissions);
    }
  }

  onStatus(employer) {
    //console.log('onStatus: ', employer);
    const index = this.state.employerSubmissions.findIndex(e => e.id === employer.id);
    if (index >= 0) {
      const employerSubmissions = [...this.state.employerSubmissions];
      employerSubmissions.splice(index, 1, employer);
      this.setState({ employerSubmissions });
      //console.log('employerSubmissions: ', employerSubmissions);
    }
  }

  notifyOnline(employer) {
    //console.log('notifyOnline: ', employer);
    const index = this.state.submittedEmployers.findIndex(e => e.id === employer.id);
    if (index === -1) {
      const submittedEmployers = [...this.state.submittedEmployers];
      submittedEmployers.push(employer);
      this.setState({ submittedEmployers });
      //console.log('submittedEmployers: ', submittedEmployers);
    }
  }

  notifySubmit(employer) {
    //console.log('notifySubmit: ', employer);
    const index = this.state.submittedEmployers.findIndex(e => e.id === employer.id);
    if (index >= 0) {
      const submittedEmployers = [...this.state.submittedEmployers];
      submittedEmployers.splice(index, 1);
      this.setState({ submittedEmployers });
      //console.log('submittedEmployers: ', submittedEmployers);
    }
  }

  onSubmittedStatus(employer) {
    //console.log(`onSubmittedStatus(${employer.candidate_status}): `, employer);
    if (employer.candidate_status === 'Approved') {
      const index = this.state.submittedEmployers.findIndex(e => e.id === employer.id);
      if (index >= 0) {
        const submittedEmployers = [...this.state.submittedEmployers];
        submittedEmployers.splice(index, 1);
        this.setState({ submittedEmployers });
        //console.log('submittedEmployers: ', submittedEmployers);

        const employerSubmissions = [...this.state.employerSubmissions];
        employerSubmissions.push(employer);
        this.setState({ employerSubmissions });
        //console.log('employerSubmissions: ', employerSubmissions);
      }
    }
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name) {
      this.setState({ [name]: value });
    }
  }

  onShowUploadAttachment() {
    const attachButtonText = this.state.showUploadAttachment ? 'Add Attachment' : 'Hide Add Attachment';
    this.setState({ showUploadAttachment: !this.state.showUploadAttachment, attachButtonText });
  }

  onFilesAdded(files) {
    this.setState({ files });

    const file = files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('category', this.state.attachmentCategory || 'None');
    formData.append('email', this.state.candidate.email);

    const candidateId = this.props.params.id;
    const url = `${env.baseUrl}/v1/candidate/${candidateId}/attach`;
    fetch(url, {
      method: 'POST',
      body: formData,
      credentials:'include',
    })
      .then(result => checkStatus(result))
      .then(response => {
        const { id, name, size, type, category } = response;
        const attachment = { id, name, size, type, category };

        const attachments = [...this.state.attachments];
        attachments.push(attachment);

        const totalAttachmentsSize = this.state.totalAttachmentsSize + file.size;
        this.setState({ attachments, totalAttachmentsSize, showUploadAttachment: false, attachButtonText: 'Add Attachment' });
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDeleteAttachment(event, attachment, index) {
    event.preventDefault();
    //const attachment = this.state.attachments?.length ? this.state.attachments[index] : null;
    if (!attachment) {
      console.error(`Unable to delete attachment @ ${index}`);
      return;
    }
    const url = `${env.baseUrl}/v1/candidate/${this.state.candidate.id}/attachment/${attachment.id}`;

    fetch(url, {
      method: 'DELETE',
      body: '{}',
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(response => {
      if (response.rowCount === 1) {
        this.setSuccessMessage('Attachment deleted');
        const attachments = [...this.state.attachments];
        attachments.splice(index, 1);
        
        const totalAttachmentsSize = this.state.totalAttachmentsSize - attachment.size;
        this.setState({ attachments, totalAttachmentsSize, showUploadAttachment: false, attachButtonText: 'Add Attachment' });
      } else
        this.setState({ errorMessage: 'Unable to delete attachment' });
    })
    .catch(error => {
      console.error(error);
      this.setState({ errorMessage: 'Delete attachment failed' });
    });
  }

  saveStatus(event) {
    const value = event.target.value;
    const url = `${env.baseUrl}/v1/candidate/${this.state.candidate.id}/status`;

    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ status: value }),
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(response => {
      if (response.data.rowCount === 1) {
        this.setState({ status: value });
        this.setSuccessMessage('Candidate Status saved');
      } else
        this.setState({ errorMessage: 'Save status failed' });
    })
    .catch(error => {
      console.error(error);
      this.setState({ errorMessage: 'Save status failed' });
    });
  }

  setSuccessMessage(msg) {
    this.setState({ successMessage: msg, errorMessage: '' });
    setTimeout(() => this.setState({ successMessage: '' }), 5000);
  }

  openActionsMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ open: true, anchorEL: event.currentTarget });
  }

  closeActionsMenu() {
    this.setState({ open: false, anchorEL: null });
  }

  onUpdateCandidate() {
    this.setState({ editMode: true });
    this.closeActionsMenu();
  }

  onDeleteConfirmation() {
    this.setState({ openDeleteDialog: true });
  };

  onDeleteConfirmationClose() {
    this.setState({ openDeleteDialog: false });
  }

  onDeleteCandidate() {
    const url = `${env.baseUrl}/v1/candidate/${this.props.params.id}`;
    fetch(url, {
        method: 'DELETE',
        body: {},
        credentials: 'include',
    })
    .then(result => checkStatus(result))
    .then(items => {
      this.setState({ forward: '/candidates' });
      this.closeActionsMenu();
    });
  }

  onSaveCandidate(save) {
    const candidate = { ...this.state.candidate };
    Object.assign(candidate, save);
    this.setState({ editMode: false, candidate });
  }

  onCancelEdit() {
    this.setState({ editMode: false });
  }

  onAllEmployers() {
    this.setState({ approvalPage: [], approvalPageCount: 0, approvalPageIndex: 0, approvalTotalCount: 0 });
    this.setState({ showAllEmployers: true }, () => {
      this.getAllEmployers({ pageIndex: 0, pageSize: this.state.approvalPageSize });
    });
  }

  onApprovalList() {
    this.setState({ approvalPage: [], approvalPageCount: 0, approvalPageIndex: 0, approvalTotalCount: 0 });
    this.setState({ showAllEmployers: false }, () => {
      this.getApprovalList({ pageIndex: 0, pageSize: this.state.approvalPageSize });
    });
  }

  onSearchCandidateApproval(event) {
    if (event) event.preventDefault();
    this.setState({ approvalPage: [], approvalPageCount: 0, approvalPageIndex: 0, approvalTotalCount: 0 });
    this.getCandidateApproval({ pageIndex: 0, pageSize: this.state.approvalPageSize });
  }

  onClearSearchEmployerName() {
    this.setState({ searchEmployerName: '' }, this.onSearchCandidateApproval);
  }

  onClearSearchAddress() {
    this.setState({ searchAddress: '' }, this.onSearchCandidateApproval);
  }

  onClearSearchOfficeName() {
    this.setState({ searchOfficeName: '' }, this.onSearchCandidateApproval);
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }
    if (this.state.loading) {
      return <Container sx={{ m: '2rem' }}>
        <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
        Loading Candidate...
      </Container>;
    }

    const c = this.state.candidate;
    if (!this.state.loading && (!c || c === [])) {
      return <p>Unable to find candidate</p>;
    }

    const isRecruiter = this.state.user.id === this.state.recruiterId;
    const isAdmin = this.state.user.role === 'Administrator' || this.state.user.role === 'Manager';
    const readonly = !isRecruiter && !isAdmin;

    const { first_name, last_name } = c;
    const name = `${first_name || ''} ${last_name || ''}`;

    return <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', margin: '1rem 0 0' }} maxWidth={false}>
        { (!this.state.editMode || !isRecruiter) &&
          <H1>Candidate {name}</H1>
        }
        { this.state.editMode && isRecruiter &&
          <H1>Edit Candidate {name}</H1>
        }

        <Container component="div" className="candidate-actions" sx={{ display: 'flex', alignItems: 'flex-start', width: 'auto', m: 0 }}>

          <Container component="div" className="candidate-recruiters" sx={{ display: 'flex', flexDirection:{ xs: 'column', sm: 'column', md: 'column', lg: 'column', xl: 'row' }, gap:'0.5rem', alignItems: 'center', width: 'auto', m: 0 }}>
          { this.state.recruiters?.length > 0 &&
            <FormControl variant="standard" sx={{ minWidth: '12rem', mr: '1rem' }}>
              <InputLabel id="recruiter-label" className="select-label" sx={{ ml: '1rem' }}>Recruiter</InputLabel>
              <Select
                name="recruiter"
                labelId="recruiter-label"
                variant="outlined" onChange={this.onSelectPrimaryRecruiter}
                value={this.state.recruiterId}
                disabled={readonly}
              >
                <MenuItem key={uuid()} value="open">Open</MenuItem>
                { this.state.recruiters.map((rec,index) =>
                  <MenuItem key={rec.id} value={rec.id}>{rec.first_name} {rec.last_name}</MenuItem>
                )}
              </Select>
            </FormControl>
          }

          { this.state.recruiters?.length > 0 && this.state.recruiterId && this.state.recruiterId !== 'open' &&
            <FormControl variant="standard" sx={{ minWidth: '12rem', mr: '1rem' }}>
              <InputLabel id="secondary-recruiter-label" className="select-label" sx={{ ml: '1rem' }}>Secondary Recruiter</InputLabel>
              <Select
                name="secondaryRecruiter"
                labelId="secondary-recruiter-label"
                variant="outlined" onChange={this.onSelectSecondaryRecruiter}
                value={this.state.secondaryRecruiterId}
                disabled={readonly}
              >
                <MenuItem key={uuid()} value="open">Open</MenuItem>
                { this.state.recruiters.map((rec,index) =>
                  <MenuItem key={rec.id} value={rec.id}>{rec.first_name} {rec.last_name}</MenuItem>
                )}
              </Select>
            </FormControl>
          }
          </Container>

          { this.state.candidate &&
            <FormControl variant="standard" sx={{ minWidth: '12rem' }}>
              <InputLabel id="select-candidate-status-label" sx={{ left: '1rem', top: '-0.4rem', backgroundColor: 'white', zIndex: 9, p: '0 0.25rem' }}>Status</InputLabel>
              <Select
                name="status"
                labelId="select-candidate-status-label"
                variant="outlined" onChange={this.saveStatus}
                value={this.state.status}
                sx={{ mr: '0.5rem' }}
                disabled={readonly}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Unknown"><Unchecked sx={{opacity:'0.5', mr: '0.25rem'}}/> Unknown</MenuItem>
                <MenuItem value="Inactive"><Unchecked sx={{opacity:'0.5', mr: '0.25rem'}}/> Inactive</MenuItem>
                <MenuItem value="Need to Contact"><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> Need to Contact</MenuItem>
                <MenuItem value="Contacted"><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> Contacted</MenuItem>
                <MenuItem value="Blind Submission"><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> Blind Submission</MenuItem>
                <MenuItem value="Sent Firms"><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> Sent Firms</MenuItem>
                <MenuItem value="Submitted"><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> Submitted</MenuItem>
                <MenuItem value="Interview"><Checked sx={{opacity:'0.5', mr: '0.25rem' }}/> Interview</MenuItem>
                <MenuItem value="Interviewed But Rejected"><Checked sx={{opacity:'0.5', mr: '0.25rem' }}/> Interviewed But Rejected</MenuItem>
                <MenuItem value="Offer"><Checked sx={{opacity:'0.5', mr: '0.25rem' }}/> Offer</MenuItem>
                <MenuItem value="Offer Declined"><ThumbDown sx={{opacity:'0.5', color: red[900], mr: '0.25rem' }}/> Offer Declined</MenuItem>
                <MenuItem value="Needs Follow Up"><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> Needs Follow Up</MenuItem>
                <MenuItem value="Followed Up"><ThumbUp sx={{color: green[600], mr: '0.25rem' }}/> Followed Up</MenuItem>
                <MenuItem value="Hired"><ThumbUp sx={{color: green[600], mr: '0.25rem' }}/> Hired</MenuItem>
              </Select>
            </FormControl>
          }

          <Button
            id="candidate-actions-btn"
            variant="contained"
            aria-controls={this.state.open ? 'candidate-actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={this.state.open ? 'true' : undefined}
            onClick={this.openActionsMenu}
          >
            Actions
            <ArrowDropDown sx={{ml: '0.25rem'}}/>
          </Button>
          <Menu
            id="candidate-actions-menu"
            anchorEl={this.state.anchorEL}
            open={this.state.open}
            onClose={this.closeActionsMenu}
            MenuListProps={{
              'aria-labelledby': 'candidate-actions-btn',
            }}
          >
            <BlindSubmissionMenuItem id={c.id} approvalList={this.state.approvalList} />
            <SubmitCandidateMenuItem id={c.id} approved={this.state.approved} />
            <FollowUpMenuItem id={c.id} submitted={this.state.submitted} />
            <MenuItem onClick={this.onUpdateCandidate} disabled={readonly}>Edit Candidate</MenuItem>
            <MenuItem onClick={this.onDeleteConfirmation} disabled={readonly}>Delete Candidate</MenuItem>
          </Menu>
        </Container>
      </Container>

      { !this.state.editMode &&
        <Candidate candidate={this.state.candidate} recruiters={this.state.recruiters} readonly={readonly} />
      }
      { this.state.editMode &&
        <EditCandidate candidate={this.state.candidate} onSave={this.onSaveCandidate} onCancel={this.onCancelEdit} />
      }

      <Container component="div" sx={{ margin: '2rem 0 2rem', textAlign: 'left' }}>
        <H2>Attachments</H2>
          <Attachments attachments={this.state.attachments} onDeleteAttachment={this.onDeleteAttachment} role={this.state.role} sx={{ width: '100%' }} readonly={readonly} />
          { this.state.totalAttachmentsSize > 0 &&
            <Typography sx={{ fontSize:'13px', opacity:'75%' }} >Total size: {formatBytes(this.state.totalAttachmentsSize)}</Typography>
          }

        { this.state.showUploadAttachment &&
          <Container component="div" sx={{ mt: '1rem', textAlign: 'left' }} disableGutters={true}>
            <h3>Add Attachment</h3>
            <FormControl variant="standard" sx={{ minWidth: '12rem' }}>
              <InputLabel id="select-attachment-category-label" className="select-label" sx={{ ml: '1rem' }}>Attachment Category</InputLabel>
              <Select
                name="attachmentCategory"
                labelId="select-attachment-category-label"
                variant="outlined" onChange={this.handleInputChange}
                defaultValue="None"
                sx={{ mb: '0.25rem' }}
              >
                <MenuItem value="None">None</MenuItem>
                <MenuItem value="Resume">Resume</MenuItem>
                <MenuItem value="Transcript">Transcript</MenuItem>
                <MenuItem value="Writing Sample">Writing Sample</MenuItem>
                <MenuItem value="Deal Sheet">Deal Sheet</MenuItem>
              </Select>
            </FormControl>
            <AddAttachment onChange={this.onFilesAdded} target={this.state.attachmentCategory || 'Attachment'} sx={{ width: '100%' }} />
          </Container>
        }
        <Button type="button" variant="outlined" sx={{ mt: '0.5rem' }} onClick={this.onShowUploadAttachment} disabled={readonly}>{this.state.attachButtonText}</Button>
      </Container>


      <Container component="div" sx={{ margin: '2rem 0 2rem', textAlign: 'left' }} maxWidth={false}>
        <Container component="div" sx={{ display: 'flex', alignItems: 'top', justifyContent: 'space-between', width: '100%', margin: '1rem 0 0' }} disableGutters={true} maxWidth={false}>
          <H2>Candidate Approval</H2>

          <SearchForm>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={this.onSearchCandidateApproval}
              sx={{ display: 'flex', gap: '1rem', alignItems: 'top' }}
            >
              <Box
                component="div"
                noValidate
                autoComplete="off"
                sx={{ display: 'flex', flexDirection:'column', gap: '1rem', alignItems: 'center' }}
              >
                <Box
                  component="div"
                  noValidate
                  autoComplete="off"
                  sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
                >
                  <TextField name="searchEmployerName" value={this.state.searchEmployerName} label="Search by Company/Firm" variant="outlined" fullWidth onChange={this.handleInputChange}
                    sx={{ minWidth: '15rem' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Clear onClick={this.onClearSearchEmployerName} sx={{ cursor: 'pointer' }} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField name="searchOfficeName" value={this.state.searchOfficeName} label="Search by Office Name" variant="outlined" fullWidth onChange={this.handleInputChange}
                    sx={{ minWidth: '15rem' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Clear onClick={this.onClearSearchOfficeName} sx={{ cursor: 'pointer' }} />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <TextField name="searchAddress" value={this.state.searchAddress} label="Search by Address" variant="outlined" fullWidth onChange={this.handleInputChange}
                  sx={{ minWidth: '15rem' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Clear onClick={this.onClearSearchAddress} sx={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <div>
                <Button type="submit" variant="contained" sx={{ padding: '0.5rem 2rem' }}>Search</Button>
              </div>
            </Box>
          </SearchForm>
        </Container>

        <Container component="div" disableGutters={true} sx={{m:0}}>
          <RadioGroup value={this.state.showAllEmployers} sx={{ flexDirection: 'row' }} >
            <FormControlLabel control={<Radio name="approval_list" value={false} onClick={this.onApprovalList}/>} label="Approval List" />
            <FormControlLabel control={<Radio name="all_employers" value={true} onClick={this.onAllEmployers}/>} label="All Employers" />
          </RadioGroup>
        </Container>

        <P>The Candidate must approve the Company/Firm before we can submit.  The Candidate can also decline any firms (maybe already submitted,...).</P>
        <P>Checking <i>Add to Approval List</i> adds the Company/Firm to the <i>Blind Submission</i>.</P>
        <P>Checking <i>Approved (by Candidate)</i> adds the Company/Firm to the <i>Employer Submissions</i> list below.</P>

        <CandidateApproval
          employers={this.state.approvalPage}
          candidateId={c.id}
          fetchData={this.getCandidateApproval}
          notifyOnApproved={this.onApproved}
          notifyOnDeclined={this.onDeclined}
          notifyOnStatus={this.onStatus}
          loading={this.state.loading}
          pageSize={this.state.approvalPageSize}
          pageCount={this.state.approvalPageCount}
          pageIndex={this.state.approvalPageIndex}
          totalCount={this.state.approvalTotalCount}
          readonly={readonly}
        />
        <BlindSubmissionButton id={c.id} approvalList={this.state.approvalList} />
      </Container>

      <Container component="div" sx={{ margin: '2rem 0 0', textAlign: 'left' }} maxWidth={false}>
        <H2>Employer Submissions</H2>
        <P>Next we add the approved employers to the Submissions List (which gets reviewed and submitted).  Otherwise, the submission could be online/external.</P>
        <P>Submitting candidates or checking <i>Online or Other Submission</i> adds the Company/Firm to the <i>Submitted Employers</i> list below.</P>
        <EmployerSubmissions
          approved={this.state.employerSubmissions}
          notifyOnline={this.notifyOnline}
          notifySubmit={this.notifySubmit}
          candidateId={c.id}
          readonly={readonly}
        />
        <SubmitCandidateButton id={c.id} approved={this.state.approved} />
      </Container>

      <Container component="div" sx={{ margin: '2rem 0 0', textAlign: 'left' }} maxWidth={false}>
        <H2>Submitted Employers</H2>
        <P>We track the status of the submitted candidates and optionally follow up with them.</P>
        <SubmittedEmployers
          submitted={this.state.submittedEmployers}
          onSubmittedStatus={this.onSubmittedStatus}
          candidateId={c.id}
          readonly={readonly}
        />
      </Container>

      <Container component="div" sx={{ display: 'flex', m: '0 0 2rem' }}>
        <FollowUpButton id={c.id} submitted={this.state.submitted} />
      </Container>

      <Container component="div" sx={{ margin: '2rem 0 0', pb:'2rem', textAlign: 'left' }} maxWidth={false}>
        <H2>Submissions</H2>
        <P>Finally, we list the candidate submissions/follow ups.</P>
        <SubmissionsTable
          submissions={this.state.submissions}
          loading={this.state.loading}
          totalCount={this.state.submission?.length}
        />
      </Container>

      <Dialog open={this.state.openDeleteDialog} onClose={this.onDeleteConfirmationClose}>
        <DialogTitle sx={{ textAlign: 'left' }}>Delete Candidate</DialogTitle>
        <DialogContent sx={{ textAlign: 'left' }}>
          <DialogContentText>
            Do you want to Delete Candidate {name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onDeleteCandidate} color="primary" variant="contained" size="small">Delete</Button>
          <Button onClick={this.onDeleteConfirmationClose} color="primary" variant="outlined" size="small" autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>;
  }
}

export default withParams(CandidatePage);

