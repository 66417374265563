import React, { Component } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import { Alert, Autocomplete, Box, Button, CircularProgress, Container, Dialog, DialogTitle, DialogActions, DialogContent, FormControl, IconButton, InputAdornment, InputLabel, Link, Menu, MenuItem, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { Clear, Delete } from '@mui/icons-material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import styled from 'styled-components';
import { red } from '@mui/material/colors';

import SubmissionList from '../components/SubmissionList';
import Attachments from '../components/Attachments';
import Editor from '../components/Editor';
import { checkStatus, goToLogin } from '../checkStatus';
import { env, environment } from '../env';
import { formatBytes } from '../utils/formatBytes';
import './SubmitCandidatePage.css';


const defaultState = {
  user: null,
  submitType: 'submitCandidate',
  sendEmailDisabled: true,
  loading: true,
  sending: false,
  searchEmployerName: '',
  searchAddress: '',
  searchOfficeName: '',
  pageIndex: 0,
  pageSize: 5,
  pageCount: 0,
  totalCount: 0,
  showAllEmployers: true,
  coverLetter: null,
  coverLetterIndex: -1,
  selectCoverLetter: '',
  subject: 'Submitting Candidate {candidateName}',
  body: 'Hi {employerFirstNames},\n\nText of standard cover letter...\n',
  candidate: null,
  employers: [],
  submissionList: [],
  selectedEmployers: [],
  coverLetters: [],
  coverLetterName: undefined,
  coverLetterDescription: undefined,
  coverLetterError: null,
  attachments: [],
  totalMessageSize: 0,
  availableAttachments: [],
  anchorEL: null,
  open: false,
  openSaveAsDialog: false,
  sizeErrorMessage: null,
  errorMessage: null,
  successMessage: null,
  snackbarMessage: null,
};

const MAX_MESSAGE_SIZE = 10485760;

const SubmissionType = Object.freeze({
  blindSubmission: 'blindSubmission',
  submitCandidate: 'submitCandidate',
  followUp: 'followUp',
});

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const P = styled.p`
  margin-bottom: 0.25rem;
`;
const SearchForm = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const BackButton = (props) => {
  const navigate = useNavigate();
  return (
    <Link href={props.url} onClick={(evt) => {evt.preventDefault();navigate(props.url)}} underline="hover">{props.text}</Link>
  )
}

class SubmitCandidatePage extends Component {
  debugSubmitWithoutEmployers = false;

  constructor() {
    super();
    this.state = defaultState;
    this.onSearchEmployers = this.onSearchEmployers.bind(this);
    this.onClearSearchEmployerName = this.onClearSearchEmployerName.bind(this);
    this.onClearSearchAddress = this.onClearSearchAddress.bind(this);
    this.onClearSearchOfficeName = this.onClearSearchOfficeName.bind(this);
    this.getSubmissionList = this.getSubmissionList.bind(this);
    this.getSubmissionListUrl = this.getSubmissionListUrl.bind(this);
    this.setEmployers = this.setEmployers.bind(this);
    this.handleAutoChange = this.handleAutoChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSelectCoverLetter = this.onSelectCoverLetter.bind(this);
    this.onDeleteCoverLetter = this.onDeleteCoverLetter.bind(this);
    this.openAttachmentsMenu = this.openAttachmentsMenu.bind(this);
    this.closeAttachmentsMenu = this.closeAttachmentsMenu.bind(this);
    this.onAddAttachment = this.onAddAttachment.bind(this);
    this.onDeleteAttachment = this.onDeleteAttachment.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.onSaveAsConfirmation = this.onSaveAsConfirmation.bind(this);
    this.onSaveAsConfirmationClose = this.onSaveAsConfirmationClose.bind(this);
    this.onSaveAs = this.onSaveAs.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearSnackbarMessage = this.clearSnackbarMessage.bind(this);

    this.editorRef = React.createRef();
  }

  onEditorChange(html) {
    this.setState({ body: html });
  }

  handleAutoChange(event, item) {
    //console.log('handleAutoChange: ', event, item);
    if (item) {
      this.setState({ coverLetterName: item.label, coverLetterError: '' });
    }
  }

  handleInputChange(event, autoValue) {
    if (!event) return false;
    const { name, value } = event.target;
    if (name) {
      this.setState({ [name]: value });
    } else if (autoValue) {
      this.setState({ autoValue, coverLetterError: '' });
    }
  }

  async componentDidMount() {
    // Get the recruiter's role to use for permissions
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({ user });

    const candidateId = this.props.params.id;
    const url = `${env.baseUrl}/v1/candidate/${candidateId}`;
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        return this.setState({ candidate: data[0] });
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message, error });
      });

    const attachmentsUrl = `${env.baseUrl}/v1/candidate/${candidateId}/attachment`;
    fetch(attachmentsUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        return this.setState({ availableAttachments: data });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });

    const coverLetterUrl = `${env.baseUrl}/v1/candidate/${candidateId}/cover-letter`;
    fetch(coverLetterUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        this.setState({ coverLetters: data });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });

    const submitType = this.props.submitType || SubmissionType.submitCandidate; // blindSubmission, followUp
    this.setState({ submitType }, () => {
      const { pageSize, pageIndex } = this.state;
      this.getSubmissionList({ pageSize, pageIndex });
    });
  }

  onClearSearchEmployerName() {
    this.setState({ searchEmployerName: '' }, this.onSearchEmployers);
  }

  onClearSearchAddress() {
    this.setState({ searchAddress: '' }, this.onSearchEmployers);
  }

  onClearSearchOfficeName() {
    this.setState({ searchOfficeName: '' }, this.onSearchEmployers);
  }

  onSearchEmployers(event) {
    if (event) event.preventDefault();
    const { pageSize, pageIndex } = this.state;
    this.getSubmissionList({ pageSize, pageIndex });
  }

  getSubmissionListUrl(pageIndex, pageSize) {
    const candidateId = this.props.params.id;
    let url = null;
    switch (this.state.submitType) {
      case SubmissionType.blindSubmission:
        url = `${env.baseUrl}/v1/candidate/${candidateId}/approval-list`;
        break;
      default:
      case SubmissionType.submitCandidate:
        url = `${env.baseUrl}/v1/candidate/${candidateId}/submission-list`;
        break;
      case SubmissionType.followUp:
        url = `${env.baseUrl}/v1/candidate/${candidateId}/follow-up-list`;
        break;
    }

    let queryParams = '';
    if (this.state.searchEmployerName) {
      if (queryParams) queryParams += '&';
      queryParams += `searchEmployer=${this.state.searchEmployerName}`;
    }
    if (this.state.searchAddress) {
      if (queryParams) queryParams += '&';
      queryParams += `searchAddress=${this.state.searchAddress}`;
    }
    if (this.state.searchOfficeName) {
      if (queryParams) queryParams += '&';
      queryParams += `searchOffice=${this.state.searchOfficeName}`;
    }
    if (!this.state.showAllEmployers) {
      if (queryParams) queryParams += '&';
      queryParams += `showOffices=false`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    return url;
  }

  getSubmissionList({ pageSize, pageIndex }) {
    this.setState({ pageSize, pageIndex, loading: true });

    const url = this.getSubmissionListUrl();
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(result => this.setEmployers(result.data || []))
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });
  }

  setEmployers(employers) {
    //console.log(`setEmployers: `, employers);
    this.setState({ employers });

    if (this.state.submitType === SubmissionType.blindSubmission) {
      const approvalList = employers.filter(e => !!e.approval_list);
      this.setState({ submissionList: approvalList });

    } else if (this.state.submitType === SubmissionType.followUp) {
      const submitted = employers.filter(e => !!e.submitted || !!e.other_submission);
      this.setState({ submissionList: submitted });

    } else {
      this.setState({ submissionList: employers });
    }

    if (this.state.loading) {
      let selectedItems = null;
      switch (this.state.submitType) {
        case SubmissionType.blindSubmission:
          selectedItems = sessionStorage.getItem('approvalList');
          break;
        default:
        case SubmissionType.submitCandidate:
          selectedItems = sessionStorage.getItem('submissionList');
          break;
        case SubmissionType.followUp:
          selectedItems = sessionStorage.getItem('submittedEmployers');
          break;
      }

      if (selectedItems) {
        const selected = selectedItems.split(',');
        for (const employer of employers) {
          const found = selected.find(id => id === employer.id);
          employer.selected = !!found;
        }
      }
    }

    const selectedEmployers = employers.filter(e => !!e.selected);
    if (this.debugSubmitWithoutEmployers)
      this.setState({ selectedEmployers, sendEmailDisabled: false });
    else
      this.setState({ selectedEmployers, sendEmailDisabled: selectedEmployers.length === 0 });

    this.setState({ loading: false });
  }

  onAddAttachment(id) {
    this.closeAttachmentsMenu();
    const found = this.state.attachments.find(a => a.id === id);
    if (found) {
      return;
    }

    const attachment = this.state.availableAttachments.find(a => a.id === id);
    if (attachment) {
      //console.log('onAddAttachment: ', attachment);
      const attachments = [...this.state.attachments];
      attachments.push(attachment);
      const totalMessageSize = this.state.totalMessageSize + attachment.size;
      //console.log('onAddAttachment totalMessageSize: ', totalMessageSize);
      const sizeErrorMessage = (totalMessageSize > MAX_MESSAGE_SIZE) ? 'Attachments total size exceeds ' + formatBytes(MAX_MESSAGE_SIZE) : null;
      this.setState({ attachments, totalMessageSize, sizeErrorMessage });
    }
  }

  onDeleteAttachment(event, attachment, index) {
    //console.log('onDeleteAttachment: ', attachment);
    event.preventDefault();
    const attachments = [...this.state.attachments];
    attachments.splice(index, 1);

    const totalMessageSize = this.state.totalMessageSize - attachment.size;
    //console.log('onDeleteAttachment totalMessageSize: ', totalMessageSize);
    const sizeErrorMessage = (totalMessageSize > MAX_MESSAGE_SIZE) ? 'Attachments total size exceeds ' + formatBytes(MAX_MESSAGE_SIZE) : null;
    this.setState({ attachments, totalMessageSize, sizeErrorMessage });
  }

  onSelectCoverLetter(event, coverLetter, index) {
    event.preventDefault();
    const selectCoverLetter = coverLetter.name || '';
    this.setState({
      coverLetter,
      coverLetterIndex: index,
      coverLetterName: coverLetter.name,
      selectCoverLetter,
      subject: coverLetter.subject,
      body: coverLetter.body
    });
    if (this.editorRef?.current)
      this.editorRef.current.setValue(coverLetter.body);
    else
      console.error('Unable to set email body');
  }

  onDeleteCoverLetter(event, coverLetter, index) {
    event.preventDefault();
    //console.log('onDeleteCoverLetter');
    this.deleteCoverLetter(coverLetter);
  }

  validate() {
    this.setState({ successMessage: null });

    if (!this.state.subject) {
      this.setState({ errorMessage: 'Subject is required' });
      return false;
    }

    if (!this.state.body) {
      this.setState({ errorMessage: 'Body is required' });
      return false;
    }

    //console.log(this.state.selectedEmployers);
    if (environment === 'production') {
      if (this.state.selectedEmployers?.length === 0) {
        this.setState({ errorMessage: 'Select Company/Firm/Office(s) as email recipients' });
        return false;
      }
    }

    if (this.state.selectedEmployers?.length) {
      const emptyEmails = this.state.selectedEmployers.filter(e => !e.email_to);
      if (emptyEmails.length > 0) {
        this.setState({ errorMessage: 'Selected Company/Firm/Office(s) must have a Recruiting Coordinator email' });
        return false;
      }
    }

    this.setState({ errorMessage: null });
    return true;
  }

  onSaveAsConfirmation() {
    const coverLetterName = this.state.coverLetterName || '';
    this.setState({ openSaveAsDialog: true, coverLetterName, coverLetterError: '' });
  };

  onSaveAsConfirmationClose() {
    this.setState({ openSaveAsDialog: false });
  }

  onSaveAs() {
    if (!this.state.coverLetterName) {
      this.setState({ coverLetterError: 'Cover Letter Name is required' });
      return;
    }
    this.setState({ coverLetterError: null });

    const found = this.state.coverLetters.find(cl => cl.name === this.state.coverLetterName);
    if (found) {
      this.updateCoverLetter(found);
    } else {
      this.addCoverLetter();
    }
  }

  addCoverLetter() {
    const name = this.state.coverLetterName;
    const candidateId = this.props.params.id;
    const url = `${env.baseUrl}/v1/candidate/${candidateId}/cover-letter`;
    const payload = {
      name,
      description: this.state.coverLetterDescription,
      subject: this.state.subject,
      body: this.state.body,
    };

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(response => checkStatus(response))
      .then(({ data, coverLetter }) => {
          this.state.coverLetters.push(coverLetter);

          const snackbarMessage = `Saved Cover Letter: ${name}`;
          this.setState({ coverLetters: this.state.coverLetters, snackbarMessage, errorMessage: '' });
          this.onSaveAsConfirmationClose();
        })
        .catch(error => {
          const msg = error.error?.detail || error.message;
          console.error(msg, error);
          this.setState({ coverLetterError: msg, error });
        });
  }

  updateCoverLetter(letter) {
    const name = this.state.coverLetterName;
    const description = this.state.coverLetterDescription;
    const { subject, body } = this.state;

    const url = `${env.baseUrl}/v1/cover-letter/${letter.id}`;
    const payload = {
      name,
      description,
      subject,
      body,
    };

    fetch(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(response => checkStatus(response))
      .then(({ data, coverLetter }) => {
          letter.name = name;
          letter.description = description;
          letter.subject = subject;
          letter.body = body;

          const snackbarMessage = `Updated Cover Letter: ${name}`;
          this.setState({ coverLetters: this.state.coverLetters, snackbarMessage, errorMessage: '' });
          this.onSaveAsConfirmationClose();
        })
        .catch(error => {
          const msg = error.error?.detail || error.message;
          console.error(msg, error);
          this.setState({ coverLetterError: msg, error });
        });
  }

  deleteCoverLetter(letter) {
    const name = this.state.coverLetterName;
    const url = `${env.baseUrl}/v1/cover-letter/${letter.id}`;

    fetch(url, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(response => checkStatus(response))
      .then(({ data }) => {
          const coverLetters = this.state.coverLetters.filter(cl => cl.name !== name);
          const snackbarMessage = `Deleted Cover Letter: ${name}`;
          this.setState({ coverLetters, coverLetter: null, coverLetterName: undefined, snackbarMessage, errorMessage: '' });
          this.onSaveAsConfirmationClose();
        })
        .catch(error => {
          const msg = error.error?.detail || error.message;
          console.error(msg, error);
          this.setState({ coverLetterError: msg, error });
        });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      this.setState({ successMessage: '', errorMessage: '' });
      this.setState({ sending: true, sendEmailDisabled: true });

      const payload = {
        employers: this.state.selectedEmployers,
        candidate: this.state.candidate,
        to: this.state.selectedEmployers.map(e => e.email_to).join(','),
        subject: this.state.subject,
        body: this.state.body,
        attachments: this.state.attachments,
        submitType: this.state.submitType,
      };
      const url = `${env.baseUrl}/v1/candidate/${this.props.params.id}/submit`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        credentials: 'include',
      })
        .then(response => checkStatus(response))
        .then(response => {
          const msg = (response.errors) ? response.errors[0] : '';
          this.setState({ successMessage: response.success, errorMessage: msg });
        })
        .catch(error => {
          console.error(error);
          if (error.code === 401) {
            return goToLogin();
          }
          this.setState({ errorMessage: error.message || `${this.state.submitType} failed`, successMessage: '' });
          this.setState({ sendEmailDisabled: false });
        })
        .finally(() => this.setState({ sending: false }));
    }
  }

  openAttachmentsMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ open: true, anchorEL: event.currentTarget });
  }

  closeAttachmentsMenu() {
    this.setState({ open: false, anchorEL: null });
  }

  clearSnackbarMessage() {
    this.setState({ snackbarMessage: '' });
  }

  render() {
    if (this.state.loading) {
      return <Container sx={{ m: '2rem' }}>
        <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
        Loading Candidate...
      </Container>;
    }

    const c = this.state.candidate;
    if (!c || c === []) {
      return <p>Unable to find candidate</p>;
    }

    const recruiterId = this.state.candidate?.recruiter_id;
    const isRecruiter = this.state.user.id === recruiterId;
    const isAdmin = this.state.user.role === 'Administrator' || this.state.user.role === 'Manager';
    const readonly = !isRecruiter && !isAdmin;

    const { first_name, last_name } = c;
    const candidateName = `${first_name} ${last_name}`;
    const backUrl = `/candidate/${this.props.params.id}`;

    let title = `Submit Candidate`;
    if (this.state.submitType === SubmissionType.blindSubmission) {
      title = `Blind Submission`;
    } else if (this.state.submitType === SubmissionType.followUp) {
      title = `Follow up`;
    }

    const coverLetters = this.state.coverLetters.map(cl => ({
      label: cl.name,
      value: cl.id,
    }));

    return <div className="submit-candidate">
      <Container className="submission-list-container" component="div" maxWidth={false}>
        <Container component="div" sx={{ display: 'flex', alignItems: 'top', justifyContent: 'space-between', width: '100%', margin: '0' }} maxWidth={false} disableGutters={true}>
          <h1>{title} for <BackButton url={backUrl} text={candidateName}/></h1>

          <SearchForm>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={this.onSearchEmployers}
              sx={{ display: 'flex', gap: '1rem', alignItems: 'top' }}
            >
              <Box
                component="div"
                noValidate
                autoComplete="off"
                sx={{ display: 'flex', flexDirection:'column', gap: '1rem', alignItems: 'center' }}
              >
                <Box
                  component="div"
                  noValidate
                  autoComplete="off"
                  sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
                >
                  <TextField name="searchEmployerName" value={this.state.searchEmployerName} label="Search by Company/Firm" variant="outlined" onChange={this.handleInputChange}
                    sx={{ minWidth: '15rem' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Clear onClick={this.onClearSearchEmployerName} sx={{ cursor: 'pointer' }} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField name="searchOfficeName" value={this.state.searchOfficeName} label="Search by Office Name" variant="outlined" onChange={this.handleInputChange}
                    sx={{ minWidth: '15rem' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Clear onClick={this.onClearSearchOfficeName} sx={{ cursor: 'pointer' }} />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <TextField name="searchAddress" value={this.state.searchAddress} label="Search by Address" variant="outlined" fullWidth onChange={this.handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Clear onClick={this.onClearSearchAddress} sx={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <div>
                <Button type="submit" variant="contained" sx={{ padding: '0.5rem 2rem' }}>Search</Button>
              </div>
            </Box>
          </SearchForm>
        </Container>

        <P>List of Employers/Offices that are available for submission.</P>
        <SubmissionList
          submissionList={this.state.submissionList}
          updateEmployers={this.setEmployers}
        />
      </Container>

      <Container component="div" sx={{ margin: '2rem 0 2rem', textAlign: 'left' }} maxWidth={false}>
        <h2>Email to Employers/Offices</h2>

        <Box
          component="form"
          className="email-form"
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
          sx={{ mb: '1rem' }}
        >
          { this.state.coverLetters?.length > 0 &&
            <Box sx={{ display:'flex', alignItems:'center' }}>
              <FormControl className="select-cover-letter-form-control" variant="standard">
                <InputLabel id="cover_letter_label" className="select-label">Select Cover Letter</InputLabel>
                <Select
                  name="coverLetter"
                  labelId="cover_letter_label"
                  variant="outlined"
                  defaultValue=""
                >
                  { this.state.coverLetters.map((letter,index) =>
                    <MenuItem key={letter.id} value={letter.name} onClick={(event) => this.onSelectCoverLetter(event, letter, index)}>
                      {letter.name}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              { this.state.coverLetter && !readonly &&
                <Tooltip title="Delete Cover Letter">
                  <IconButton onClick={(event) => this.onDeleteCoverLetter(event, this.state.coverLetter, this.state.coverLetterIndex)} sx={{ mb:'1rem' }}><Delete /></IconButton>
                </Tooltip>
              }
            </Box>
          }

          <TextField
            name="subject"
            label="Email Subject"
            value={this.state.subject}
            className="subject"
            onChange={this.handleInputChange}
            disabled={readonly}
          />

          <Editor
            placeholder="Email Body"
            ref={this.editorRef}
            value={this.state.body}
            onChange={this.onEditorChange}
            disabled={readonly}
          />
          <Box className="replacement-tags-label">
            <Box sx={{ minWidth:'23rem'}}>Use these replacement tags in your Email Subject &amp; Body: </Box>
            <Tooltip title="First &amp; Last name of candidate"><span>&#123;candidateName&#125;</span></Tooltip>
            <Tooltip title="Email address of candiate"><span>&#123;candidateEmail&#125;</span></Tooltip>
            <Tooltip title="Comma separated first names"><span>&#123;employerFirstNames&#125;</span></Tooltip>
            <Tooltip title="Comma separated last names"><span>&#123;employerLastNames&#125;</span></Tooltip>
            <Tooltip title="Employer official company name"><span>&#123;employerCompany&#125;</span></Tooltip>
            <Tooltip title="Optional Employer prefererred name, defaults to &#123;employerCompany&#125;"><span>&#123;employerPreferredName&#125;</span></Tooltip>
            <Tooltip title="Comma list of employer email addresses"><span>&#123;employerEmails&#125;</span></Tooltip>
          </Box>

          { this.state.attachments.length > 0 &&
            <FormControl className="attachments-form-control" variant="standard">
              <Typography className="attachments-label">Attachments</Typography>
              <Attachments
                attachments={this.state.attachments}
                onDeleteAttachment={this.onDeleteAttachment}
                sx={{ width: '100%' }}
              />
            </FormControl>
          }
          { this.state.totalMessageSize > 0 &&
            <Typography className="attachments-size">Total size: {formatBytes(this.state.totalMessageSize)}</Typography>
          }
          { this.state.sizeErrorMessage &&
            <Alert severity="error" sx={{ mb:'1rem' }}>{ this.state.sizeErrorMessage }</Alert>
          }

          <div className="submit-actions">
            { this.state.availableAttachments?.length > 0 &&
            <>
            <Button
              id="add-attachments-btn"
              variant="outlined"
              aria-controls={this.state.open ? 'add-attachments-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={this.state.open ? 'true' : undefined}
              onClick={this.openAttachmentsMenu}
              disabled={readonly}
            >
              Add Attachments
              <ArrowDropDown sx={{ml: '0.25rem'}}/>
            </Button>
            <Menu
              id="add-attachments-menu"
              anchorEl={this.state.anchorEL}
              open={this.state.open}
              onClose={this.closeAttachmentsMenu}
              MenuListProps={{
                'aria-labelledby': 'add-attachments-btn',
              }}
            >
              { this.state.availableAttachments.map((file,index) =>
                <MenuItem key={file.id} onClick={(event) => this.onAddAttachment(file.id)}>
                  <span className="attachment-category">{file.category}</span>
                  &nbsp;
                  <span className="attachment-name">{file.name}</span>
                  &nbsp;
                  <span className="attachment-size">({formatBytes(file.size)})</span>
                </MenuItem>
              )}
            </Menu>
            </>
            }

            <Button type="submit" variant="contained" disabled={this.state.sendEmailDisabled || readonly}>
              { this.state.sending &&
                <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
              }
              { !this.state.sending &&
                <EmailIcon sx={{mr:'0.5rem'}} />
              }
              Send Email
            </Button>
            <Button variant="outlined" onClick={this.onSaveAsConfirmation} disabled={!this.state.subject || !this.state.body || readonly}>Save As...</Button>
          </div>
        </Box>

        <Snackbar open={!!this.state.snackbarMessage} autoHideDuration={5000} onClose={this.clearSnackbarMessage}>
          <Alert severity="success" sx={{ mb:'1rem' }}>{ this.state.snackbarMessage }</Alert>
        </Snackbar>

        { this.state.successMessage &&
          <Alert severity="success" sx={{ mb:'1rem' }}>{ this.state.successMessage }</Alert>
        }
        { this.state.errorMessage &&
        <Alert severity="error" sx={{ mb:'1rem' }}>{ this.state.errorMessage }</Alert>
        }

        <BackButton url={backUrl} text="Back to Candidate" />
      </Container>

      <Dialog open={this.state.openSaveAsDialog} onClose={this.onSaveAsConfirmationClose}>
        <DialogTitle sx={{ textAlign: 'left' }}>Save Cover Letter</DialogTitle>
        <DialogContent sx={{ textAlign: 'left' }}>
        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.3rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <Autocomplete
            disablePortal
            options={coverLetters}
            sx={{ width: 300 }}
            name="coverLetterNameSelect"
            autoComplete={true}
            onChange={this.handleAutoChange}
            onInputChange={this.handleInputChange}
            defaultValue={this.state.coverLetterName}
            renderInput={(params) => <TextField {...params} name="coverLetterName" label="Name" />}
          />

          <TextField name="coverLetterDescription" value={this.state.coverLetterDescription} label="Description" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.coverLetterError }</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onSaveAs} color="primary" variant="contained" size="small">Save</Button>
          <Button onClick={this.onSaveAsConfirmationClose} color="primary" variant="outlined" size="small" autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>

    </div>;
  }
}

export default withParams(SubmitCandidatePage);

