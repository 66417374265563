
const config = {
  development: {
    //baseUrl: 'http://68.203.10.50:8000'
    baseUrl: 'http://localhost:8000'
  },
  production: {
    baseUrl: 'https://api.lateral-match.com'
  }
};

module.exports = config;
