import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { Link } from "@mui/material";


export default function RecruitersStatsTable({ stats }) {

  const locale = Intl.NumberFormat().resolvedOptions().locale;
  const navigate = useNavigate();

  // 1. Create Columns
  const columns = React.useMemo(
    () => [
    {
      Header: 'Recruiter',
      accessor: 'email',
      Cell: ({value, row}) => {
        const { id, email } = row.original;
        const url = `/recruiter/${id}`;
        //let name = last_name || '';
        //if (first_name) name += `, ${first_name}`;
        return email ? <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} underline="hover">{email}</Link> : '';
      }
    },
    {
      Header: 'Month',
      accessor: 'month',
      Cell: ({value, row}) => {
        const { month } = row.original;
        if (!month) return '';
        const date = new Date(month);
        return date.toLocaleString(locale, { month: 'long', year: 'numeric', timeZone: 'UTC' });
      }
    },
    {
      Header: 'Blind Sub',
      accessor: 'blind_submission',
      tipText: 'Blind Submissions',
    },
    {
      Header: 'Approved',
      accessor: 'approved'
    },
    {
      Header: 'Declined',
      accessor: 'declined'
    },
    {
      Header: 'Sub List',
      accessor: 'submission_list',
      tipText: 'Submission List',
    },
    {
      Header: 'Other Sub',
      accessor: 'other_submission',
      tipText: 'Other Submissions',
    },
    {
      Header: 'Sub',
      accessor: 'submitted',
      tipText: 'Submitted',
    },
    {
      Header: 'Resub',
      accessor: 'resubmitted',
      tipText: 'Resubmitted',
    },
    {
      Header: 'Under Review',
      accessor: 'under_review'
    },
    {
      Header: 'Interview',
      accessor: 'interview'
    },
    {
      Header: 'Interviewed But Rejected',
      accessor: 'interviewed_but_rejected'
    },
    {
      Header: 'Offer',
      accessor: 'offer'
    },
    {
      Header: 'Rejected',
      accessor: 'rejected'
    },
    {
      Header: 'Follow Up',
      accessor: 'follow_up'
    },
    {
      Header: 'Placed',
      accessor: 'placed'
    }], [locale, navigate]
  );

  // 2. Call Use Table
  const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
  } = useTable({ columns, data: stats }, useSortBy);

  // 3. Return the HTML Table
  return (<>
    <table {...getTableProps()}>
      <thead>
          {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th {...column.getHeaderProps(column.getSortByToggleProps({ title: column.tipText }))}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                  ))}
              </tr>
          ))}
      </thead>
      <tbody {...getTableBodyProps()}>
          {rows.map(
              (row) => {
                  prepareRow(row);
                  return (
                      <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                              return (
                                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              );
                          })}
                      </tr>
                  );
              }
          )}
      </tbody>
    </table>

    { stats?.length === 0 &&
      <p className="empty-message">No statistics</p>
    }
    { stats?.length > 0 &&
      <div className="total-count-row">
        Total Count: {" "}
        <strong>{stats.length}</strong>
      </div>
    }
  </>);
}

