import React, { Component } from 'react';
import { Box, Button, Container, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { red } from '@mui/material/colors';

import Dropzone from './Dropzone';
import { checkStatus } from '../checkStatus';
import { env } from '../env';


export default class EditMyAccount extends Component {

  constructor(props) {
    super(props);

    const { email, phone, address, city, state, zipcode, country, first_name, last_name, law_school, practice_areas, notes, username, signature, role } = this.props.recruiter;
 
    this.state = {
      email,
      phone: phone || '',
      address: address || '',
      city: city || '',
      state: state || '',
      zipcode: zipcode || '',
      country: country || 'USA',
      first_name: first_name || '',
      last_name: last_name || '',
      law_school: law_school || '',
      practice_areas: practice_areas || '',
      notes: notes || '',
      username: username || '',
      signature: signature || null,
      role: role || '',

      emailError: null,
      errorMessage: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onRemoveSignature = this.onRemoveSignature.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  validate() {
    if (!this.state.email) {
      this.setState({ emailError: 'Email is required' });
      return false;
    } else
      this.setState({ emailError: null });

    const reg = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (reg.test(this.state.email) === false) {
      this.setState({ emailError: 'Email is invalid' });
      return false;
    } else
      this.setState({ emailError: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      const { email, phone, address, city, state, zipcode, country, first_name, last_name, law_school, practice_areas, notes, username, signature, role } = this.state;

      const payload = {
        email: email?.trim(),
        phone: phone?.trim(),
        address: address?.trim(),
        city: city?.trim(),
        state: state?.trim(),
        zipcode: zipcode?.trim(),
        country: country?.trim(),
        first_name: first_name?.trim(),
        last_name: last_name?.trim(),
        law_school,
        practice_areas,
        notes,
        username,
        signature,
        role,
      };

      const url = `${env.baseUrl}/v1/recruiter/${this.props.recruiter.id}`;
      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })
        .then(response => checkStatus(response))
        .then(value => {
          this.props.onSave(payload);
        })
        .catch(error => {
          console.error(error);
          this.setState({ errorMessage: error.message, error });
        });
    }
  }

  onRemoveSignature(event) {
    this.setState({ signature: null });
  }

  onFilesAdded(files) {
    if (files?.length) {
        // this.state.files is not set yet
        for (let i=0; i < files.length; i++) {
          const file = files[i];
          let reader = new FileReader();
          reader.addEventListener('load', (event) => {
            this.setState({ signature: event.target.result });
          });
          reader.readAsDataURL(file);
        }
    }
  }

  render() {
    return (<Container sx={{ m: '0 2rem' }}>
        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.3rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField name="email" value={this.state.email} label="Email" variant="outlined" fullWidth required onChange={this.handleInputChange} error={!this.state.email || !this.state.email.includes('@')} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.emailError }</Typography>

          <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }} disableGutters={true} >
            <TextField name="first_name" value={this.state.first_name} label="First name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
            <TextField name="last_name" value={this.state.last_name} label="Last name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
          </Container>

          <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true}>
            <TextField name="first_name" value={this.state.first_name} label="First name" variant="outlined" sx={{ width: '48%' }} onChange={this.handleInputChange} />
            <TextField name="last_name" value={this.state.last_name} label="Last name" variant="outlined" sx={{ width: '48%', ml: '1.65rem !important' }} onChange={this.handleInputChange} />
          </Container>
          <TextField name="phone" value={this.state.phone} label="Phone" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <TextField name="address" value={this.state.address} label="Address" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="city" value={this.state.city} label="City" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="state" value={this.state.state} label="State" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="zipcode" value={this.state.zipcode} label="Zipcode" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="country" value={this.state.country} label="Country" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="practice_areas" value={this.state.practice_areas} label="Practice Areas" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="law_school" value={this.state.law_school} label="Law School" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <TextField name="username" value={this.state.username} label="Username for login" variant="outlined" fullWidth onChange={this.handleInputChange} />

          { this.state.signature &&
            <Box sx={{ display:'flex', flexDirection:'column', alignItems:'start', mt:'1rem !important' }}>
              <Tooltip title="Remove signature" placement="top" arrow><IconButton onClick={this.onRemoveSignature}><CancelOutlined/></IconButton></Tooltip>
              <img className="signature" src={this.state.signature} alt="signature" height="85" />
            </Box>
          }
          <Dropzone
            target="Signature"
            onFilesAdded={this.onFilesAdded}
            disabled={this.state.uploading || this.state.successfullyUploaded}
          />

          <Container sx={{ display: 'flex', gap: '0.5rem' }} disableGutters={true}>
            <Button type="submit" variant="contained">Save</Button>
            <Button type="button" variant="outlined" onClick={this.props.onCancel}>Cancel</Button>
          </Container>
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
        </Box>
      </Container>);
  }
}

