import { Component } from 'react';
import { Box, Button, Container, FormControl, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useParams } from 'react-router';
import { useNavigate, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  attachment: null,
  employer: null,
  terms: '',
  notes: '',
  execution: '',
  expiration: '',
  category: null,
  invoice: null,
  showInvoice: false,
  errorMessage: null
};

const H1 = styled.h1`
  margin: 0;
`;
const P = styled.p`
  margin-left: 2rem;
  margin-bottom: 2rem;
  text-align: left;
`;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const BackButton = (props) => {
  const navigate = useNavigate();
  return (
    <Link href={props.url} onClick={(evt) => {evt.preventDefault();navigate(props.url)}} underline="hover">{props.text}</Link>
  )
}

class EmplAttachmentPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  handleCategoryChange(event) {
    const { name, value } = event.target;
    //console.log(`handleCategoryChange: ${name} = ${value}`);
    if (name) {
      this.setState({ [name]: value });
      this.setState({ showInvoice: value === 'Invoice' });
    }
  }

  async componentDidMount() {
    // Get the recruiter's role to use for permissions
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({ user });

    const showInvoice = window.location.pathname?.startsWith('/invoice');
    this.setState({ showInvoice });
    //console.log('showInvoice: ', showInvoice);

    const url = `${env.baseUrl}/v1/empl-attachment/${this.props.params.id}`;
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        const attachment = items.data[0];
        const { terms, notes, category } = attachment;
        // TIMESTAMPS are 2022-10-01T05:00:00.000Z, TextField requires yyyyt-MM-dd
        const execution = attachment.execution ? attachment.execution.split('T')[0] : '';
        const expiration = attachment.expiration ? attachment.expiration.split('T')[0] : '';
        const invoice = attachment.invoice || null;
        const showInvoice = !!invoice || category === 'Invoice';
        this.setState({ attachment, terms, notes, execution, expiration, category, invoice, showInvoice });
        if (attachment.employer_id)
          this.getEmployer(attachment.employer_id);
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message || 'Unable to load attachment', error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  async getEmployer(id) {
    const employerUrl = `${env.baseUrl}/v1/employer/${id}`;
    fetch(employerUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        return this.setState({ employer: items.data[0] });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message || 'Unable to load employer', error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  deleteAttachment() {
    const url = `${env.baseUrl}/v1/empl-attachment/${this.props.params.id}`;
    fetch(url, {
        method: 'DELETE',
        body: '{}',
        headers:{ 'Content-Type':'application/json' },
        credentials: 'include',
    })
      .then(result => checkStatus(result))
      .then(items => {
        const forward = `/employer/${this.state.employer.id}`;
        this.setState({ forward });
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { terms, notes, execution, expiration, category, invoice } = this.state;

    const payload = { terms, notes, category };
    if (execution) {
      payload.execution = execution;
    }
    if (expiration) {
      payload.expiration = expiration;
    }
    if (invoice) {
      payload.invoice = invoice;
    }

    const attachmentId = this.props.params.id;
    const url = `${env.baseUrl}/v1/empl-attachment/${attachmentId}`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
      .then(result => checkStatus(result))
      .then(result => {
        if (this.state.employer?.id)
          this.setState({ forward: `/employer/${this.state.employer.id}` });
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }

    const a = this.state.attachment;
    if (!a || a === []) {
      return <p>Unable to find attachment</p>;
    }
    const employerUrl = `/employer/${a.employer_id}`;

    const isAdmin = this.state.user?.role === 'Administrator' || this.state.user.role === 'Manager';
    // Don't show Delete option for invoices (only do deletes from the employer)
    const showInvoice = window.location.pathname?.startsWith('/invoice');

    return <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', m: '1rem 0' }}>
        <H1>Attachment {a.name}</H1>

        { isAdmin && !showInvoice &&
          <div className="empl-attachment-actions">
            <Button variant="outlined" onClick={this.deleteAttachment}>Delete Attachment</Button>
          </div>
        }
      </Container>

      <Box
        component="form"
        sx={{ '& > :not(style)': { m: '0.5rem 0' }, m: '0 2rem 1rem' }}
        noValidate
        align="left"
        autoComplete="off"
        onSubmit={this.handleSubmit}
      >
        <TextField name="terms" value={this.state.terms} label="Terms" variant="outlined" multiline minRows="3" maxRows="20" fullWidth onChange={this.handleInputChange} />

        <TextField name="notes" value={this.state.notes} label="Notes" variant="outlined" multiline minRows="3" maxRows="20" fullWidth onChange={this.handleInputChange} />

        <TextField name="execution" value={this.state.execution} label="Execution Date" type="date" variant="outlined" InputLabelProps={{shrink: true}} fullWidth onChange={this.handleInputChange} />

        <TextField name="expiration" value={this.state.expiration} label="Expiration Date" type="date" variant="outlined" InputLabelProps={{shrink: true}} fullWidth onChange={this.handleInputChange} />

        <h2>Attachment</h2>
        <FormControl variant="standard" sx={{ minWidth: '12rem' }}>
          <InputLabel id="select-attachment-category-label" className="select-label" sx={{ ml: '1rem' }}>Attachment Category</InputLabel>
          <Select
            name="category"
            labelId="select-attachment-category-label"
            variant="outlined" onChange={this.handleCategoryChange}
            defaultValue={this.state.category}
            sx={{ mb: '0.25rem' }}
          >
            <MenuItem value="Unknown">Unknown</MenuItem>
            <MenuItem value="None">None</MenuItem>
            <MenuItem value="Fee Agreement">Fee Agreement</MenuItem>
            <MenuItem value="Job Description">Job Description</MenuItem>
            <MenuItem value="Attachment">Attachment</MenuItem>
            <MenuItem value="Invoice">Invoice</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>

        { this.state.category === 'Invoice' &&
          <TextField name="invoice" label="Invoice Number" type="number" variant="outlined" defaultValue={this.state.invoice} InputLabelProps={{shrink: true}} fullWidth onChange={this.handleInputChange} />
        }

        <Button type="submit" variant="contained" sx={{ display: 'block' }}>Submit</Button>
        <Typography sx={{ mt: '0 !important' }}>{ this.state.successMessage }</Typography>
        <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>

        { this.state.employer &&
          <P>Company/Firm: <BackButton url={employerUrl} text={this.state.employer.company} /></P>
        }
      </Box>

    </>;
  }
}

export default withParams(EmplAttachmentPage);

