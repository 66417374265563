import React from 'react';
import { useTable, useSortBy } from 'react-table';


export default function RecruitersStatsTable({ stats }) {

  const locale = Intl.NumberFormat().resolvedOptions().locale;

  // 1. Create Columns
  const columns = React.useMemo(
    () => [{
      Header: 'Month',
      accessor: 'month',
      Cell: ({value, row}) => {
        const { month } = row.original;
        if (!month) return '';
        const date = new Date(month);
        return date.toLocaleString(locale, { month: 'long', year: 'numeric', timeZone: 'UTC' });
      }
    },
    {
      Header: 'Blind Submissions',
      accessor: 'blind_submission'
    },
    {
      Header: 'Candidate Submissions',
      accessor: 'submit_candidate'
    },
    {
      Header: 'Follow Ups',
      accessor: 'follow_up'
    }], [locale]
  );

  // 2. Call Use Table
  const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
  } = useTable({ columns, data: stats }, useSortBy);

  // 3. Return the HTML Table
  return (<>
    <table {...getTableProps()}>
      <thead>
          {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                  ))}
              </tr>
          ))}
      </thead>
      <tbody {...getTableBodyProps()}>
          {rows.map(
              (row) => {
                  prepareRow(row);
                  return (
                      <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                              return (
                                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              );
                          })}
                      </tr>
                  );
              }
          )}
      </tbody>
    </table>

    { stats?.length === 0 &&
      <p className="empty-message">No statistics</p>
    }
    { stats?.length > 0 &&
      <div className="total-count-row">
        Total Count: {" "}
        <strong>{stats.length}</strong>
      </div>
    }
  </>);
}

