import React, { useState } from "react";
import { Button, Link, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import './MobileMenu.css';
import matchLogo from '../assets/logo.png';


export default function MobileMenu() {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const user = JSON.parse(localStorage.getItem('user'));
  const role = user.role || 'Recruiter';
  const isAdmin = role === 'Manager' || role === 'Administrator';

  return (
    <div className="mobile-menu">
      <img className="mobile-logo" src={matchLogo} alt="Lateral Match logo" />

      <Button
        id="mobile-menu-btn"
        aria-haspopup="true"
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>

      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'mobile-menu-btn' }}
      >
        <MenuItem href="/home" component={Link} onClick={handleClose}>Home</MenuItem>
        <MenuItem href="/candidates" component={Link} onClick={handleClose}>Candidates</MenuItem>

        { isAdmin &&
        <MenuItem href="/recruiters" component={Link} onClick={handleClose}>Recruiters</MenuItem>
        }

        <MenuItem href="/employers" component={Link} onClick={handleClose}>Employers</MenuItem>

        { isAdmin &&
        <MenuItem href="/invoices" component={Link} onClick={handleClose}>Invoices</MenuItem>
        }

        <MenuItem href="/exclusive" component={Link} onClick={handleClose}>Exclusive</MenuItem>
        <MenuItem href="/do-not-contact" component={Link} onClick={handleClose}>Do Not Contact</MenuItem>
      </Menu>
    </div>
  );
}

