import { Component } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Container, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { ThumbUp } from '@mui/icons-material';
import { Navigate } from "react-router-dom";
import { useParams } from "react-router";
import { green, red } from '@mui/material/colors';
import styled from "styled-components";

import Attachments from '../components/Attachments';
import AddAttachment from '../components/AddAttachment';
import { formatBytes } from '../utils/formatBytes';

import Recruiter from '../components/Recruiter';
import EditRecruiter from '../components/EditRecruiter';
import SubmissionStatsTable from '../components/SubmissionStatsTable';
import RecruiterStatsTable from '../components/RecruiterStatsTable';
import Candidates from '../components/Candidates';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const H1 = styled.h1`
  margin: 0;
`;
const H2 = styled.h2`
  margin: 1.5rem 0 0.25rem;
  text-align: left;
`;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const AddCandidateButton = (props) => {
  const navigate = useNavigate();
  const recruiterId = props.recruiterId;

  return (
    <Button onClick={()=> navigate(`/recruiter/${recruiterId}/add-candidate`)} variant="outlined">Add New Candidate</Button>
  )
}

const defaultState = {
  user: null,
  loading: true,
  recruiter: null,
  notes: '',

  showUploadAttachment: false,
  attachmentCategory: 'None',
  attachments: [],
  totalAttachmentsSize: 0,
  attachButtonText: 'Add Attachment',

  stats: [],
  submissionStats: [],

  role: 'Recruiter',
  editMode: false,
  anchorEL: null,
  open: false,
  openDeleteDialog: false,
  errorMessage: null,
};

class RecruiterPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.openActionsMenu = this.openActionsMenu.bind(this);
    this.closeActionsMenu = this.closeActionsMenu.bind(this);
    this.onSaveRecruiter = this.onSaveRecruiter.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onUpdateRecruiter = this.onUpdateRecruiter.bind(this);
    this.onShowUploadAttachment = this.onShowUploadAttachment.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.onDeleteConfirmationClose = this.onDeleteConfirmationClose.bind(this);
    this.onDeleteRecruiter = this.onDeleteRecruiter.bind(this);
    this.onDeleteAttachment = this.onDeleteAttachment.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  async componentDidMount() {
    // Get the recruiter's role to use for permissions
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({ user });

    const recruiterId = this.props.params.id;

    const url = `${env.baseUrl}/v1/recruiter/${recruiterId}`;
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        const [recruiter] = data;
        const notes = recruiter.notes || '';
        return this.setState({ recruiter, notes });
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message, error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    const attachmentsUrl = `${env.baseUrl}/v1/recruiter/${recruiterId}/attachment`;
    fetch(attachmentsUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        let totalAttachmentsSize = 0;
        for (const attachment of data) {
          totalAttachmentsSize += attachment.size;
        }
        //console.log(`attachments (${totalAttachmentsSize}): `, data);
        return this.setState({ attachments: data, totalAttachmentsSize });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });

    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const start = firstDay.toISOString();
    const end = lastDay.toISOString();
    const statsUrl = `${env.baseUrl}/v1/recruiter/${recruiterId}/stats?start=${start}&end=${end}`;
    fetch(statsUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(result => {
        return this.setState({ stats: result.data });
      })
      .catch(err => {
        console.error(err);
        this.setState({ errorMessage: err.message });
      });

    const submissionStatsUrl = `${env.baseUrl}/v1/recruiter/${recruiterId}/submission-stats?start=${start}&end=${end}`;
    fetch(submissionStatsUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(result => {
        return this.setState({ submissionStats: result.data });
      })
      .catch(err => {
        console.error(err);
        this.setState({ errorMessage: err.message });
      });

    const recruiterUrl = `${env.baseUrl}/v1/recruiter/${user.id}`;
    fetch(recruiterUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        const recruiter = items.data[0];
        this.setState({ role: recruiter.role });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message, error });
      });
  }

  saveNotes(event) {
    event.preventDefault();
    const recruiter = this.state.recruiter;
    const url = `${env.baseUrl}/v1/recruiter/${recruiter.id}/notes`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ notes: this.state.notes }),
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(response => {
      if (response.data.rowCount === 1) {
        this.setState({ notes: this.state.notes });
        this.setSuccessMessage('Recruiter Notes saved');
      } else
        this.setState({ errorMessage: 'Save Notes failed' });
    })
    .catch(error => {
      console.error(error);
      this.setState({ errorMessage: 'Save Notes failed' });
    });
  }

  setSuccessMessage(msg) {
    this.setState({ successMessage: msg, errorMessage: '' });
    setTimeout(() => this.setState({ successMessage: '' }), 5000);
  }

  openActionsMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ open: true, anchorEL: event.currentTarget });
  }

  closeActionsMenu() {
    this.setState({ open: false, anchorEL: null });
  }

  onUpdateRecruiter() {
    this.setState({ editMode: true });
    this.closeActionsMenu();
  }

  onDeleteConfirmation() {
    this.setState({ openDeleteDialog: true });
  };

  onDeleteConfirmationClose() {
    this.setState({ openDeleteDialog: false });
  }

  onDeleteRecruiter() {
    const url = `${env.baseUrl}/v1/recruiter/${this.props.params.id}`;
    fetch(url, {
        method: 'DELETE',
        body: '{}',
        headers:{ 'Content-Type':'application/json' },
        credentials: 'include',
    })
      .then(result => checkStatus(result))
      .then(items => {
        this.setState({ forward: '/recruiters' });
        this.closeActionsMenu();
      });
  }

  onSaveRecruiter(save) {
    const recruiter = { ...this.state.recruiter };
    Object.assign(recruiter, save);
    this.setState({ editMode: false, recruiter });
  }

  onCancelEdit() {
    this.setState({ editMode: false });
  }

  onShowUploadAttachment() {
    const attachButtonText = this.state.showUploadAttachment ? 'Add Attachment' : 'Hide Add Attachment';
    this.setState({ showUploadAttachment: !this.state.showUploadAttachment, attachButtonText });
  }

  onFilesAdded(files) {
    this.setState({ files });

    const file = files[0];
    const formData = new FormData();
    formData.append('file', file);

    const recruiterId = this.props.params.id;
    const url = `${env.baseUrl}/v1/recruiter/${recruiterId}/attach`;
    fetch(url, {
      method: 'POST',
      body: formData,
      credentials:'include',
    })
      .then(result => checkStatus(result))
      .then(response => {
        const { id, name, size, type } = response;
        const attachment = { id, name, size, type };

        const attachments = [...this.state.attachments];
        attachments.push(attachment);

        const totalAttachmentsSize = this.state.totalAttachmentsSize + file.size;
        this.setState({ attachments, totalAttachmentsSize, showUploadAttachment: false, attachButtonText: 'Add Attachment' });
      })
      .catch(error => {
        console.error(error);
      });
  }

  onDeleteAttachment(event, attachment, index) {
    event.preventDefault();
    //const attachment = this.state.attachments?.length ? this.state.attachments[index] : null;
    if (!attachment) {
      console.error(`Unable to delete attachment @ ${index}`);
      return;
    }
    const url = `${env.baseUrl}/v1/recruiter/${this.state.recruiter.id}/attachment/${attachment.id}`;

    fetch(url, {
      method: 'DELETE',
      body: '{}',
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(response => {
      if (response.rowCount === 1) {
        this.setSuccessMessage('Attachment deleted');
        const attachments = [...this.state.attachments];
        attachments.splice(index, 1);

        const totalAttachmentsSize = this.state.totalAttachmentsSize - attachment.size;
        this.setState({ attachments, totalAttachmentsSize, showUploadAttachment: false, attachButtonText: 'Add Attachment' });
      } else
        this.setState({ errorMessage: 'Unable to delete attachment' });
    })
    .catch(error => {
      console.error(error);
      this.setState({ errorMessage: 'Delete attachment failed' });
    });
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }
    if (this.state.loading) {
      return <Container sx={{ m: '2rem' }}>
        <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
        Loading Recruiter...
      </Container>;
    }

    const r = this.state.recruiter;
    if (!r || r === []) {
      return <p>Unable to find recruiter</p>;
    }
    const { first_name, last_name } = r;
    const name = `${first_name || ''} ${last_name || ''}`;
    let placed = 0;
    for (const stat of this.state.stats) {
      placed += stat.placed || 0;
    }

    const recruiterId = this.props.params.id;
    const isRecruiter = this.state.user.id === recruiterId;
    const isAdmin = this.state.user.role === 'Administrator' || this.state.user.role === 'Manager';
    const readonly = !isRecruiter && !isAdmin;

    return <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: '1rem', padding:'1rem 2rem 2rem' }} disableGutters={true} maxWidth={false}>
        { !this.state.editMode &&
          <H1>Recruiter {name}</H1>
        }
        { this.state.editMode &&
          <H1>Edit Recruiter {name}</H1>
        }

        <div className="recruiter-actions">
        <Button
            id="recruiter-actions-btn"
            variant="contained"
            aria-controls={this.state.open ? 'recruiter-actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={this.state.open ? 'true' : undefined}
            onClick={this.openActionsMenu}
          >
            Actions
            <ArrowDropDown sx={{ml: '0.25rem'}}/>
          </Button>
          <Menu
            id="recruiter-actions-menu"
            anchorEl={this.state.anchorEL}
            open={this.state.open}
            onClose={this.closeActionsMenu}
            MenuListProps={{
              'aria-labelledby': 'recruiter-actions-btn',
            }}
          >
            <MenuItem onClick={this.onUpdateRecruiter}>Edit Recruiter</MenuItem>
            <MenuItem onClick={this.onDeleteConfirmation} disabled={this.state.role === 'Recruiter'}>Delete Recruiter</MenuItem>
          </Menu>

        </div>
      </Container>

      <Container sx={{ padding:'1rem 2rem 2rem', textAlign:'left' }} disableGutters={true} maxWidth={false}>
        { !this.state.editMode &&
          <>
            <Recruiter recruiter={this.state.recruiter} />

            <H2>Notes</H2>
            <Box
                component="form"
                className="notes-form"
                noValidate
                align="left"
                autoComplete="off"
                onSubmit={this.saveNotes}
                sx={{ width: '100%' }}
            >
              <TextField name="notes" multiline minRows="4" maxRows="20" defaultValue={this.state.notes} className="recruiter-notes" onChange={this.handleInputChange} sx={{ width: '100%', mb: '0.5rem' }} />

              <div className="notes-actions">
                <Button type="submit" variant="contained" className="save-notes-button">Update Notes</Button>
                <Typography sx={{ mt: '0 !important' }}>{ this.state.successMessage }</Typography>
                <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
              </div>
            </Box>
          </>
        }
        { this.state.editMode &&
          <EditRecruiter recruiter={this.state.recruiter} onSave={this.onSaveRecruiter} onCancel={this.onCancelEdit} />
        }


        { isAdmin &&
          <Container component="div" sx={{ margin: '2rem 0 2rem', textAlign: 'left'}} disableGutters={true} maxWidth={false}>
            <H2>Attachments</H2>
              <Attachments attachments={this.state.attachments} onDeleteAttachment={this.onDeleteAttachment} readonly={readonly} entity="recruiter" sx={{ width: '100%' }} />
              { this.state.totalAttachmentsSize > 0 &&
                <Typography sx={{ fontSize:'13px', opacity:'75%' }} >Total size: {formatBytes(this.state.totalAttachmentsSize)}</Typography>
              }

            { this.state.showUploadAttachment &&
              <Container component="div" sx={{ mt: '1rem', textAlign: 'left' }} disableGutters={true} maxWidth={false}>
                <h3>Add Attachment</h3>
                <AddAttachment onChange={this.onFilesAdded} target={this.state.attachmentCategory || 'Attachment'} sx={{ width: '100%' }} />
              </Container>
            }
            <Button type="button" variant="outlined" sx={{ mt: '0.5rem' }} onClick={this.onShowUploadAttachment} disabled={readonly}>{this.state.attachButtonText}</Button>
          </Container>
        }


        { this.state.stats?.length > 0 &&
          <>
            <H2>Placed Candidates</H2>
            <Box sx={{ display:'flex', alignItems:'center', mb:'0.5rem' }}>
              <ThumbUp sx={{ color: green[900], mr: '0.25rem' }}/>
              <span>Placed: {placed}</span>
            </Box>

            <H2>Submission Counts</H2>
            <SubmissionStatsTable stats={this.state.submissionStats} />

            <H2>Candidate Counts</H2>
            <RecruiterStatsTable stats={this.state.stats} />
          </>
        }

        <Candidates recruiterId={r.id} />
        <AddCandidateButton recruiterId={r.id} />
      </Container>

      <Dialog open={this.state.openDeleteDialog} onClose={this.onDeleteConfirmationClose}>
        <DialogTitle sx={{ textAlign: 'left' }}>Delete Recruiter</DialogTitle>
        <DialogContent sx={{ textAlign: 'left' }}>
          <DialogContentText>
            Do you want to Delete Recruiter {name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onDeleteRecruiter} color="primary" variant="contained" size="small">Delete</Button>
          <Button onClick={this.onDeleteConfirmationClose} color="primary" variant="outlined" size="small" autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>;
  }
}

export default withParams(RecruiterPage);

