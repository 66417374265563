import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Tooltip } from "@mui/material";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import PaginatedTable from './PaginatedTable';
import './EmployersTable.css';


export default function EmployersTable({ employers, fetchData, loading, totalCount, pageCount, pageSize }) {

  const navigate = useNavigate();

  // 1. Create Columns
  const columns = React.useMemo( () => [
    {
      id: 'expander', // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <ExpandMore/> : <ExpandLess/>}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? <ExpandMore/> : <ExpandLess/>}
            </span>
          ) : null,
    },
    {
      Header: 'Company/Firm',
      tipText: 'Employer Legal name',
      accessor: 'company',
      Cell: ({value, row}) => {
        const { id, parent_id, name, company, parent_name, parent_company, status } = row.original;
        const url = `/employer/${parent_id  || id}`;
        const cname = status === 'On Hold' ? 'reverse-link' : '';
        return <Tooltip title={parent_name || name}><Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{parent_company || company}</Link></Tooltip>;
      }
    },
    {
      Header: 'Office Count/Name',
      tipText: 'Office count for employers; Office name for offices',
      accessor: 'office_name',
      Cell: ({value, row}) => {
        const { id, parent_id, status } = row.original;
        if (!parent_id) return row.original.subRows?.length || ''
        const url = `/office/${id}`;
        const cname = status === 'On Hold' ? 'reverse-link' : '';
        //return row.original.office_name;
        return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{value}</Link>
      }
    },
    {
      Header: 'Coordinators',
      tipText: 'Recruiting Coordinator names & emails',
      accessor: 'email_to',
      Cell: ({value, row}) => {
        const { first_name, last_name, email_to } = row.original;
        const { first_name2, last_name2, email_to2 } = row.original;
        const { first_name3, last_name3, email_to3 } = row.original;
        const { first_name4, last_name4, email_to4 } = row.original;

        const name1 = `${first_name || ''} ${last_name || ''}`.trim();
        const name2 = `${first_name2 || ''} ${last_name2 || ''}`.trim();
        const name3 = `${first_name3 || ''} ${last_name3 || ''}`.trim();
        const name4 = `${first_name4 || ''} ${last_name4 || ''}`.trim();

        let coords = email_to ? (name1 ? `${name1} <${email_to}>` : email_to) : '';
        if (email_to2) {
          coords += email_to2 ? (name2 ? `\n${name2} <${email_to2}>` : `\n${email_to2}`) : '';
        }
        if (email_to3) {
          coords += email_to3 ? (name3 ? `\n${name3} <${email_to3}>` : `\n${email_to3}`) : '';
        }
        if (email_to4) {
          coords += email_to4 ? (name4 ? `\n${name4} <${email_to4}>` : `\n${email_to4}`) : '';
        }
        return <div className="wrap-cell">{ coords }</div>;
      }
    },
    {
      Header: 'Address',
      accessor: 'address',
      Cell: ({value, row}) => {
        const { city, state, country } = row.original;
        let addr = city || '';
        if (city && state) addr += ', ';
        addr += state ? `${state} ` : '';
        if (country && !country.startsWith('US')) addr += country;
        return <span>{addr}</span>;
      }
    },
    {
      Header: 'Phone',
      accessor: 'phone'
    },
    {
      Header: 'Online',
      tipText: 'Online submissions required',
      accessor: 'online_subs',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'Blinds',
      tipText: 'Blind submissions allowed',
      accessor: 'blind_feelers',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'FA',
      tipText: 'Employer Fee Agreements exist',
      accessor: 'attachments',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value, row}) => {
        if (value === null || value === 'Unknown') return '';
        return value;
      }
    }],
  [navigate]
 );

  const getRowClasses = (row) => {
    let classes = '';
    if (row.original.online_subs) {
      classes += ' online-subs';
    }
    if (row.original.blind_feelers) {
      classes += ' blind-feelers';
    }
    if (row.original.status === 'Active') {
      classes += ' active';
    } else if (row.original.status === 'On Hold') {
      classes += ' on-hold';
    } else if (row.original.status === 'Do Not Contact') {
      classes += ' do-not-contact';
    } else if (row.original.status === 'Blacklisted') {
      classes += ' blacklisted';
    }
    return (classes) ? ({ className: classes }) : {};
  }

  // 3. Return the HTML Table
  return (<>
    <PaginatedTable
      data={employers}
      columns={columns}
      fetchData={fetchData}
      loading={loading}
      totalCount={totalCount}
      pageCount={pageCount}
      pageSize={pageSize}
      target="Employers"
      getRowClasses={getRowClasses}
    />
  </>);
}

