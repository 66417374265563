import React, { Component } from 'react';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { red } from '@mui/material/colors';
import { Navigate } from "react-router-dom";
import { PropTypes } from 'prop-types';
//import { instanceOf } from 'prop-types';

import matchLogo from '../assets/logo-blue2.png';
import { checkStatus } from '../checkStatus';
import { env } from '../env';
import './LoginPage.css';


const defaultState = {
  username: null,
  password: null,
  showPassword: false,
  forward: null,
  errorMessage: null,
  cookie: null,
};

class LoginPage extends Component {

  static propTypes = {
    //cookies: instanceOf(Cookies).isRequired,
    setToken: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name) {
      this.setState({ [name]: value });
    }
  }

  toggleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  };
  
  handleMouseDownPassword(event) {
    event.preventDefault();
  };

  validate() {
    if (!this.state.username) {
      this.setState({ errorMessage: 'Username is required' });
      return false;
    } else
      this.setState({ errorMessage: null });

    if (!this.state.password) {
      this.setState({ errorMessage: 'Password is required' });
      return false;
    } else
      this.setState({ errorMessage: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      const url = `${env.baseUrl}/login`;
      const payload = {
        username: this.state.username,
        password: this.state.password,
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })
        .then(response => checkStatus(response))
        .then(response => {
          if (response.error) {
            console.error(response.error);
            this.setState({ errorMessage: response.message });
          } else {
            const { token, user } = response;
            const pathname = sessionStorage.getItem('pathname') || '/home';
            let forward = pathname;

            this.props.setToken(token);
            if (user) {
              localStorage.setItem('user', JSON.stringify(user));

              if (user.role !== 'Administrator' && user.role !== 'Manager') {
                if (pathname !== '/home' && !pathname.startsWith('/candidate') && !pathname.startsWith('/employer')) {
                  forward = '/home';
                }
              }

              this.setState({ forward });

            } else {
              console.error('Invalid user for login');
            }
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({ errorMessage: error.message || 'Invalid login' });
        });
    }
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }
    return(
      <div className="login-wrapper">
        <img className="match-logo" src={matchLogo} alt="Lateral Match logo" />
        <h1>Lateral Match Tools</h1>

        <Box
          component="form"
          noValidate
          align="center"
          autoComplete="off"
          onSubmit={this.handleSubmit}
          sx={{ width: '20rem' }}
        >
          <TextField name="username" label="Username" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />

          <TextField name="password" label="Password" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }}
            type={this.state.showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.toggleShowPassword}
                  >
                   {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Button type="submit" variant="contained">Login</Button>
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>

        </Box>
      </div>
  );
  }
}

export default LoginPage;
