import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Container, FormControl, FormControlLabel, IconButton, Link, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { useTable, useSortBy } from 'react-table';
import { Edit } from '@mui/icons-material';
import { red } from '@mui/material/colors';

import { env } from '../env';
import { checkStatus } from '../checkStatus';
import './SubmittedEmployers.css';


function SubmittedTable({ columns, data, skipPageReset, onSubmittedStatus }) {

  const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data }, useSortBy);

  const getRowClasses = (row) => {
    let classes = '';
    if (row.original.online_subs) {
      classes += 'online-subs';
    }
    if (row.original.blind_feelers) {
      if (!classes) classes += ' ';
      classes += 'blind-feelers';
    }
    if (row.original.status === 'Active') {
      classes += ' active';
    } else if (row.original.status === 'On Hold') {
      classes += ' on-hold';
    } else if (row.original.status === 'Blacklisted') {
      classes += ' blacklisted';
    }
    return (classes) ? ({ className: classes }) : {};
  }

  return (<>
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? ' 🔽'
                                    : ' 🔼'
                                  : ''}
                              </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} {...getRowClasses(row)}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                        );
                    }
                )}
            </tbody>
        </table>

      { data.length > 0 &&
        <div className="total-count-row">
              Total Count: {" "}
              <strong>{data.length}</strong>
        </div>
      }
    </>);
}

function SubmittedEmployers({ submitted, onSubmittedStatus, candidateId, readonly }) {
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const locale = Intl.NumberFormat().resolvedOptions().locale;

  // After data changes, we turn the flag back off
  React.useEffect(() => {
    // Maintain previous selections
    const submittedEmployers = sessionStorage.getItem('submittedEmployers');
    if (submittedEmployers) {
      const list = submittedEmployers.split(',');
      for (const employer of submitted) {
        const found = list.find(id => id === employer.id);
        if (found)
          employer.selected = true;
      }
    }

    setData(submitted);
    setSkipPageReset(false)
  }, [submitted])

  const onSelect = React.useCallback((event, value, row, index, data) => {
    row.selected = !row.selected;
    setData(data);
    //console.log('SubmittedEmployers.onSelect: ', data);

    // Add/remove to/from session submittedEmployers selections
    const submittedEmployers = sessionStorage.getItem('submittedEmployers');
    const list = submittedEmployers ? submittedEmployers.split(',') : [];
    const found = list.findIndex(id => id === row.id);
    if (found >= 0 && !row.selected) {
      list.splice(found, 1);
    } else if (row.selected) {
      list.push(row.id);
    }
    const selectedSubmittedEmployers = list.join(',');
    sessionStorage.setItem('submittedEmployers', selectedSubmittedEmployers);
    //console.log('submittedEmployers: ', selectedSubmittedEmployers);
  }, []);

  const onStatusChange = React.useCallback((event, status, row, index) => {
    event.preventDefault();
    setErrorMessage('');

    const url = `${env.baseUrl}/v1/candidate/${row.candidate_id}/employer/${row.employer_id}/status`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ status }),
      headers:{ 'Content-Type':'application/json' },
      credentials:'include',
    })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data.rowCount === 1) {
          setSkipPageReset(true);
          const list = [...data];
          const employer = list[index];
          updateStatus(employer, status);
          onSubmittedStatus(employer);
          setData(list);
        }
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message || 'Candidate-employer status change failed');
      });
  }, [data, onSubmittedStatus]);

  const updateStatus = (row, status) => {
    row.candidate_status = status;

    const value = new Date().toISOString();
    switch (status) {
      case 'Blind Submission':
        row.blind_submission = value;
        break;
      case 'Interested':
        row.interested = value;
        break;
      case 'Passed':
        row.passed = value;
        break;
      case 'No Openings':
        row.no_openings = value;
        break;
      case 'Approval List':
        row.approval_list = value;
        break;
      case 'Approved':
        row.approved = value;
        row.declined = null;
        row.other_submission = null;
        break;
      case 'Declined':
        row.declined = value;
        row.approved = null;
        row.other_submission = null;
        break;
      case 'Submission List':
        row.submission_list = value;
        break;
      case 'Other Submission':
        row.other_submission = value;
        break;
      case 'Submitted':
        row.submitted = value;
        break;
      case 'Resubmitted':
        row.resubmitted = value;
        break;
      case 'Under Review':
        row.under_review = value;
        break;
      case 'Interview':
        row.interview = value;
        break;
      case 'Interviewed But Rejected':
        row.interviewed_but_rejected = value;
        break;
      case 'Offer':
        row.offer = value;
        break;
      case 'Offer Declined':
        row.offer_declined = value;
        break;
      case 'Placed':
        row.placed = value;
        break;
      default:
        break;
    }
  }

  const onSaveInterview = React.useCallback((event, row, index) => {
    event.preventDefault();
    const interview = new Date(`${row.interviewDate} ${row.interviewTime}`);
    setErrorMessage('');

    const url = `${env.baseUrl}/v1/candidate/${row.candidate_id}/employer/${row.employer_id}/interview`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ value: interview }),
      headers:{ 'Content-Type':'application/json' },
      credentials:'include',
    })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data.rowCount === 1) {
          setSkipPageReset(true);
          const list = [...data];
          const employer = list[index];
          employer.interview = interview.toISOString();
          onSubmittedStatus(employer);
          setData(list);
        }
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message || 'Save interview date/time failed');
      });
  }, [data, onSubmittedStatus]);


  // 1. Create Columns
  const columns = React.useMemo(() => {
    
    const getStatusLabel = (row) => {
      let label = ''
      switch (row.candidate_status) {
        case 'Blind Submission':
          label = getDate(row.blind_submission);
          break;
        case 'Interested':
          label = getDate(row.interested);
          break;
        case 'Passed':
          label = getDate(row.passed);
          break;
        case 'No Openings':
          label = getDate(row.no_openings);
          break;
        case 'Approval List':
          label = getDate(row.approval_list);
          break;
        case 'Approved':
          label = getDate(row.approved);
          break;
        case 'Declined':
          label = getDate(row.declined);
          break;
        case 'Submission List':
          label = getDate(row.submission_list);
          break;
        case 'Other Submission':
          label = getDate(row.other_submission);
          break;
        case 'Submitted':
          label = getDate(row.submitted);
          break;
        case 'Resubmitted':
          label = getDate(row.resubmitted);
          break;
        case 'Under Review':
          label = getDate(row.under_review);
          break;
        case 'Interview':
          label = getDate(row.interview);
          break;
        case 'Interviewed But Rejected':
          label = getDate(row.interviewed_but_rejected);
          break;
        case 'Offer':
          label = getDate(row.offer);
          break;
        case 'Offer Declined':
          label = getDate(row.offer_declined);
          break;
        case 'Placed':
          label = getDate(row.placed);
          break;
        default:
          break;
      }
      return label;
    }

    const getDate = (value) => {
      if (!value) return '';
      const date = new Date(value);
      return `${date.toLocaleDateString(locale, {dateStyle:'medium'})} ${date.toLocaleTimeString(locale, {timeStyle:'short'})}`;
    }

    return [{
      Header: 'Company/Firm',
      accessor: 'company',
      Cell: ({value, row}) => {
        const { id, parent_id, name, company, parent_name, parent_company, status } = row.original;
        const url = `/employer/${parent_id || id}`;
        const cname = status === 'On Hold' ? 'on-hold-link' : '';
        return <>
          <FormControlLabel className="company-control" control={<Checkbox name="follow_up" checked={row.original.selected} value={id} onChange={(evt) => onSelect(evt, evt.target.value, row.original, row.index, data)} />} sx={{ margin:0 }} />
          <Tooltip title={parent_name || name}>
            <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{parent_company || company}</Link>
          </Tooltip>
        </>;
      }
    },
    {
      Header: 'Office',
      accessor: 'office_name',
      Cell: ({value, row}) => {
         const url = `/office/${row.original.employer_id}`;
         const cname = row.original.status === 'On Hold' ? 'on-hold-link' : '';
         return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{value}</Link>
      }
    },
    {
      Header: 'Coordinators',
      accessor: 'email_to',
      Cell: ({value, row}) => {
        const { first_name, last_name, email_to } = row.original;
        const { first_name2, last_name2, email_to2 } = row.original;
        const { first_name3, last_name3, email_to3 } = row.original;
        const { first_name4, last_name4, email_to4 } = row.original;

        const name1 = `${first_name || ''} ${last_name || ''}`.trim();
        const name2 = `${first_name2 || ''} ${last_name2 || ''}`.trim();
        const name3 = `${first_name3 || ''} ${last_name3 || ''}`.trim();
        const name4 = `${first_name4 || ''} ${last_name4 || ''}`.trim();

        let coords = email_to ? (name1 ? `${name1} <${email_to}>` : email_to) : '';
        if (email_to2) {
          coords += email_to2 ? (name2 ? `\n${name2} <${email_to2}>` : `\n${email_to2}`) : '';
        }
        if (email_to3) {
          coords += email_to3 ? (name3 ? `\n${name3} <${email_to3}>` : `\n${email_to3}`) : '';
        }
        if (email_to4) {
          coords += email_to4 ? (name4 ? `\n${name4} <${email_to4}>` : `\n${email_to4}`) : '';
        }
        return <div className="wrap-cell">{ coords }</div>;
      }
    },
    {
      Header: 'Date Submitted',
      accessor: 'submitted',
      Cell: ({value, row}) => {
        if (!value) return '';
        const posted = getDate(value);
        return posted.toLocaleString();
      }
    },
    {
      Header: 'Status',
      accessor: 'candidate_status',
      Cell: ({value, row}) => {
        const label = getStatusLabel(row.original);
        if (value === 'Interview' && row.original.interview) {
          const date = new Date(row.original.interview);
          const interview = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));

          row.original.interviewDateTime = getDate(interview);
          row.original.interviewTime = interview.toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit', hour12: false});
          row.original.interviewDate = row.original.interview.split('T')[0];
        }
        return <Container sx={{ display:'flex', alignItems:'center', gap:'0.5rem' }} disableGutters={true}>
          <FormControl variant="standard" sx={{ minWidth: '12rem' }}>
            <Select
              name="status"
              labelId="status-label"
              id="status_select"
              className="status-select"
              variant="outlined"
              onChange={(evt) => onStatusChange(evt, evt.target.value, row.original, row.index)}
              defaultValue={value}
              disabled={readonly}
            >
              <MenuItem value="Unknown">Unknown</MenuItem>
              <MenuItem value="Other Recruiter">Other Recruiter</MenuItem>

              <MenuItem value="Blind Submission">Blind Submission</MenuItem>
              <MenuItem value="Interested">Interested</MenuItem>
              <MenuItem value="Passed">Passed</MenuItem>
              <MenuItem value="No Openings">No Openings</MenuItem>
              <MenuItem value="Approval List">Approval List</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Declined">Declined</MenuItem>
              <MenuItem value="Submission List">Submission List</MenuItem>
              <MenuItem value="Other Submission">Other Submission</MenuItem>
              <MenuItem value="Submitted">Submitted</MenuItem>
              <MenuItem value="Resubmitted">Resubmitted</MenuItem>
              <MenuItem value="Under Review">Under Review</MenuItem>
              <MenuItem value="Interview">Interview</MenuItem>
              <MenuItem value="Interviewed But Rejected">Interviewed But Rejected</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              <MenuItem value="Offer">Offer</MenuItem>
              <MenuItem value="Offer Declined">Offer Declined</MenuItem>
              <MenuItem value="Placed">Placed</MenuItem>
            </Select>


          </FormControl>

          { value !== 'Interview' &&
            <>
            &nbsp;
            <span className="submitted-status-label">{ label }</span>
            </>
          }
          { value === 'Interview' && readonly &&
            <>
            &nbsp;
            <span className="submitted-status-label">{ row.original.interviewDateTime }</span>
            </>
          }
          { value === 'Interview' && !readonly &&
            <>
            <TextField name="interview_date" className="submitted-interview-date" defaultValue={row.original.interviewDate} label="Interview Date" type="date" variant="outlined" InputLabelProps={{shrink: true}} onChange={(evt) => row.original.interviewDate = evt.target.value} />
            <TextField name="interview_time" className="submitted-interview-time" defaultValue={row.original.interviewTime} label="Interview Time" type="time" variant="outlined" InputLabelProps={{shrink: true}} onChange={(evt) => row.original.interviewTime = evt.target.value} />
            <Tooltip title="Save Interview Date" arrow><IconButton variant="contained" size="small" onClick={(evt) => onSaveInterview(evt, row.original, row.index)}><Edit/></IconButton></Tooltip>
            </>
          }

        </Container>
      }
    },
    {
      Header: 'Follow Up',
      accessor: 'follow_up',
      Cell: ({value, row}) => {
        return value ? getDate(value) : '';
      }
    }];
    }, [data, onSaveInterview, onStatusChange, onSelect, locale, navigate, readonly]
  );

  return (<>
      <SubmittedTable
        columns={columns}
        data={data}
        skipPagereset={skipPageReset}
        updateEmployers={onSubmittedStatus}
      />
      <Typography sx={{ color: red[900], mt: '0 !important' }}>{ errorMessage }</Typography>
    </>);
}

export default SubmittedEmployers;

