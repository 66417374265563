import React, { Component } from 'react';
import { useParams } from "react-router";
import { Container } from "@mui/material";
import './JobTable.css';

const defaultState = {
  job: null,
  errorMessage: null,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Job extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  async componentDidMount() {
    const id = this.props.params.id;
    if (!id) {
      throw Error('Invalid jobs id');
    }
    const url = 'https://developer.firmprospects.com/v1/jobs';
    const req = new XMLHttpRequest();
    req.open('POST', url);
    req.setRequestHeader('X-AUTH-TOKEN', 'LPQQLLsXR-6k-Y6yXF_XeY9O2iQTQmfvcYPYNMUh4iA');
    req.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
    req.onreadystatechange = () => {
      if (req.readyState === XMLHttpRequest.DONE) {
        if (req.status === 200) {
          const jobs = JSON.parse(req.response);
          //const job = this.anonymize(jobs.data[0]);
          const [job] = jobs.data;
          this.setState({ job });
        } else {
          this.setState({ errorMessage: 'Search failed' });
        }
      }
    }

    const payload = {
      id: [this.props.params.id],
    };
    req.send(JSON.stringify(payload));
  }

  anonymize(job) {
    const { firmName, contactEmail } = job;
    if (firmName) {
      const regex = new RegExp(firmName, 'gi');
      job.jobDescription = job.jobDescription.replace(regex, 'FIRM');
    }
    if (contactEmail) {
      const regex = new RegExp(contactEmail, 'gi');
      job.jobDescription = job.jobDescription.replace(regex, 'EMAIL');
    }
    if (job.locations) {
      for (const location of job.locations) {
        const tokens = location.split('-');
        if (tokens.length >= 1) {
          const [city] = tokens;
          try {
            const cityRegex = new RegExp(city, 'gi');
            job.jobDescription = job.jobDescription.replace(cityRegex, 'CITY');
          } catch (err) {
            console.error(`Unable to parse city: ${city}`, err);
          }
        }
        if (tokens.length === 2) {
          const [,state] = tokens;
          try {
            const stateRegex = new RegExp(state, 'gi');
            job.jobDescription = job.jobDescription.replace(stateRegex, 'STATE');
          } catch (err) {
            console.error(`Unable to parse state: ${state}`, err);
          }
        }
      }
    }
    return job;
  }

  render() {
    const job = this.state.job;
    if (!job) {
      return <></>;
    }
    const bgClass = this.props.bg || 'none';
    const postedDate = job.postedDate ? new Date(job.postedDate).toLocaleString() : '';
    const closedDate = job.closedDate ? new Date(job.closedDate).toLocaleString() : '';

    return <section id="search" className={bgClass}>
      <Container sx={{ mb: '2rem' }} >
      <table className="job-table">
        <tbody>
          <tr><th>Title</th><td>{job.jobTitle}</td></tr>
          <tr><th>Firm Name</th><td>{job.firmName}</td></tr>
          <tr><th>Contact Email</th><td>{job.contactEmail}</td></tr>
          <tr><th>Status</th><td>{job.statusLabel}</td></tr>
          { closedDate && <tr><th>Closed</th><td>{closedDate}</td></tr> }
          <tr><th>Description</th><td>{job.jobDescription}</td></tr>
          <tr><th>Partner Counsel</th><td>{job.partnerCounsel}</td></tr>
          <tr><th>Degrees</th><td>{job.degrees?.join(', ')}</td></tr>
          <tr><th>Locations</th><td>{job.locations?.join(', ')}</td></tr>
          <tr><th>Additional Locations</th><td>{job.additionalJobLocations?.join(', ')}</td></tr>
          <tr><th>Specialty</th><td>{job.specialty?.join(', ')}</td></tr>
          <tr><th>Practice Areas</th><td>{job.practiceAreas?.join(', ')}</td></tr>
          <tr><th>Posted</th><td>{postedDate}</td></tr>
          <tr><th>Job Link</th><td><a href={job.onlineLink} target="_blank" rel="noreferrer">{job.onlineLink}</a></td></tr>
        </tbody>
      </table>
      </Container>
    </section>;
  }
}

export default withParams(Job);

