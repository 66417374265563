import { Component } from 'react';
import { Box, Button, CircularProgress, Container, Link, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { red } from '@mui/material/colors';

import Submission from '../components/Submission';
import SubmissionEmployersTable from '../components/SubmissionEmployersTable';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  user: null,
  loading: true,
  submission: null,
  notes: null,
  notesError: null,
  notesMessage: null,

  candidate: null,

  loadingEmployers: true,
  employerPageCount: 0,
  employerPageSize: 100,
  employerTotalCount: 0,
  employers: [],

  successMessage: null,
  errorMessage: null,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const H2 = styled.h2`
  margin: 1.5rem 0 0.25rem;
`;

const BackButton = (props) => {
  const navigate = useNavigate();
  return (
    <Link href={props.url} onClick={(evt) => {evt.preventDefault();navigate(props.url)}} underline="hover">{props.text}</Link>
  )
}

class SubmissionPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.getEmployers = this.getEmployers.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name) {
      this.setState({ [name]: value });
    }
  }

  async componentDidMount() {
    // Get the recruiter's role to use for permissions
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({ user });

    const submissionId = this.props.params.id;
    const url = `${env.baseUrl}/v1/submission/${submissionId}`;
    fetch(url, { credentials: 'include' })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data) {
          const [submission] = result.data;
          const notes = submission?.notes || '';
          this.setState({ submission, notes });

          this.getCandidate(submission.candidate_id);
          this.getEmployers(submission.id);
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message, error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  async getCandidate(candidateId) {
    const url = `${env.baseUrl}/v1/candidate/${candidateId}`;
    fetch(url, { credentials: 'include' })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data) {
          const [candidate] = result.data;
          this.setState({ candidate });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message, error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  async getEmployers({ pageSize, pageIndex }) {
    this.setState({ employersPageSize: pageSize, employersPageIndex: pageIndex });

    const submissionId = this.props.params.id;
    const url = `${env.baseUrl}/v1/submission/${submissionId}/employer`;
    fetch(url, { credentials: 'include' })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data) {
          this.setState({ employers: result.data });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message, error });
      })
      .finally(() => {
        this.setState({ loadingEmployers: false });
      });
  }

  setSuccessMessage(msg) {
    this.setState({ successMessage: msg, errorMessage: '' });
    setTimeout(() => this.setState({ successMessage: '' }), 5000);
  }

  saveNotes(event) {
    event.preventDefault();
    const submission = this.state.submission;
    const url = `${env.baseUrl}/v1/submission/${submission.id}/notes`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ notes: this.state.notes }),
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(({ data, notes }) => {
      if (data?.rowCount === 1) {
        const employer = {...this.state.employer};
        employer.notes = notes;
        this.setState({ notes, employer });
        this.setNotesMessage('Submission Notes saved');
      } else
        this.setState({ notesError: 'Save Notes failed' });
    })
    .catch(error => {
      console.error(error);
      const notesError = error.message || 'Save Notes failed';
      this.setState({ notesError });
    });
  }

  setNotesMessage(msg) {
    this.setState({ notesMessage: msg, notesError: '' }, () => {
      setTimeout(() => this.setState({ notesMessage: '' }), 5000);
    });
  }
  getSubmissionType(type) {
    switch (type) {
      case 'blindSubmission': return 'Blind Submission';
      case 'submitCandidate': return 'Candidate Submission';
      case 'followUp': return 'Follow Up';
      default: return '';
    }
  }

  render() {
    if (this.state.loading) {
      return <Container sx={{ m: '2rem' }}>
        <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
        Loading Submission...
      </Container>;
    }

    const s = this.state.submission;
    const c = this.state.candidate;
    if (!s || s === []) {
      return <p>Unable to find submission</p>;
    }
    const backUrl = `/candidate/${s.candidate_id}`;
    const candidateName = `${c?.first_name || ''} ${c?.last_name || ''}`.trim();
    const submitType = this.getSubmissionType(s.submit_type);

    const recruiterId = this.state.candidate?.recruiter_id;
    const isRecruiter = this.state.user.id === recruiterId;
    const isAdmin = this.state.user.role === 'Administrator' || this.state.user.role === 'Manager';
    const readonly = !isRecruiter && !isAdmin;

    return <>
      <Container component="div" maxWidth={false} sx={{ mb:'1rem', textAlign:'left' }}>
        <h1>{submitType} for {candidateName}</h1>
        <Submission submission={this.state.submission} />

        <H2>Notes</H2>
        <Box
          component="form"
          className="notes-form"
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.saveNotes}
          sx={{ width: '100%' }}
        >
          <TextField name="notes" multiline minRows="4" maxRows="20" value={this.state.notes} className="employer-notes" onChange={this.handleInputChange} sx={{ width: '100%', mb: '0.5rem' }} />

          <div className="notes-actions">
            <Button type="submit" variant="contained" className="save-notes-button" disabled={readonly}>Update Notes</Button>
            <Typography sx={{ mt: '0 !important' }}>{ this.state.notesMessage }</Typography>
            <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.notesError }</Typography>
          </div>
        </Box>

        <Container maxWidth={false} disableGutters={true} sx={{ mb:'1rem' }}>
          <H2>Selected Employers/Offices for this {submitType}</H2>
          <SubmissionEmployersTable
            employers={this.state.employers}
            loading={this.state.loadingEmployers}
            fetchData={this.getEmployers}
            pageCount={this.state.employerPageCount}
            pageSize={this.state.employerPageSize}
            totalCount={this.state.employerTotalCount}
            target="Employers/Offices"
          />
        </Container>

        <BackButton url={backUrl} text="Back to Candidate" />
      </Container>
    </>;
  }
}

export default withParams(SubmissionPage);

