import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "@mui/material";
import { useTable } from 'react-table';

import './JobsTable.css';


export default function JobsTable({ jobs }) {

  const navigate = useNavigate();

  // 1. Create Columns
  const columns = React.useMemo(
     () => [{
       Header: 'Job Title',
       accessor: 'jobTitle',
       Cell: ({value, row}) => {
         const url = `/job/${row.original.id}`;
         return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} target="_blank" rel="noreferrer">{value}</Link>
       }
     },
     {
       Header: 'Firm Name',
       accessor: 'firmName'
     },
     {
       Header: 'Title',
       accessor: 'title'
     },
     {
       Header: 'Locations',
       accessor: 'locations'
     },
     {
       Header: 'Posted',
       accessor: 'postedDate',
       Cell: ({value, row}) => {
         const posted = new Date(Date.parse(value));
         return posted.toLocaleDateString();
       }
     },
     {
       Header: 'Status',
       accessor: 'statusLabel'
     }], [navigate]
  );

  // 2. Call Use Table
  const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data: jobs });

  // 3. Return the HTML Table
  return (<>
        <table className="jobs-table" {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                        );
                    }
                )}
            </tbody>
        </table>
    </>);
}

