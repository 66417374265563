import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "@mui/material";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { ThumbUp, ThumbDown } from '@mui/icons-material';

import PaginatedTable from './PaginatedTable';
import './EmployersTable.css';


const statusStyles = {
  display : 'flex',
  alignItems: 'center',
};

export default function OfficesTable({ offices, employer, fetchData, loading, totalCount, pageCount, pageSize }) {

  const navigate = useNavigate();

  // 1. Create Columns
  const columns = React.useMemo(
     () => [{
       Header: 'Office Name',
       accessor: 'office_name',
       Cell: ({value, row}) => {
         const url = `/office/${row.original.id}`;
         const cname = row.original.status === 'On Hold' ? 'on-hold-link' : '';
         return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{value}</Link>;
       }
     },
     {
       Header: 'Email',
       accessor: 'email_to'
     },
     {
       Header: 'Address',
       accessor: 'address',
       Cell: ({value, row}) => {
         const { address, city, state, country } = row.original;
         let addr = address ? `${address}, ` : '';
         addr += city ? `${city}, ` : '';
         addr += state ? `${state} ` : '';
         if (country && !country.startsWith('US')) addr += country;
         return <span>{addr}</span>;
       }
     },
     {
       Header: 'Coordinator',
       accessor: 'first_name',
       Cell: ({value, row}) => {
         return <span>{row.original.first_name} {row.original.last_name}</span>;
       }
     },
     {
       Header: 'Phone',
       accessor: 'phone'
     },
     {
       Header: 'Practice Areas',
       accessor: 'practice_areas'
     },
     {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value, row}) => {
         switch (value) {
           case 'Unknown':
             return <span style={statusStyles}><Unchecked sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
           case 'Active':
             return <span style={statusStyles}><ThumbUp sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
           case 'On Hold':
             return <span style={statusStyles}><ThumbDown sx={{opacity:'0.5', color: 'white', mr: '0.25rem' }}/> {value}</span>;
           default:
             return <span style={statusStyles}><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
         }
      }
    }], [navigate]
  );

  const getRowClasses = (row) => {
    let classes = '';
    if (row.original.online_subs) {
      classes += ' online-subs';
    }
    if (row.original.blind_feelers) {
      classes += ' blind-feelers';
    }
    if (row.original.status === 'Active') {
      classes += ' active';
    }
    if (row.original.status === 'On Hold') {
      classes += ' on-hold';
    }
    return (classes) ? ({ className: classes }) : {};
  }

  // 3. Return the HTML Table
  return <PaginatedTable
    data={offices}
    columns={columns}
    fetchData={fetchData}
    loading={loading}
    totalCount={totalCount}
    pageCount={pageCount}
    pageSize={pageSize}
    target="Offices"
    getRowClasses={getRowClasses}
  />;

}

