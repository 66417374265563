import { useTable, useSortBy } from 'react-table';
import './BaseTable.css';


function BaseTable({ columns, data, skipPageReset }) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    autoResetPage: !skipPageReset,
  }, useSortBy);

  const getRowClasses = (row) => {
    let classes = '';
    if (row.original.online_subs) {
      classes += 'online-subs';
    }
    if (row.original.blind_feelers) {
      if (!classes) classes += ' ';
      classes += 'blind-feelers';
    }
    if (row.original.status === 'Active') {
      classes += ' active';
    } else if (row.original.status === 'On Hold') {
      classes += ' on-hold';
    } else if (row.original.status === 'Blacklisted') {
      classes += ' blacklisted';
    }
    return (classes) ? ({ className: classes }) : {};
  }

  return (<>
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? ' 🔽'
                                    : ' 🔼'
                                  : ''}
                              </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} {...getRowClasses(row)}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                        );
                    }
                )}
            </tbody>
        </table>

      { data.length > 0 &&
        <div className="total-count-row">
              Total Count: {" "}
              <strong>{data.length}</strong>
        </div>
      }
    </>);
}

export default BaseTable;

