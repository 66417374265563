import React, { Component } from 'react';
import { Box, Button, TextField, Typography } from "@mui/material";
import { red } from '@mui/material/colors';
import styled from "styled-components";

import './Candidate.css';
import { checkStatus } from '../checkStatus';
import { env } from '../env';


const H2 = styled.h2`
  margin: 1rem 0 0;
  text-align: left;
`;
const H3 = styled.h3`
  font-size: 18px;
  margin: 0;
  text-align: left;
`;

const defaultState = {
  notes: '',
  successMessage: null,
  errorMessage: null,
};

export default class Candidate extends Component {

  locale = Intl.NumberFormat().resolvedOptions().locale;

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.onNotes = this.onNotes.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
  }

  async componentDidMount() {
    this.setState({ recruiterId: this.props.candidate.recruiter_id || 'open', notes: this.props.candidate.notes });
  }

  onNotes(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  saveNotes(event) {
    event.preventDefault();
    const candidate = this.props.candidate;
    const url = `${env.baseUrl}/v1/candidate/${candidate.id}/notes`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ notes: this.state.notes }),
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(({ data, notes }) => {
      if (data?.rowCount === 1) {
        this.setState({ notes });
        this.setSuccessMessage('Candidate Notes saved');
      } else
        this.setState({ errorMessage: 'Save Notes failed' });
    })
    .catch(error => {
      console.error(error);
      this.setState({ errorMessage: 'Save Notes failed' });
    });
  }

  setSuccessMessage(msg) {
    this.setState({ successMessage: msg, errorMessage: '' });
    setTimeout(() => this.setState({ successMessage: '' }), 5000);
  }

  getUTCDate(value) {
    if (!value) return '';
    const date = new Date(value);
    const utc = this.convertDateToUTC(date);
    return `${utc.toLocaleDateString(this.locale, {dateStyle:'medium'})}`;
  }

  convertDateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  render() {
    const c = this.props.candidate;
    const readonly = this.props.readonly;

    const completed = c.signup_completed_on ? c.signup_completed_on.split('T')[0] : '';
    const signupCompletedOn = this.getUTCDate(completed);

    let address2 = c.city || '';
    if (address2) address2 += ',';
    if (c.state) address2 += ` ${c.state}`;
    if (c.zipcode) address2 += ` ${c.zipcode}`;
    if (c.country && !c.country.startsWith('US')) address2 += ` ${c.country}`;

    return (<div className="candidate">

      <div className="candidate-profile">
        <p className="indent">{c.email}</p>
        <p className="indent">{c.address}</p>
        <p className="indent">{address2}</p>
        <p className="indent">{c.phone}</p>
      </div>

      <table className="candidate-table">
        <tbody>
          <tr><th>Law School</th><td>{c.law_school || '--'} {c.graduated_on}</td></tr>

          <tr><th>Current Firm</th><td>{c.current_firm}</td></tr>
          <tr><th>Signup Source</th><td>{c.signup_source || '--'} {signupCompletedOn}</td></tr>

          { (c.practice_areas || c.secondary_practice_areas || c.preferred_practice_areas) &&
          <tr>
            <th>Practice Areas<br/>Secondary Practice Areas<br/>Preferred Practice Areas</th>
            <td>{c.practice_areas}&nbsp;<br/>{c.secondary_practice_areas}&nbsp;<br/>{c.preferred_practice_areas}&nbsp;</td>
          </tr>
          }

         { c.interested_locations && c.preferred_locations &&
          <tr>
            <th>Preferred Locations<br/>Interested Locations</th>
            <td>{c.interested_locations}&nbsp;<br/>{c.preferred_locations}&nbsp;</td>
          </tr>
          }

        </tbody>
      </table>

      { c.description &&
        <>
          <H3>Description</H3>
          <pre className="indent">{c.description}</pre>
        </>
      }

      <H2>Candidate Notes</H2>
      <Box
        component="form"
        className="email-form"
        noValidate
        align="left"
        autoComplete="off"
        onSubmit={this.saveNotes}
      >
        <TextField name="notes" multiline minRows="4" maxRows="20" defaultValue={c.notes || ''} className="candidate-notes" onChange={this.onNotes} disabled={readonly} />

        <div className="notes-actions">
          <Button type="submit" variant="contained" className="save-notes-button" disabled={readonly}>Update Notes</Button>
          <Typography sx={{ mt: '0 !important' }}>{ this.state.successMessage }</Typography>
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
        </div>
      </Box>

    </div>);
  }
}

