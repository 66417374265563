import { Component } from 'react';
import Search from '../components/Search';

export default class SearchPage extends Component {
  render() {
    return <>
      <Search />
    </>;
  }
}

