import React, { Component } from 'react';
import { Container } from "@mui/material";

import './Recruiter.css';


export default class Recruiter extends Component {

  render() {
    const e = this.props.recruiter;
    let address2 = e.city || '';
    if (address2) address2 += ',';
    if (e.state) address2 += ` ${e.state}`;
    if (e.zipcode) address2 += ` ${e.zipcode}`;
    if (e.country && !e.country.startsWith('US')) address2 += ` ${e.country}`;

    return (<Container sx={{ textAlign: 'left' }} maxWidth={false}>

      <div className="recruiter-profile">
        <div>{e.email}</div>
        <div>{e.address}</div>
        <div>{e.address2}</div>
        <div>{e.phone}</div>
        <br />
        <ul>
          <li><span className="item-name">Law School</span><span className="item-value">{e.law_school || '--'}</span></li>
          <li><span className="item-name">Practice Areas</span><span className="item-value">{e.practice_areas || '--'}</span></li>
        </ul>
      </div>

      { e.signature && 
        <img className="signature" src={e.signature} alt="signature" height="85" />
      }
    </Container>);
  }
}

