import { Component } from 'react';
import { useParams } from 'react-router';
import { FormControl, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';

import './Attachments.css';
import { env } from '../env';


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Attachments extends Component {

  entity = 'candidate';

  constructor(props) {
    super(props);
    this.entity = this.props.entity || 'candidate';
    this.onDeleteAttachment = this.onDeleteAttachment.bind(this);
  }

  onDeleteAttachment(event, file, index) {
    event.preventDefault();
    this.props.onDeleteAttachment(event, file, index);
  }

  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  render() {
    if (this.props.attachments?.length === 0) {
      return <p>No attachments</p>
    }
    return <>
      <FormControl className="attachments-form-control" variant="standard">

      <List className="attachments-list">
        { this.props.attachments.map((att,index) =>
          <ListItem key={att.id}>
            <ListItemIcon sx={{ minWidth: '1.75rem' }}><DescriptionIcon /></ListItemIcon>
            <ListItemText>
              <a href={env.baseUrl+'/v1/' +this.entity+ '/'+this.props.params.id+'/attachment/'+att.id} className="attachment-link" download target="_blank" rel="noreferrer">
                <span className="attachment-category">{att.category}</span>
                &nbsp;
                <span className="attachment-name">{att.name}</span>
                &nbsp;
                <span className="attachment-size">({this.formatBytes(att.size)})</span>
              </a>
            </ListItemText>
            { !this.props.readonly &&
              <ListItemIcon><DeleteIcon onClick={(event) => this.onDeleteAttachment(event, att, index)} sx={{ cursor: 'pointer' }}/></ListItemIcon>
            }
          </ListItem>
        )}
        </List>
      </FormControl>
    </>;
  }
}

export default withParams(Attachments);

