import { useEffect, useRef } from "react";
import { Alert, Pagination } from "@mui/material";
import { useTable, usePagination, useSortBy, useExpanded } from 'react-table';
import { PropTypes } from 'prop-types';

import './PaginatedTable.css';


export default function PaginatedTable({ data, columns, loading, fetchData, totalCount, pageCount: controlledPageCount, pageSize: controlledPageSize, target, getRowClasses, alertMessage, alertType }) {

    // 2. Call Use Table
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      pageOptions,
      gotoPage,
      state: { pageIndex },
    } = useTable({
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      pageSize: controlledPageSize,
      autoResetPage: false,
    }, useSortBy, useExpanded, usePagination);

    const pageIndexRef = useRef();
    pageIndexRef.current = pageIndex || 0;

    useEffect(() => {
      fetchData && fetchData({ pageIndex, pageSize: controlledPageSize });
      pageIndexRef.current = 0; 
    }, [fetchData, pageIndex, controlledPageSize, pageIndexRef]);

    // 3. Return the HTML Table
    return (<>
      { alertMessage &&
        <Alert severity={alertType || 'error'} onClose={() => alertMessage=''}>{alertMessage}</Alert>
      }

      <table className={target.replace(' ','-')} {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps(column.getSortByToggleProps({ title: column.tipText }))}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? ' 🔽'
                                    : ' 🔼'
                                  : ''}
                              </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map(
                    (row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} {...getRowClasses(row)}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                        );
                    }
                )}
            </tbody>
        </table>

        <div className="pagination">
          { controlledPageCount === 1 &&
            <div>&nbsp;</div>
          }

          { controlledPageCount > 1 &&
            <Pagination count={controlledPageCount} onChange={(event, page) => gotoPage(page - 1)} />
          }

          { controlledPageCount > 1 &&
            <span>
              Page{" "}
              <strong>{pageIndexRef.current + 1} of {pageOptions.length}</strong>
              {" "}
              { totalCount &&
                <>
                  Total Count: {" "}
                  <strong>{totalCount}</strong>
                </>
              }
            </span>
          }
          { controlledPageCount === 1 && totalCount &&
            <span>
              Total Count: {" "}
              <strong>{totalCount}</strong>
            </span>
          }

          { controlledPageCount > 1 &&
            <select
              value={controlledPageSize}
              onChange={(e) => {
                const pageSize = Number(e.target.value);
                fetchData({ pageSize, pageIndex: pageIndexRef.current });
              }}
            >
              {[5, 10, 20, 30, 40, 50, 100, 200].map(size => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
          }
      </div>

      { !loading && data && data.length === 0 &&
        <p className="empty-message">No {target || 'rows'}</p>
      }
    </>);
}

PaginatedTable.propTypes = {
  fetchData: PropTypes.func.isRequired,
};

