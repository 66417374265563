import { Component } from 'react';
import { Alert, Box, Button, Container, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, InputLabel, FormControl, Menu, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import { useParams } from 'react-router';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { ArrowDropDown, ThumbUp, Warning } from '@mui/icons-material';
import { amber, green, red } from '@mui/material/colors';
import styled from 'styled-components';

import Employer from '../components/Employer';
import EditEmployer from '../components/EditEmployer';
import EmployerCandidatesTable from '../components/EmployerCandidatesTable';
import EmplAttachmentsTable from '../components/EmplAttachmentsTable';
import OfficesTable from '../components/OfficesTable';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const H1 = styled.h1`
  margin: 0;
`;
const H2 = styled.h2`
  margin: 1.5rem 0 0.25rem;
`;
const H3 = styled.h3`
  margin: 1rem 0 0;
  text-transform: none;
`;

const AddOfficeButton = (props) => {
  const navigate = useNavigate();
  const url = `/employer/${props.id}/add-office`;
  return (
    <Button onClick={()=> navigate(url)} variant="outlined" size="small" sx={{ mt: '0.25rem' }}>Add Office</Button>
  )
}

const AddAttachmentButton = (props) => {
  const navigate = useNavigate();
  const url = `/employer/${props.id}/add-empl-attachment`;
  return (
    <Button onClick={()=> navigate(url)} variant="outlined" size="small" sx={{ mt: '0.5rem' }}>Add Attachment</Button>
  )
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const defaultState = {
  loading: true,
  searchText: null,
  employer: null,

  notes: '',
  notesError: null,
  notesMessage: null,
  offers: '',
  offersError: null,
  offersMessage: null,
  status: 'Unknown',
  statusError: null,

  loadingOffices: true,
  searchOfficeNameText: null,
  officesTotalCount: 0,
  officesPageIndex: 0,
  officesPageSize: 50,
  officesPageCount: 0,
  offices: [],
  officesError: null,

  loadingCandidates: true,
  candidatesTotalCount: 0,
  candidatesPageIndex: 0,
  candidatesPageSize: 10,
  candidatesPageCount: 0,
  candidates: [],
  candidatesError: null,

  attachments: [],
  attachmentsError: null,

  role: null,
  editMode: false,
  anchorEL: null,
  open: false,
  openDeleteDialog: false,
  openDeleteAttachmentDialog: false,
  dialogErrorMessage: null,

  alertMessage: null,
  alertType: 'error',
};

class EmployerPage extends Component {

  locale = Intl.NumberFormat().resolvedOptions().locale;

  constructor() {
    super();
    this.state = defaultState;
    this.openActionsMenu = this.openActionsMenu.bind(this);
    this.closeActionsMenu = this.closeActionsMenu.bind(this);
    this.onUpdateEmployer = this.onUpdateEmployer.bind(this);
    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.onDeleteConfirmationClose = this.onDeleteConfirmationClose.bind(this);
    this.onDeleteEmployer = this.onDeleteEmployer.bind(this);
    this.onSaveEmployer = this.onSaveEmployer.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
    this.saveOffers = this.saveOffers.bind(this);
    this.saveStatus = this.saveStatus.bind(this);
    this.loadCandidates = this.loadCandidates.bind(this);
    this.loadOffices = this.loadOffices.bind(this);
    this.onEditAttachment = this.onEditAttachment.bind(this);
    this.onDeleteAttachment = this.onDeleteAttachment.bind(this);
    this.onDeleteAttachmentConfirmation = this.onDeleteAttachmentConfirmation.bind(this);
    this.onDeleteAttachmentConfirmationClose = this.onDeleteAttachmentConfirmationClose.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  async componentDidMount() {
    const employerId = this.props.params.id;
    this.setState({ employerId });
    const employerUrl = `${env.baseUrl}/v1/employer/${employerId}`;
    fetch(employerUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        if (data?.length) {
          const [employer] = data;
          const status = employer.status || 'Unknown';
          if (status === 'On Hold') {
            this.setState({ alertType: 'warning', alertMessage: `Status is "On Hold"` });
          } else if (status === 'Exclusive') {
            this.setState({ alertType: 'warning', alertMessage: `Exclusive Listing for this Employer` });
          } else if (status === 'Exclusive & DNC') {
            this.setState({ alertType: 'warning', alertMessage: `Exclusive Listing for this Employer; Do Not Contact/Poach from this Employer` });
          } else if (status === 'Do Not Contact') {
            this.setState({ alertType: 'warning', alertMessage: `Do Not Contact/Poach from this Employer` });
          }
          const notes = employer.notes || '';
          const offers = employer.offers || '';
          return this.setState({ employer, status, notes, offers });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ alertMessage: error.message || 'Unable to get employer' });
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    const attachmentsUrl = `${employerUrl}/attachment`;
    fetch(attachmentsUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        const attachments = items.data;
        const now = Date.now();
        const twoWeeks = 2*7*24*60*60*1000;
        for (const att of attachments) {
          if (att.expiration) {
            const expiration = new Date(att.expiration);
            const expirationDate = this.getDate(att.expiration);
            const expirationTime = expiration.getTime();
            if (expirationTime < now) {
              this.setState({ alertMessage: `${att.category || ''} ${att.name} expired on ${expirationDate}` });
            } else if (expirationTime < (now + twoWeeks)) {
              this.setState({ alertType: 'warning', alertMessage: `${att.category || ''} ${att.name} will expire on ${expirationDate} ` });
            }
          }
        }
        return this.setState({ attachments: items.data, attachmentsError: '' });
      })
      .catch(error => {
        console.error(error);
        this.setState({ attachmentsError: error.message });
      });

    // Get the recruiter's role to use for permissions
    const user = JSON.parse(localStorage.getItem('user'));
    const recruiterUrl = `${env.baseUrl}/v1/recruiter/${user.id}`;
    fetch(recruiterUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        const recruiter = items.data[0];
        this.setState({ role: recruiter.role });
      })
      .catch(error => {
        console.error(error);
        this.setState({ alertMessage: error.message || 'Unable to get recruiter', alertType: 'error' });
      });
  }

  getOfficesUrl(searchText, pageSize, pageIndex) {
    const employerId = this.props.params.id;
    let url = `${env.baseUrl}/v1/employer/${employerId}/office`;
    let queryParams = '';
    if (this.state.searchOfficeNameText) {
      if (queryParams) queryParams += '&';
      queryParams += `search=${this.state.searchOfficeNameText}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    return url;
  }

  loadOffices({ pageSize, pageIndex }) {
    this.setState({ officesPageSize: pageSize, officesPageIndex: pageIndex });
    this.setState({ loadingOffices: true });
    this.setState({ officesPageCount: 0 });
    const url = this.getOfficesUrl(this.state.searchText, pageSize, pageIndex);
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        const officesTotalCount = items.data.length ? items.data[0].count : 0;
        this.setState({ officesTotalCount, officesPageCount: Math.ceil(officesTotalCount / pageSize) });
        this.setState({ officesError: '' });
        this.setState({ offices: items.data});
      })
      .catch(err => {
        console.error(err);
        this.setState({ officesError: 'Unable to get offices' });
      })
      .finally(() => {
        this.setState({ loadingOffices: false });
      });
  }

  getCandidatesUrl(searchText, pageSize, pageIndex) {
    const employerId = this.props.params.id;
    let url = `${env.baseUrl}/v1/employer/${employerId}/candidate`;
    let queryParams = '';
    if (this.state.searchText) {
      if (queryParams) queryParams += '&';
      queryParams += `search=${this.state.searchText}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    return url;
  }

  loadCandidates({ pageSize, pageIndex }) {
    this.setState({ candidatePageSize: pageSize, candidatePageIndex: pageIndex });
    this.setState({ loadingCandidates: true });
    const url = this.getCandidatesUrl(this.state.searchText, pageSize, pageIndex);
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        const candidatesTotalCount = items.data.length ? items.data[0].count : 0;
        this.setState({ candidatesTotalCount, candidatePageCount: Math.ceil(candidatesTotalCount / pageSize) });
        this.setState({ candidatesError: '' });
        this.setState({ candidates: items.data});
      })
      .catch(err => {
        console.error(err);
        this.setState({ candidatesError: 'Unable to get candidates' });
      })
      .finally(() => {
        this.setState({ loadingCandidates: false });
      });
  }

  async handleDelete() {
    try {
      const employerId = this.props.params.id;
      const url = `${env.baseUrl}/v1/employer/${employerId}`;
      const response = await fetch(url, {
        method: 'DELETE',
        body: '{}',
        headers:{ 'Content-Type':'application/json' },
        credentials: 'include',
      });

      if (!response.ok) {
        const message = 'Error with Status Code: ' + response.status;
        throw new Error(message);
      }

      await response.json();
    } catch (err) {
      console.error('Error: ' + err);
    }
  }

  saveStatus(event) {
    const status = event.target.value;
    const url = `${env.baseUrl}/v1/employer/${this.state.employer.id}/status`;

    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ status }),
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(response => {
      if (response.data?.rowCount === 1) {
        const employer = {...this.state.employer};
        employer.status = status;
        this.setState({ status, employer, statusError: null });
        if (status === 'On Hold') {
          this.setState({ alertType: 'warning', alertMessage: `Status is "On Hold"` });
        } else if (status === 'Exclusive') {
          this.setState({ alertType: 'warning', alertMessage: `Added to "Exclusive Listings"` });
        } else if (status === 'Exclusive & DNC') {
          this.setState({ alertType: 'warning', alertMessage: `Added to "Exclusive Listings" and "Do Not Contact/Poach list"` });
        } else if (status === 'Do Not Contact') {
          this.setState({ alertType: 'warning', alertMessage: `Added to "Do Not Contact/Poach list"` });
        } else {
          this.setState({ alertType: 'warning', alertMessage: null });
        }
      } else
        this.setState({ statusError: 'Save status failed' });
    })
    .catch(error => {
      console.error(error);
      const statusError = error.message || 'Save Status failed';
      this.setState({ statusError });
    });
  }

  saveNotes(event) {
    event.preventDefault();
    const employerId = this.props.params.id;

    const url = `${env.baseUrl}/v1/employer/${employerId}/notes`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ notes: this.state.notes }),
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(({ data, notes }) => {
      if (data?.rowCount === 1) {
        const employer = {...this.state.employer};
        employer.notes = notes;
        this.setState({ notes, employer }, () => { this.setNotesMessage('Employer Notes saved') });
      } else
        this.setState({ notesError: 'Save Notes failed' });
    })
    .catch(error => {
      console.error(error);
      const notesError = error.message || 'Save Notes failed';
      this.setState({ notesError });
    });
  }

  setNotesMessage(msg) {
    this.setState({ notesMessage: msg, notesError: '' }, () => {
      setTimeout(() => this.setState({ notesMessage: '' }), 5000);
    });
  }

  saveOffers(event) {
    event.preventDefault();
    const employerId = this.props.params.id;
    const url = `${env.baseUrl}/v1/employer/${employerId}/offers`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ offers: this.state.offers }),
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(({ data, offers }) => {
      if (data?.rowCount === 1) {
        const employer = {...this.state.employer};
        employer.offers = offers;
        this.setState({ offers, employer });
        this.setOffersMessage('Employer Offers saved');
      } else
        this.setState({ offersError: 'Save Offers failed' });
    })
    .catch(error => {
      console.error(error);
      const offersError = error.message || 'Save Offers failed';
      this.setState({ offersError });
    });
  }

  setOffersMessage(msg) {
    this.setState({ offersMessage: msg, offersError: '' }, () => {
      setTimeout(() => this.setState({ offersMessage: '' }), 5000);
    });
  }

  openActionsMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ open: true, anchorEL: event.currentTarget });
  }

  closeActionsMenu() {
    this.setState({ open: false, anchorEL: null });
  }

  onUpdateEmployer() {
    this.setState({ editMode: true });
    this.closeActionsMenu();
  }

  onDeleteConfirmation() {
    this.setState({ openDeleteDialog: true });
  };

  onDeleteConfirmationClose() {
    this.setState({ openDeleteDialog: false });
  }

  onDeleteEmployer() {
    this.setState({ dialogErrorMessage: '' });
    const url = `${env.baseUrl}/v1/employer/${this.props.params.id}`;
    fetch(url, {
      method: 'DELETE',
      body: '{}',
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
      .then(result => checkStatus(result))
      .then(result => {
        this.setState({ forward: '/employers' });
        this.closeActionsMenu();
      })
      .catch(error => {
        console.error(error);
        this.setState({ dialogErrorMessage: error.message || 'Unable to delete employer', error });
      });
  }

  onSaveEmployer(savedEmployer) {
    this.setState({ editMode: false, employer: savedEmployer, notes: savedEmployer.notes, offers: savedEmployer.offers });
  }

  onCancelEdit() {
    this.setState({ editMode: false });
  }

  onEditAttachment(event, attachment, index) {
    this.setState({ forward: `/empl-attachment/${attachment.id}` });
  }

  index = null;
  onDeleteAttachmentConfirmation(event, file, index) {
    if (event) event.preventDefault();
    this.index = index;
    this.setState({ openDeleteAttachmentDialog: true });
  };

  onDeleteAttachmentConfirmationClose() {
    this.setState({ openDeleteAttachmentDialog: false });
  }

  onDeleteAttachment(event) {
    const index = this.index;
    const attachment = this.state.attachments?.length ? this.state.attachments[index] : null;
    if (!attachment) {
      console.error(`Unable to delete attachment @ ${index}`);
      return;
    }
    const url = `${env.baseUrl}/v1/employer/${this.props.params.id}/attachment/${attachment.id}`;

    fetch(url, {
      method: 'DELETE',
      body: '{}',
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(response => {
      if (response.rowCount === 1) {
        const attachments = [...this.state.attachments];
        attachments.splice(index, 1);
        this.setState({ attachments, attachmentsError: '' });
        this.onDeleteAttachmentConfirmationClose();
      } else
        this.setState({ attachmentsError: 'Unable to delete attachment' });
    })
    .catch(error => {
      console.error(error);
      this.setState({ attachmentsError: error.message || 'Delete attachment failed' });
    });
  }

  getDate(value) {
    if (!value) return '';
    const date = new Date(value);
    return date.toLocaleDateString(this.locale, {dateStyle:'medium'});
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }
    if (this.state.loading) {
      return <Container sx={{ m: '2rem' }}>
        <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
        Loading Employer...
      </Container>;
    }

    const e = this.state.employer;
    if (!e || e === []) {
      return <p>Unable to find employer</p>;
    }

    return <>
      { this.state.alertMessage &&
        <Alert severity={this.state.alertType || 'error'} onClose={() => this.setState({ alertMessage:'' })}>{this.state.alertMessage}</Alert>
      }

      <Container component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '1rem 0 0' }} maxWidth={false}>
        { !this.state.editMode &&
          <H1>{e.office_name || e.company}</H1>
        }
        { this.state.editMode &&
          <H1>Edit {e.office_name || e.company}</H1>
        }

        <div className="candidate-actions" style={{ display:'flex', alignItems:'center' }}>
           <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.statusError }</Typography>

          { this.state.employer &&
            <FormControl variant="standard" sx={{ minWidth: '12rem', ml: '0.5rem' }}>
              <InputLabel id="select-employer-status-label" sx={{ left: '1rem', top: '-0.4rem', backgroundColor: 'white', zIndex: 9, p: '0 0.25rem' }}>Status</InputLabel>
              <Select
                name="status"
                labelId="select-employer-status-label"
                variant="outlined" onChange={this.saveStatus}
                value={this.state.status}
                sx={{ mr: '0.5rem' }}
              >
                <MenuItem value="Unknown"><Unchecked sx={{opacity:'0.5', mr: '0.25rem'}}/> Unknown</MenuItem>
                <MenuItem value="Active"><ThumbUp sx={{color: green[600], mr: '0.25rem' }}/> Active</MenuItem>
                <MenuItem value="Exclusive"><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> Exclusive</MenuItem>
                <MenuItem value="Exclusive & DNC"><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> Exclusive &amp; DNC</MenuItem>
                <MenuItem value="Do Not Contact"><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> Do Not Contact</MenuItem>
                <MenuItem value="On Hold"><Warning sx={{opacity:'0.5', color: red[900], mr: '0.25rem' }}/> On Hold</MenuItem>
                <MenuItem value="Blacklisted"><Warning sx={{opacity:'0.5', color: red[900], mr: '0.25rem' }}/> Blacklisted</MenuItem>
              </Select>
            </FormControl>
          }

          <Button
            id="employer-actions-btn"
            variant="contained"
            aria-controls={this.state.open ? 'employer-actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={this.state.open ? 'true' : undefined}
            onClick={this.openActionsMenu}
          >
            Actions
            <ArrowDropDown sx={{ml: '0.25rem'}}/>
          </Button>
          <Menu
            id="employer-actions-menu"
            anchorEl={this.state.anchorEL}
            open={this.state.open}
            onClose={this.closeActionsMenu}
            MenuListProps={{
              'aria-labelledby': 'employer-actions-btn',
            }}
          >
            <MenuItem onClick={this.onUpdateEmployer}>Edit Employer</MenuItem>
            <MenuItem onClick={this.onDeleteConfirmation} disabled={!this.state.role || this.state.role === 'Recruiter'}>Delete Employer</MenuItem>
          </Menu>

        </div>
      </Container>

      <Container sx={{ padding: '0 2rem 2rem', textAlign: 'left' }} maxWidth={false}>
        { !this.state.editMode && 
          <>
            { e.name &&
              <h3>Preferred Name: {e.name}</h3>
            }

            <Employer employer={this.state.employer} />

            <H3>Notes</H3>
            <Box
                component="form"
                className="notes-form"
                noValidate
                align="left"
                autoComplete="off"
                onSubmit={this.saveNotes}
                sx={{ width: '100%' }}
            >
              <TextField name="notes" multiline minRows="4" maxRows="20" value={this.state.notes} className="employer-notes" onChange={this.handleInputChange} sx={{ width: '100%', mb: '0.5rem' }} />

              <div className="notes-actions">
                <Button type="submit" variant="contained" className="save-notes-button">Update Notes</Button>
                <span sx={{ mt: '0 !important' }}>{ this.state.notesMessage }</span>
                <span sx={{ color: red[900], mt: '0 !important' }}>{ this.state.notesError }</span>
              </div>
            </Box>

            <H3>Offers</H3>
            <Box
                component="form"
                className="offers-form"
                noValidate
                align="left"
                autoComplete="off"
                onSubmit={this.saveOffers}
                sx={{ width: '100%' }}
            >
              <TextField name="offers" multiline minRows="4" maxRows="20" value={this.state.offers} className="employer-offers" onChange={this.handleInputChange} sx={{ width: '100%', mb: '0.5rem' }} />

              <div className="offers-actions">
                <Button type="submit" variant="contained" className="save-offers-button">Update Offers</Button>
                <span sx={{ mt: '0 !important' }}>{ this.state.offersMessage }</span>
                <span sx={{ color: red[900], mt: '0 !important' }}>{ this.state.offersError }</span>
              </div>
            </Box>

            <H2>Offices</H2>
            <OfficesTable
              offices={this.state.offices}
              loading={this.state.loadingOffices}
              fetchData={this.loadOffices}
              totalCount={this.state.officesTotalCount}
              pageCount={this.state.officesPageCount}
              pageSize={this.state.officesPageSize}
              error={this.state.officesError}
            />
            <AddOfficeButton id={e.id} />

            <H2>Candidates</H2>
            <EmployerCandidatesTable
              candidates={this.state.candidates}
              loading={this.state.loadingCandidates}
              fetchData={this.loadCandidates}
              totalCount={this.state.candidatesTotalCount}
              pageCount={this.state.candidatePageCount}
              pageSize={this.state.candidatesPageSize}
              error={this.state.candidatesError}
            />

            <H2>Attachments</H2>
            <EmplAttachmentsTable
              attachments={this.state.attachments}
              employerId={this.state.employer.id}
              onEditAttachment={this.onEditAttachment}
              onDeleteAttachment={this.onDeleteAttachmentConfirmation}
              error={this.state.attachmentsError}
              role={this.state.role}
            />
            <AddAttachmentButton id={e.id} />
          </>
        }
        { this.state.editMode && 
          <EditEmployer employerId={this.state.employerId} employer={this.state.employer} notes={this.state.notes} offers={this.state.offers} onSave={this.onSaveEmployer} onCancel={this.onCancelEdit} />
        }
      </Container>

      { this.state.alertMessage &&
        <Alert severity={this.state.alertType || 'error'} onClose={() => this.setState({ alertMessage:'' })}>{this.state.alertMessage}</Alert>
      }

      <Dialog open={this.state.openDeleteDialog} onClose={this.onDeleteConfirmationClose}>
        <DialogTitle sx={{ textAlign: 'left' }}>Delete Employer</DialogTitle>
        <DialogContent sx={{ textAlign: 'left' }}>
          <DialogContentText>
            Do you want to Delete Employer {e.company}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onDeleteEmployer} color="primary" variant="contained" size="small">Delete</Button>
          <Button onClick={this.onDeleteConfirmationClose} color="primary" variant="outlined" size="small" autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.openDeleteAttachmentDialog} onClose={this.onDeleteAttachmentConfirmationClose}>
        <DialogTitle sx={{ textAlign: 'left' }}>Delete Attachment</DialogTitle>
        <DialogContent sx={{ textAlign: 'left' }}>
          <DialogContentText>Do you want to Delete Attachment {this.state.attachment?.name}?</DialogContentText>
          { this.state.dialogErrorMessage &&
            <Typography sx={{ color: red[900] }}>{this.state.dialogErrorMessage}</Typography>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onDeleteAttachment} color="primary" variant="contained" size="small">Delete</Button>
          <Button onClick={this.onDeleteAttachmentConfirmationClose} color="primary" variant="outlined" size="small" autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>;
  }
}

export default withParams(EmployerPage);

