import React, { Component } from 'react';
import { red } from '@mui/material/colors';
import { Box, Button, Container, FormControl, InputLabel, Link, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useParams } from "react-router";
import { useNavigate, Navigate } from "react-router-dom";

import AddAttachment from '../components/AddAttachment';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  employer: null,
  loading: true,
  file: null,
  terms: '',
  notes: '',
  execution: '',
  expiration: '',
  attachmentCategory: 'None',
  attachment: null,
  name: null,
  filename: null,
  size: null,
  invoice: null,
  showInvoice: false,
  contentType: null,
  attachments: [],
  successMessage: null,
  errorMessage: null,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const BackButton = (props) => {
  const navigate = useNavigate();
  return (
    <Link href={props.url} onClick={(evt) => {evt.preventDefault();navigate(props.url)}} underline="hover">Back to {props.name}</Link>
  )
}

class AddEmplAttachmentPage extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  async componentDidMount() {
    const employerId = this.props.params.id;
    const employerUrl = `${env.baseUrl}/v1/employer/${employerId}`;
    fetch(employerUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        if (data?.length) {
          const [employer] = data;
          return this.setState({ employer });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message, error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    const nextInvoiceUrl = `${env.baseUrl}/v1/next-invoice`;
    fetch(nextInvoiceUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ nextInvoice }) => {
        console.log('nextInvoice: ', nextInvoice);
        this.setState({ invoice: nextInvoice });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  handleCategoryChange(event) {
    const { name, value } = event.target;
    //console.log(`handleCategoryChange: ${name} = ${value}`);
    if (name) {
      this.setState({ [name]: value });
      this.setState({ showInvoice: value === 'Invoice' });
    }
  }

  onFilesAdded(files, contents) {
    this.setState({ files });

    const file = files[0];
    this.setState({ file });
    this.setState({ filename: file.name });
    this.setState({ size: file.size });
    this.setState({ contentType: file.type });
    this.setState({ attachment: contents[0] });
  }

  onDeleteAttachment(event, file, index) {
    event.preventDefault();
    const attachments = [...this.state.attachments];
    attachments.splice(index, 1);
    this.setState({ attachments });
  }

  validate() {
    if (!this.state.filename) {
      this.setState({ errorMessage: 'Attachment is required' });
      return false;
    } else
      this.setState({ errorMessage: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      const formData = new FormData();
      formData.append('file', this.state.file);

      // formData doesn't handle null well
      const terms = this.state.terms || '';
      const notes = this.state.notes || '';
      const category = this.state.attachmentCategory || 'None';
      const email = this.state.employer.email_to?.trim() || '';

      formData.append('terms', terms);
      formData.append('notes', notes);
      formData.append('category', category);
      formData.append('email', email);

      const { invoice, execution, expiration } = this.state;
      if (invoice && category === 'Invoice') {
        formData.append('invoice', invoice);
      }
      if (execution) {
        formData.append('execution', execution);
      }
      if (expiration) {
        formData.append('expiration', expiration);
      }

      const employerId = this.props.params.id;
      const url = `${env.baseUrl}/v1/employer/${employerId}/attach`;
      fetch(url, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      })
        .then(result => checkStatus(result))
        .then(response => {
          this.setState({ forward: `/employer/${employerId}`, successMessage: 'Employer Attachment added', errorMessage: '' });
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }

    const backUrl = `/employer/${this.props.params.id}`;
    return <>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0 0', lg: '2rem 0 0', xl: '2rem 0 0' },
        margin: '0 auto 1rem',
        textAlign: 'left'
      }}>
        <h1>Add Employer Attachment</h1>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField name="terms" label="Terms" variant="outlined" multiline minRows="3" maxRows="20" fullWidth onChange={this.handleInputChange} />

          <TextField name="notes" label="Notes" variant="outlined" multiline minRows="3" maxRows="20" fullWidth onChange={this.handleInputChange} />

          <TextField name="execution" label="Execution Date" type="date" variant="outlined" InputLabelProps={{shrink: true}} fullWidth onChange={this.handleInputChange} />

          <TextField name="expiration" label="Expiration Date" type="date" variant="outlined" InputLabelProps={{shrink: true}} fullWidth onChange={this.handleInputChange} />

          <h2>Attachment</h2>
          <FormControl variant="standard" sx={{ minWidth: '12rem' }}>
            <InputLabel id="select-attachment-category-label" className="select-label" sx={{ ml: '1rem' }}>Attachment Category</InputLabel>
            <Select
              name="attachmentCategory"
              labelId="select-attachment-category-label"
              variant="outlined" onChange={this.handleCategoryChange}
              defaultValue="None"
              sx={{ mb: '0.25rem' }}
            >
              <MenuItem value="Unknown">Unknown</MenuItem>
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="Fee Agreement">Fee Agreement</MenuItem>
              <MenuItem value="Job Description">Job Description</MenuItem>
              <MenuItem value="Attachment">Attachment</MenuItem>
              <MenuItem value="Invoice">Invoice</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          { this.state.showInvoice &&
            <TextField name="invoice" label="Invoice Number" type="number" variant="outlined" defaultValue={this.state.invoice} InputLabelProps={{shrink: true}} fullWidth onChange={this.handleInputChange} />
          }

          <AddAttachment onChange={this.onFilesAdded} target={this.state.attachmentCategory || 'Attachment'} sx={{ width: '100%' }} />

          { this.state.filename && 
            <p>{this.state.filename}</p>
          }

          <Button type="submit" variant="contained" sx={{ display: 'block' }}>Submit</Button>
          <Typography sx={{ mt: '0 !important' }}>{ this.state.successMessage }</Typography>
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>

          <BackButton url={backUrl} name="Employer" />
        </Box>

      </Container>

    </>;

  }
}

export default withParams(AddEmplAttachmentPage);

