import { useState } from 'react';

export default function useToken() {

  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    return tokenString ? JSON.parse(tokenString) : null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if (userToken) {
      localStorage.setItem('token', JSON.stringify(userToken));
      setToken(userToken);
    } else {
      deleteToken();
    }
  };

  const deleteToken = () => {
    localStorage.removeItem('token');
    setToken(null);
  };

  return {
    getToken,
    setToken: saveToken,
    deleteToken,
    token
  }

}

