import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Container, FormControl, FormControlLabel, Link, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { red } from '@mui/material/colors';

import { env } from '../env';
import { checkStatus } from '../checkStatus';
import PaginatedTable from './PaginatedTable';


function CandidateApproval({ employers, candidateId, notifyOnApproved, notifyOnDeclined, notifyOnStatus, loading, fetchData, pageSize, pageIndex, pageCount, totalCount, readonly }) {
  const [data, setData] = useState(employers);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  // After data changes, we turn the flag back off
  React.useEffect(() => {
    setData(employers);
    setSkipPageReset(false);

    // Maintain previous selections
    const approvalList = sessionStorage.getItem('approvalList');
    if (approvalList) {
      const list = approvalList.split(',');
      for (const employer of employers) {
        const found = list.find(id => id === employer.id);
        if (found)
          employer.selected = true;
      }
    }

  }, [employers]);

  const locale = Intl.NumberFormat().resolvedOptions().locale;

  const onAddToApprovalList = React.useCallback((event) => {
    setErrorMessage('');
    const ids = event.target.value.split(':');
    const [, employerId, indexString] = ids;
    const index = parseInt(indexString, 10);
    const value = event.target.checked;

    const url = `${env.baseUrl}/v1/candidate/${candidateId}/employer/${employerId}/approvalList`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ value }),
      headers:{ 'Content-Type':'application/json' },
      credentials:'include',
    })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data.rowCount === 1) {
          setSkipPageReset(true);
          const list = [...data];
          const employer = list[index];
          employer.approval_list = value ? new Date().toISOString() : null;
          setData(list);
        } else {
          setErrorMessage('Candidate add to Approval List failed');
        }
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message || 'Candidate add to Approval List failed');
      });
  }, [data, candidateId]);

  const onApproved = React.useCallback((event) => {
    setErrorMessage('');
    const ids = event.target.value.split(':');
    const [, employerId, indexString] = ids;
    const index = parseInt(indexString, 10);
    const value = event.target.checked;

    const url = `${env.baseUrl}/v1/candidate/${candidateId}/employer/${employerId}/approved`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ value }),
      headers:{ 'Content-Type':'application/json' },
      credentials:'include',
    })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data.rowCount === 1) {
          setSkipPageReset(true);
          const list = [...data];
          const employer = list[index];
          employer.declined = null;
          employer.approved = value ? new Date().toISOString() : null;
          if (value)
            notifyOnApproved(employer);
          else
            notifyOnDeclined(employer);
          setData(list);
        }
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message || 'Candidate Approved Employer failed');
      });
  }, [data, notifyOnApproved, notifyOnDeclined, candidateId]);

  const onDeclined = React.useCallback((event) => {
    setErrorMessage('');
    const ids = event.target.value.split(':');
    const [, employerId, indexString] = ids;
    const index = parseInt(indexString, 10);
    const value = event.target.checked;

    const url = `${env.baseUrl}/v1/candidate/${candidateId}/employer/${employerId}/declined`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ value }),
      headers:{ 'Content-Type':'application/json' },
      credentials:'include',
    })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data.rowCount === 1) {
          setSkipPageReset(true);
          const list = [...data];
          const employer = list[index];
          employer.approved = null;
          employer.declined = value ? new Date().toISOString() : null;
          if (value)
            notifyOnDeclined(employer);
          else
            notifyOnApproved(employer);
          setData(list);
        }
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message || 'Candidate Declined Employer failed');
      });
  }, [data, notifyOnApproved, notifyOnDeclined, candidateId]);

  const onStatusChange = React.useCallback((event, status, row, index) => {
    //console.log('onStatusChange: ', row);
    setErrorMessage('');
    const employerId = row.employer_id || row.id;

    const url = `${env.baseUrl}/v1/candidate/${candidateId}/employer/${employerId}/status`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ status }),
      headers:{ 'Content-Type':'application/json' },
      credentials:'include',
    })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data.rowCount === 1) {
          setSkipPageReset(true);
          const list = [...data];
          const employer = list[index];
          updateStatus(employer, status);
          notifyOnStatus([employer]);
          setData(list);
        }
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message || 'Candidate-employer status change failed');
      });
  }, [data, notifyOnStatus, candidateId]);

  const updateStatus = (row, status) => {
    row.candidate_status = status;

    const value = new Date().toISOString();
    switch (status) {
      case 'Blind Submission':
        row.blind_submission = value;
        break;
      case 'Interested':
        row.interested = value;
        break;
      case 'Passed':
        row.passed = value;
        break;
      case 'No Openings':
        row.no_openings = value;
        break;
      case 'Approval List':
        row.approval_list = value;
        break;
      case 'Approved':
        row.approved = value;
        break;
      case 'Declined':
        row.declined = value;
        break;
      case 'Submission List':
        row.submission_list = value;
        break;
      case 'Other Submission':
        row.other_submission = value;
        break;
      case 'Submitted':
        row.submitted = value;
        break;
      case 'Resubmitted':
        row.resubmitted = value;
        break;
      case 'Under Review':
        row.under_review = value;
        break;
      case 'Interview':
        row.interview = value;
        break;
      case 'Interviewed But Rejected':
        row.interviewed_but_rejected = value;
        break;
      case 'Offer':
        row.offer = value;
        break;
      case 'Offer Declined':
        row.offer_declined = value;
        break;
      case 'Placed':
        row.placed = value;
        break;
      default:
        break;
    }
  }

  const onSelect = React.useCallback((event, value, row, index, data) => {
    row.selected = !row.selected;
    setData(data);
    //console.log('onSelect: ', data);

    // Add/remove to/from session approvalList selections
    const approvalList = sessionStorage.getItem('approvalList');
    const list = approvalList ? approvalList.split(',') : [];
    const found = list.findIndex(id => id === row.id);
    if (found >= 0 && !row.selected) {
      list.splice(found, 1);
    } else if (row.selected) {
      list.push(row.id);
    }
    const selectedApprovalList = list.join(',');
    sessionStorage.setItem('approvalList', selectedApprovalList);
    //console.log('approvalList: ', selectedApprovalList);
  }, []);

  // 1. Create Columns
  const columns = React.useMemo(() => {

    const getStatusLabel = (row) => {
      let label = ''
      switch (row.candidate_status) {
        case 'Blind Submission':
          label = getDate(row.blind_submission);
          break;
        case 'Interested':
          label = getDate(row.interested);
          break;
        case 'Passed':
          label = getDate(row.passed);
          break;
        case 'No Openings':
          label = getDate(row.no_openings);
          break;
        case 'Approval List':
          label = getDate(row.approval_list);
          break;
        case 'Approved':
          label = getDate(row.approved);
          break;
        case 'Declined':
          label = getDate(row.declined);
          break;
        case 'Submission List':
          label = getDate(row.submission_list);
          break;
        case 'Other Submission':
          label = getDate(row.other_submission);
          break;
        case 'Submitted':
          label = getDate(row.submitted);
          break;
        case 'Resubmitted':
          label = getDate(row.resubmitted);
          break;
        case 'Under Review':
          label = getDate(row.under_review);
          break;
        case 'Interview':
          label = getDate(row.interview);
          break;
        case 'Interviewed But Rejected':
          label = getDate(row.interviewed_but_rejected);
          break;
        case 'Offer':
          label = getDate(row.offer);
          break;
        case 'Offer Declined':
          label = getDate(row.offer_declined);
          break;
        case 'Placed':
          label = getDate(row.placed);
          break;
        default:
          break;
      }
      return label;
    }

    const getDate = (value) => {
      if (!value) return '';
      const date = new Date(value);
      return `${date.toLocaleDateString(locale, {dateStyle:'medium'})} ${date.toLocaleTimeString(locale, {timeStyle:'short'})}`;
    }

    return [{
      Header: 'Company/Firm',
      tipText: 'Employer Legal Company name',
      accessor: 'company',
      Cell: ({value, row}) => {
        const { id, parent_id, name, company, parent_name, parent_company, status } = row.original;
        const url = `/employer/${parent_id || id}`;
        const cname = status === 'On Hold' ? 'on-hold-link' : '';
        const checkbox = <Checkbox name="candidate_approval" defaultChecked={row.original.selected} onChange={(evt) => onSelect(evt, evt.target.value, row.original, row.index, employers)} disabled={readonly} />;
        return <>
          <FormControlLabel className="company-control" control={checkbox} sx={{ margin:0 }}/>
          <Tooltip title={parent_name || name}>
            <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{parent_company || company}</Link>
          </Tooltip>
        </>;
      }
    },
    {
      Header: 'Office',
      tipText: 'Employer Office name',
      accessor: 'office_name',
      Cell: ({value, row}) => {
        const url = `/office/${row.original.id}`;
        const cname = row.original.status === 'On Hold' ? 'on-hold-link' : '';
        return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{value}</Link>
      }
    },
    {
      Header: 'Approval List',
      tipText: 'Add to Approval List, so you don\'t have to view all employers',
      accessor: 'approval_list',
      Cell: ({value, row}) => {
        const id = `${candidateId}:${row.original.id}:${row.index}`;
        const label = getDate(value);
        return <FormControlLabel control={<Checkbox name="approval_list" defaultChecked={!!value} value={id} onClick={onAddToApprovalList} disableRipple={true} />} label={label} disabled={readonly} />;
      }
    },
    {
      Header: 'Blind Sub',
      tipText: 'Blind Submission date',
      accessor: 'blind_submission',
      Cell: ({value, row}) => {
        const label = getDate(value);
        //return <FormControlLabel control={<Checkbox name="blind_submission" defaultChecked={!!value} value={id} onClick={onBlindSubmission} disableRipple={true} />} label={label} disabled={readonly} />;
        return label;
      }
    },
    {
      Header: 'Approved',
      tipText: 'Approved by the Candidate for submission',
      accessor: 'approved',
      Cell: ({value, row}) => {
        const id = `${candidateId}:${row.original.id}:${row.index}`;
        const label = getDate(value);
        return <FormControlLabel control={<Checkbox name="approved" defaultChecked={!!value} value={id} onClick={onApproved} disableRipple={true} />} label={label} disabled={readonly} />;
      }
    },
    {
      Header: 'Declined',
      tipText: 'Declined by the Candidate for submission',
      accessor: 'declined',
      Cell: ({value, row}) => {
        const id = `${candidateId}:${row.original.id}:${row.index}`;
        const label = getDate(value);
        return <FormControlLabel control={<Checkbox name="declined" defaultChecked={!!value} value={id} onClick={onDeclined} disableRipple={true} />} label={label} disabled={readonly} />;
      }
    },
    {
      Header: 'Online',
      tipText: 'Online submissions only?',
      accessor: 'online_subs',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'Blinds',
      tipText: 'Blind submissions required?',
      accessor: 'blind_feelers',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'FA',
      tipText: 'Employer Fee Agreements exist',
      accessor: 'attachments',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'Status',
      tipText: 'Status for this Employer and Candidate',
      accessor: 'candidate_status',
      Cell: ({value, row}) => {
        const label = getStatusLabel(row.original);
        if (value === 'Interview' && row.original.interview) {
          const date = new Date(row.original.interview);
          const interview = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));

          //row.original.interviewDate = interview.toLocaleDateString(locale, {dateStyle:'medium'});
          row.original.interviewTime = interview.toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit', hour12: false});
          row.original.interviewDate = row.original.interview.split('T')[0];
        }
        return <Container sx={{ display:'flex', alignItems:'center', gap:'0.5rem' }} disableGutters={true}>
          <FormControl variant="standard" sx={{ minWidth: '12rem' }}>
            <Select
              name="status"
              labelId="status-label"
              id="status_select"
              className="status-select"
              variant="outlined"
              onChange={(evt) => onStatusChange(evt, evt.target.value, row.original, row.index)}
              defaultValue={value}
              disabled={readonly}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="Unknown">Unknown</MenuItem>
              <MenuItem value="Other Recruiter">Other Recruiter</MenuItem>

              <MenuItem value="Blind Submission">Blind Submission</MenuItem>
              <MenuItem value="Interested">Interested</MenuItem>
              <MenuItem value="Passed">Passed</MenuItem>
              <MenuItem value="No Openings">No Openings</MenuItem>
              <MenuItem value="Approval List">Approval List</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Declined">Declined</MenuItem>
              <MenuItem value="Submission List">Submission List</MenuItem>
              <MenuItem value="Other Submission">Other Submission</MenuItem>
              <MenuItem value="Submitted">Submitted</MenuItem>
              <MenuItem value="Resubmitted">Resubmitted</MenuItem>
              <MenuItem value="Under Review">Under Review</MenuItem>
              <MenuItem value="Interview">Interview</MenuItem>
              <MenuItem value="Interviewed But Rejected">Interviewed But Rejected</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              <MenuItem value="Offer">Offer</MenuItem>
              <MenuItem value="Offer Declined">Offer Declined</MenuItem>
              <MenuItem value="Placed">Placed</MenuItem>
            </Select>


          </FormControl>
          &nbsp;
          <span className="submitted-status-label">{ label }</span>

        </Container>
      }
    }
    ];
   }, [employers, onAddToApprovalList, onSelect, onApproved, onDeclined, onStatusChange, candidateId, locale, navigate, readonly]
  );

  const getRowClasses = (row) => {
    let classes = '';
    if (row.original.online_subs) {
      classes += 'online-subs';
    }
    if (row.original.blind_feelers) {
      if (!classes) classes += ' ';
      classes += 'blind-feelers';
    }
    if (row.original.status === 'Active') {
      classes += ' active';
    } else if (row.original.status === 'On Hold') {
      classes += ' on-hold';
    } else if (row.original.status === 'Blacklisted') {
      classes += ' blacklisted';
    }
    return (classes) ? ({ className: classes }) : {};
  }

  return (<>
    <PaginatedTable
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      totalCount={totalCount}
      pageCount={pageCount}
      pageSize={pageSize}
      target="Employers"
      skipPageReset={skipPageReset}
      getRowClasses={getRowClasses}
    />
    <Typography sx={{ color: red[900], mt: '0 !important' }}>{ errorMessage }</Typography>
  </>);
}

export default CandidateApproval;
