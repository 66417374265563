import React, { Component } from 'react';
import { Box } from "@mui/material";

import './Submission.css';


export default class Submission extends Component {

  locale = Intl.NumberFormat().resolvedOptions().locale;

  getDate(value) {
    if (!value) return '';
    const date = new Date(value);
    return `${date.toLocaleDateString(this.locale, {dateStyle:'medium'})} ${date.toLocaleTimeString(this.locale, {timeStyle:'short'})}`;
  }

  render() {
    const s = this.props.submission;

    return (<Box sx={{ ml:'1rem', textAlign: 'left' }}>
      <div className="submission-profile">
        <ul>
          <li><span className="submission-name">Submission Time</span><span className="submission-value">{this.getDate(s.created_on)}</span></li>
          <li><span className="submission-name">Submission Type</span><span className="submission-value">{s.submit_type || '--'}</span></li>
          <li><span className="submission-name">Status</span><span className="submission-value">{s.status || '--'}</span></li>
          <li><span className="submission-name">Candidate Email</span><span className="submission-value">{s.candidate_email || '--'}</span></li>
          <li><span className="submission-name">Email Subject</span><span className="submission-value">{s.subject || '--'}</span></li>
          <li><span className="submission-name">Email Body</span><span className="submission-value">{s.body || '--'}</span></li>
        </ul>
      </div>
    </Box>);
  }
}

