import React, { Component } from 'react'

import './Upload.css'
import Dropzone from './Dropzone';


const defaultState = {
  files: []
};

export default class AddAttachment extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;

    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  render() {
    const target = this.props.target || 'Attachment';
    return (
      <div className="file-selector">
        <Dropzone
          onFilesAdded={this.onFilesAdded}
          target={target}
        />
      </div>
    );
  }

  onFilesAdded(files) {
    if (files?.length) {
      this.setState(prevState => ({
        files: prevState.files.concat(files)
      }));
      if (this.props.onChange) {
        // this.state.files is not set yet
        const contents = [];
        for (let i=0; i < files.length; i++) {
          const file = files[i];
          let reader = new FileReader();
          reader.addEventListener('load', (event) => {
            contents.push(event.target.result);
// currently only works with one file
this.props.onChange(files, contents);
          });
          reader.readAsDataURL(file);
        }
      }
    }
  }

}

