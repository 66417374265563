import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import PaginatedTable from './PaginatedTable';


export default function ExclusiveTable({ listings, fetchData, loading, totalCount, pageCount, pageSize, onEdit, onDelete }) {

  const [data, setData] = useState(listings);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const navigate = useNavigate();


  // After data changes, we turn the flag back off
  React.useEffect(() => {
    setData(listings);
    setSkipPageReset(false);
  }, [listings]);

  // 1. Create Columns
  const columns = React.useMemo( () => {
    return [
    {
      Header: 'Company/Firm',
      accessor: 'company',
      Cell: ({value, row}) => {
        const { employer_id, company } = row.original;
        const url = `/employer/${employer_id}`;
        const cname = row.original.status === 'On Hold' ? 'on-hold-link' : '';
        return <Tooltip title={company}><Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{company}</Link></Tooltip>;
      }
    },
    {
      Header: 'Location',
      accessor: 'location',
      Cell: ({value, row, index}) => {
        return value?.length > 64 ? <pre>{value.substring(0, 63)+'...'}</pre> : <pre>{value}</pre>;
      }
    },
    {
      Header: 'Practice Area',
      accessor: 'practice_area',
      Cell: ({value, row, index}) => {
        return value?.length > 64 ? <pre>{value.substring(0, 63)+'...'}</pre> : <pre>{value}</pre>;
      }
    },
    {
      Header: 'Class Year',
      accessor: 'class_year',
      Cell: ({value, row, index}) => {
        return value?.length > 64 ? <pre>{value.substring(0, 63)+'...'}</pre> : <pre>{value}</pre>;
      }
    },
    {
      Header: 'Recruiter',
      accessor: 'recruiter',
      Cell: ({value, row, index}) => {
        return value?.length > 64 ? <pre>{value.substring(0, 63)+'...'}</pre> : <pre>{value}</pre>;
      }
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      minWidth: 64,
      Cell: ({value, row, index}) => {
        const maxLength = 256;
        return value?.length > maxLength ? <pre>{value.substring(0, maxLength-1)+'...'}</pre> : <pre>{value}</pre>;
      }
    },
    {
      Header: 'Actions',
      Cell: ({value, row, index}) => {
        return <>
          <Tooltip title="Edit"><EditIcon onClick={(event) => onEdit(event, row.original, row.index)} sx={{ cursor: 'pointer' }}/></Tooltip>
          <Tooltip title="Delete"><DeleteIcon onClick={(event) => onDelete(event, row.original, row.index)} sx={{ cursor: 'pointer' }}/></Tooltip>
        </>;
      }
    },
    ]},
  [navigate, onEdit, onDelete]
 );

  const getRowClasses = (row) => {
    let classes = '';
    return (classes) ? ({ className: classes }) : {};
  }

  // 3. Return the HTML Table
  return (<>
    <PaginatedTable
      data={data}
      columns={columns}
      fetchData={fetchData}
      loading={loading}
      totalCount={totalCount}
      pageCount={pageCount}
      pageSize={pageSize}
      getRowClasses={getRowClasses}
      skipPageReset={skipPageReset}
      target="Exclusive Listings"
    />
  </>);
}

