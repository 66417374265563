import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { PropTypes } from 'prop-types';

import './Editor.css';
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";


export default class Editor extends Component {

  static propTypes = {
    placeholder: PropTypes.string,
  }

  quillRef = null;

  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  static modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'},
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  static formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

  constructor (props) {
    super(props);
    this.state = {
      editorHtml: '',
      theme: 'snow',
    };
    this.setValue = this.setValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.quillRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ editorHtml: this.props.value || '' });
  }

  setHTML(value) {
    this.setState({ editorHtml: value || '' });
  }

  handleChange(html) {
  	this.setState({ editorHtml: html });
    if (this.props.onChange) {
      this.props.onChange(html);
    }
  }

  setValue(value) {
    const quill = this.quillRef.current.getEditor();

    this.setState({ editorHtml: value || ''}, () => {
      quill.focus();
      quill.clipboard.dangerouslyPasteHTML(value);

      //quill.setSelection(newValueObject.indexAfterInsertion, 0, quill);
    });
  }
  
  handleThemeChange(newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme })
  }
  
  render () {
    return (
        <ReactQuill 
          ref={this.quillRef}
          theme={this.state.theme}
          onChange={this.handleChange}
          value={this.state.editorHtml}
          modules={Editor.modules}
          formats={Editor.formats}
          bounds={'.app'}
          placeholder={this.props.placeholder}
          readOnly={this.props.disabled}
         />
     );
  }
}

