import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, Link, Tooltip } from "@mui/material";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { useTable, useSortBy } from 'react-table';

import './SubmissionList.css';


function SubmissionListTable({ columns, data, skipPageReset, updateEmployers }) {
  const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data }, useSortBy);

  const getRowClasses = (row) => {
    let classes = '';
    if (row.original.online_subs) {
      classes += 'online-subs';
    }
    if (row.original.blind_feelers) {
      if (!classes) classes += ' ';
      classes += 'blind-feelers';
    }
    return (classes) ? ({ className: classes }) : {};
  }

  return (<>
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps({...column.getSortByToggleProps(), title: column.tipText })}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? ' 🔽'
                                    : ' 🔼'
                                  : ''}
                              </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} {...getRowClasses(row)} >
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                        );
                    }
                )}
            </tbody>
        </table>

      { data?.length === 0 &&
        <p className="empty-message">No Employers/Offices</p>
      }

      { data?.length > 0 &&
        <div className="total-count-row">
          Total Count: {" "}
          <strong>{data.length}</strong>
        </div>
      }
    </>);
}

function SubmissionList({ submissionList, updateEmployers }) {
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false)

  const navigate = useNavigate();

  React.useEffect(() => {
    setData(submissionList);
  }, [submissionList])

  // After data changes, we turn the flag back off
  React.useEffect(() => {
    setSkipPageReset(false)
  }, [data])

  const onSelected = React.useCallback((event) => {
    event.preventDefault();
    const ids = event.target.value.split(':');
    const [,,indexString] = ids;
    const index = parseInt(indexString, 10);
    const value = event.target.checked;

    setSkipPageReset(true);
    const list = [...data];
    list[index].selected = value;
    updateEmployers(list);
    setData(list);
  }, [data, updateEmployers]);

  // 1. Create Columns
  const columns = React.useMemo(
    () => [{
      Header: 'Company/Firm',
      tipText: 'Employer Legal Company name',
      accessor: 'selected',
      Cell: ({value, row}) => {
        const {id, parent_id, candidate_id, employer_id, name, company, parent_name, parent_company, selected} = row.original;
        const rowIds = `${candidate_id}:${employer_id}:${row.index}`;
        const url = `/employer/${parent_id || id}`;
        return <>
          <FormControlLabel control=<Checkbox name="submission_list" checked={!!selected} value={rowIds}/>
            onClick={onSelected} sx={{ width: '1rem', height: '1rem', mr:'1.25rem' }} />
          <Tooltip title={parent_name || name}>
            <Link href={url} onClick={(evt) => {evt.preventDefault(); navigate(url)}} underline="hover">{parent_company || company}</Link>
          </Tooltip>
        </>;
      }
    },
    {
      Header: 'Office',
      tipText: 'Employer Office name',
      accessor: 'office_name',
      Cell: ({value, row}) => {
         const url = `/office/${row.original.employer_id}`;
         const cname = row.original.status === 'On Hold' ? 'on-hold-link' : '';
         return <Link href={url} className={cname} onClick={(evt) => {evt.preventDefault(); navigate(url)}} underline="hover">{value}</Link>
      }
    },
    {
      Header: 'Coordinators',
      tipText: 'Recruiting Coordinators',
      accessor: 'email_to',
      Cell: ({value, row}) => {
        const { first_name, last_name, email_to } = row.original;
        const { first_name2, last_name2, email_to2 } = row.original;
        const { first_name3, last_name3, email_to3 } = row.original;
        const { first_name4, last_name4, email_to4 } = row.original;

        const name1 = `${first_name || ''} ${last_name || ''}`.trim();
        const name2 = `${first_name2 || ''} ${last_name2 || ''}`.trim();
        const name3 = `${first_name3 || ''} ${last_name3 || ''}`.trim();
        const name4 = `${first_name4 || ''} ${last_name4 || ''}`.trim();

        let coords = email_to ? (name1 ? `${name1} <${email_to}>` : email_to) : '';
        if (email_to2) {
          coords += email_to2 ? (name2 ? `\n${name2} <${email_to2}>` : `\n${email_to2}`) : '';
        }
        if (email_to3) {
          coords += email_to3 ? (name3 ? `\n${name3} <${email_to3}>` : `\n${email_to3}`) : '';
        }
        if (email_to4) {
          coords += email_to4 ? (name4 ? `\n${name4} <${email_to4}>` : `\n${email_to4}`) : '';
        }
        return <div className="wrap-cell">{ coords }</div>;
      }
    },
    {
      Header: 'Address',
      accessor: 'address',
      Cell: ({value, row}) => {
        const { city, state, country } = row.original;
        let addr = city || '';
        if (city && state) addr += ', ';
        addr += state ? `${state} ` : '';
        if (country && !country.startsWith('US')) addr += country;
        return <span>{addr}</span>;
      }
    },
    {
      Header: 'Practice Areas',
      accessor: 'practice_areas'
    },
    {
      Header: 'Online',
      tipText: 'Online submissions only?',
      accessor: 'online_subs',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'Blinds',
      tipText: 'Blind submissions required?',
      accessor: 'blind_feelers',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'FA',
      tipText: 'Employer Fee Agreements exist',
      accessor: 'attachments',
      Cell: ({value, row}) => (value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'Candidate Status',
      accessor: 'candidate_status',
    }],
  [onSelected, navigate]
 );

  return <SubmissionListTable columns={columns} data={data} skipPageReset={skipPageReset} updateEmployers={updateEmployers} />;
}

export default SubmissionList;
