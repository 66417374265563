import React, { Component } from 'react';
import { red } from '@mui/material/colors';
import { Box, Button, Container, Link, TextField, Typography } from "@mui/material";
import { useNavigate, Navigate } from "react-router-dom";
import { useParams } from "react-router";

import { env } from '../env';
import { checkStatus, goToLogin } from '../checkStatus';

const defaultState = {
  contact: null,
  startTime: null,
  expiration: null,
  location: null,
  practice_area: null,
  class_year: null,
  recruiter: null,
  notes: null,
  successMessage: null,
  forward: null,
  errorMessage: null,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const EmployerLink = (props) => {
  const navigate = useNavigate();
  return (
    <Link href={props.url} onClick={(evt) => {evt.preventDefault();navigate(props.url)}} underline="hover">{props.name}</Link>
  )
}

const BackButton = (props) => {
  const navigate = useNavigate();
  return (
    <Link href={props.url} onClick={(evt) => {evt.preventDefault();navigate(props.url)}} underline="hover">Back to {props.name}</Link>
  )
}

class EditDoNotContactPage extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBooleanChange = this.handleBooleanChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      console.error('Not logged in');
      return;
    }
    this.setState({ user });

    const contactId = this.props.params.id;
    const url = `${env.baseUrl}/v1/exclusive/${contactId}`;
    fetch(url, { credentials: 'include' })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data) {
          const [contact] = result.data;
          const startTime = contact.start_time.split('T')[0];
          const expiration = contact.expiration.split('T')[0];
          this.setState({ contact, startTime, expiration,
            location: contact.location,
            practice_area: contact.practice_area,
            class_year: contact.class_year,
            recruiter: contact.recruiter,
            notes: contact.notes,
          });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message, error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  handleBooleanChange(event) {
    const { name, checked } = event.target;
    if (name)
      this.setState({ [name]: checked });
  }

  validate() {
    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      const payload = {
        start_time: this.state.startTime?.trim(),
        expiration: this.state.expiration?.trim(),
        location: this.state.location?.trim(),
        practice_area: this.state.practice_area?.trim(),
        class_year: this.state.class_year?.trim(),
        recruiter: this.state.recruiter?.trim(),
        notes: this.state.notes?.trim(),
      };

      const contactId = this.props.params.id;
      const url = `${env.baseUrl}/v1/exclusive/${contactId}`;
      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        credentials: 'include',
      })
        .then(response => checkStatus(response))
        .then(value => {
          const forward = '/exclusive';
          this.setState({ forward });
        })
        .catch(error => {
          console.error(error);
          if (error.code === 401) {
            return goToLogin();
          }
          this.setState({ errorMessage: error.message || 'Update exclusive-listings failed' });
        });
    }
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }

    const contact = this.state.contact;
    const employerUrl = `/employer/${contact?.employer_id}`;
    const backUrl = '/exclusive';

    return <>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0 0', lg: '2rem 0 0', xl: '2rem 0 0' },
        margin: '0 auto 1rem',
        textAlign: 'left'
      }}>
        <h1>Edit Exclusive Listing</h1>

        { contact &&
        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.phoneError }</Typography>

          <Typography>Employer: <EmployerLink url={employerUrl} name={contact.company} /></Typography>

          <TextField name="startTime" defaultValue={this.state.startTime} label="Start Date" type="date" variant="outlined" InputLabelProps={{shrink: true}} fullWidth onChange={this.handleInputChange} />

          <TextField name="expiration" defaultValue={this.state.expiration} label="Expiration Date" type="date" variant="outlined" InputLabelProps={{shrink: true}} fullWidth onChange={this.handleInputChange} />

          <TextField name="location" label="Location" defaultValue={this.state.location} variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="practice_area" label="Practice Area" defaultValue={this.state.practice_area} variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="class_year" label="Class Year" defaultValue={this.state.class_year} variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="recruiter" label="Recruiter" defaultValue={this.state.recruiter} variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="notes" label="Notes" defaultValue={this.state.notes} variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', color: red[900] }}>
            <Button type="submit" variant="contained">Update</Button>
            <Typography>{ this.state.errorMessage }</Typography>
          </Box>

          <BackButton url={backUrl} name="Exclusive Listings" />
        </Box>
        }

      </Container>
      </>;

  }
}

export default withParams(EditDoNotContactPage);

