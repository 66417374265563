import { Component } from 'react';
import { Box, Button, CircularProgress, Container, Dialog, DialogTitle, DialogActions, DialogContent, InputLabel, FormControl, Link, Menu, MenuItem, Select, TextField, Typography } from '@mui/material';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { ArrowDropDown, ThumbUp, Warning } from '@mui/icons-material';
import { amber, green, red } from '@mui/material/colors';
import { useParams } from 'react-router';
import { useNavigate, Navigate } from "react-router-dom";
import styled from 'styled-components';

import Office from '../components/Office';
import EditOffice from '../components/EditOffice';
import EmployerCandidatesTable from '../components/EmployerCandidatesTable';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  user: null,
  loading: true,
  forward: null,
  office: null,
  notes: '',
  status: 'Unknown',
  editMode: false,

  loadingCandidates: true,
  candidatesTotalCount: 0,
  candidatesPageIndex: 0,
  candidatesPageSize: 10,
  candidatesPageCount: 0,
  candidates: [],
  candidatesError: null,

  anchorEL: null,
  open: false,
  openDeleteDialog: false,
  dialogErrorMessage: null,
  successMessage: null,
  errorMessage: null,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const H1 = styled.h1`
  margin: 0;
`;
const H2 = styled.h2`
  margin: 1.5rem 0 0.25rem;
`;

const BackButton = (props) => {
  const navigate = useNavigate();
  return (
    <Link href={props.url} onClick={(evt) => {evt.preventDefault();navigate(props.url)}} underline="hover">Back to {props.name}</Link>
  )
}

class OfficePage extends Component {

  locale = Intl.NumberFormat().resolvedOptions().locale;

  constructor() {
    super();
    this.state = defaultState;
    this.openActionsMenu = this.openActionsMenu.bind(this);
    this.closeActionsMenu = this.closeActionsMenu.bind(this);
    this.onSaveOffice = this.onSaveOffice.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onUpdateOffice = this.onUpdateOffice.bind(this);
    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.onDeleteConfirmationClose = this.onDeleteConfirmationClose.bind(this);
    this.onDeleteOffice = this.onDeleteOffice.bind(this);
    this.loadCandidates = this.loadCandidates.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
    this.saveStatus = this.saveStatus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      console.error('Not logged in');
      return;
    }
    this.setState({ user });

    const officeId = this.props.params.id;
    const url = `${env.baseUrl}/v1/office/${officeId}`;
    fetch(url, { credentials: 'include' })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data) {
          const [office] = result.data;
          const status = office.status || 'Unknown';
          const notes = office.notes || '';
          this.setState({ office, status, notes });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message, error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getCandidatesUrl(searchText, pageSize, pageIndex) {
    const employerId = this.props.params.id;
    let url = `${env.baseUrl}/v1/employer/${employerId}/candidate`;
    let queryParams = '';
    if (this.state.searchText) {
      if (queryParams) queryParams += '&';
      queryParams += `search=${this.state.searchText}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    return url;
  }

  loadCandidates({ pageSize, pageIndex }) {
    this.setState({ candidatePageSize: pageSize, candidatePageIndex: pageIndex });
    this.setState({ loadingCandidates: true });
    const url = this.getCandidatesUrl(this.state.searchText, pageSize, pageIndex);
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        const candidatesTotalCount = items.data.length ? items.data[0].count : 0;
        this.setState({ candidatesTotalCount, candidatePageCount: Math.ceil(candidatesTotalCount / pageSize) });
        this.setState({ candidatesError: '' });
        this.setState({ candidates: items.data});
      })
      .catch(err => {
        console.error(err);
        this.setState({ candidatesError: 'Unable to get candidates' });
      })
      .finally(() => {
        this.setState({ loadingCandidates: false });
      });
  }

  saveStatus(event) {
    const status = event.target.value;
    const url = `${env.baseUrl}/v1/employer/${this.state.office.id}/status`;

    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ status }),
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(response => {
      if (response.data.rowCount === 1) {
        this.setState({ status });
        this.setSuccessMessage('Office Status saved');
      } else
        this.setState({ errorMessage: 'Save status failed' });
    })
    .catch(error => {
      console.error(error);
      this.setState({ errorMessage: 'Save status failed' });
    });
  }

  saveNotes(event) {
    event.preventDefault();
    const office = this.state.office;
    const url = `${env.baseUrl}/v1/employer/${office.id}/notes`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ notes: this.state.notes }),
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(response => {
      if (response.data.rowCount === 1) {
        const office = {...this.state.office};
        office.notes = this.state.notes;
        this.setState({ notes: this.state.notes, office });
        this.setSuccessMessage('Office Notes saved');
      } else
        this.setState({ errorMessage: 'Save Notes failed' });
    })
    .catch(error => {
      console.error(error);
      this.setState({ errorMessage: 'Save Notes failed' });
    });
  }

  setSuccessMessage(msg) {
    this.setState({ successMessage: msg, errorMessage: '' });
    setTimeout(() => this.setState({ successMessage: '' }), 5000);
  }

  openActionsMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ open: true, anchorEL: event.currentTarget });
  }

  closeActionsMenu() {
    this.setState({ open: false, anchorEL: null });
  }

  onUpdateOffice() {
    this.setState({ editMode: true });
    this.closeActionsMenu();
  }

  onSaveOffice(updatedOffice) {
    const office = {...this.state.office};
    Object.assign(office, updatedOffice);
    this.setState({ editMode: false, office });
  }

  onCancelEdit() {
    this.setState({ editMode: false });
  }

  onDeleteConfirmation() {
    this.setState({ openDeleteDialog: true, dialogErrorMessage: '' });
  };

  onDeleteConfirmationClose() {
    this.setState({ openDeleteDialog: false });
  }

  onDeleteOffice() {
    this.setState({ dialogErrorMessage: '' });
    const url = `${env.baseUrl}/v1/office/${this.props.params.id}`;
    fetch(url, {
        method: 'DELETE',
        body: '{}',
        headers:{ 'Content-Type':'application/json' },
        credentials: 'include',
    })
      .then(result => checkStatus(result))
      .then(items => {
        const forward = `/employer/${this.state.office.parent_id}`;
        this.setState({ forward });
        this.closeActionsMenu();
      })
      .catch(error => {
        console.error(error);
        this.setState({ dialogErrorMessage: error.message });
      });
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }
    if (this.state.loading) {
      return <Container sx={{ m: '2rem' }}>
        <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
        Loading Office...
      </Container>;
    }

    const o = this.state.office;
    if (!o || o === []) {
      return <p>Unable to find office</p>;
    }
    const backUrl = `/employer/${o.parent_id}`;

    return <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '1rem 0 0' }} maxWidth={false}>
        { !this.state.editMode &&
          <H1>Office {o.office_name}</H1>
        }
        { this.state.editMode &&
          <H1>Edit Office {o.office_name}</H1>
        }

        <Container component="div" className="office-actions" sx={{ display: 'flex', alignItems: 'center', width: 'auto', m: 0 }}>
          { this.state.office &&
            <FormControl variant="standard" sx={{ minWidth: '12rem' }}>
              <InputLabel id="select-office-status-label" className="select-label" sx={{ left: '1rem', top: '-0.4rem', backgroundColor: 'white', zIndex: 9, p: '0 0.25rem' }}>Status</InputLabel>
              <Select
                name="status"
                labelId="select-office-status-label"
                variant="outlined" onChange={this.saveStatus}
                value={this.state.status}
                sx={{ mr: '0.5rem' }}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Unknown"><Unchecked sx={{opacity:'0.5', mr: '0.25rem'}}/> Unknown</MenuItem>
                <MenuItem value="Active"><ThumbUp sx={{color: green[600], mr: '0.25rem' }}/> Active</MenuItem>
                <MenuItem value="On Hold"><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> On Hold</MenuItem>
              </Select>
            </FormControl>
          }

          <Button
            id="office-actions-btn"
            variant="contained"
            aria-controls={this.state.open ? 'office-actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={this.state.open ? 'true' : undefined}
            onClick={this.openActionsMenu}
          >
            Actions
            <ArrowDropDown sx={{ml: '0.25rem'}}/>
          </Button>
          <Menu
            id="office-actions-menu"
            anchorEl={this.state.anchorEL}
            open={this.state.open}
            onClose={this.closeActionsMenu}
            MenuListProps={{
              'aria-labelledby': 'office-actions-btn',
            }}
          >
            <MenuItem onClick={this.onUpdateOffice}>Edit Office</MenuItem>
            <MenuItem onClick={this.onDeleteConfirmation} disabled={this.state.user?.role === 'Recruiter'}>Delete Office</MenuItem>
          </Menu>

        </Container>
      </Container>

      <Container component="div" sx={{ margin: '0.5rem 0 2rem', textAlign: 'left' }}>
      { !this.state.editMode &&
        <>
          <Office office={this.state.office} />

          <H2>Notes</H2>
          <Box
            component="form"
            className="notes-form"
            noValidate
            align="left"
            autoComplete="off"
            onSubmit={this.saveNotes}
            sx={{ width: '100%' }}
          >
            <TextField name="notes" multiline minRows="4" maxRows="20" defaultValue={this.state.notes} className="office-notes" onChange={this.handleInputChange} sx={{ width: '100%', mb: '0.5rem' }} />

            <div className="notes-actions">
              <Button type="submit" variant="contained" className="save-notes-button">Update Notes</Button>
              <Typography sx={{ mt: '0 !important' }}>{ this.state.successMessage }</Typography>
              <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
            </div>
          </Box>

          <H2>Candidates</H2>
          <EmployerCandidatesTable
            candidates={this.state.candidates}
            loading={this.state.loadingCandidates}
            fetchData={this.loadCandidates}
            totalCount={this.state.candidatesTotalCount}
            pageCount={this.state.candidatePageCount}
            pageSize={this.state.candidatesPageSize}
            error={this.state.candidatesError}
          />
        </>
      }


      { this.state.editMode &&
        <EditOffice office={this.state.office} onSave={this.onSaveOffice} onCancel={this.onCancelEdit} />
      }
      </Container>

      { this.state.office &&
        <Container component="div" sx={{ margin: '2rem 0', textAlign: 'left' }}>
          <BackButton url={backUrl} name={o.parent_name || o.parent_company} />
        </Container>
      }

      <Dialog open={this.state.openDeleteDialog} onClose={this.onDeleteConfirmationClose}>
        <DialogTitle sx={{ textAlign: 'left' }}>Delete Office</DialogTitle>
        <DialogContent sx={{ textAlign: 'left' }}>
          <Typography>Do you want to Delete Office {o.office_name}?</Typography>
          { this.state.dialogErrorMessage &&
            <Typography sx={{ color: red[900] }}>{this.state.dialogErrorMessage}</Typography>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onDeleteOffice} color="primary" variant="contained" size="small">Delete</Button>
          <Button onClick={this.onDeleteConfirmationClose} color="primary" variant="outlined" size="small" autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>;
  }
}

export default withParams(OfficePage);

