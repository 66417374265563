import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "@mui/material";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { ThumbUp, ThumbDown, Warning } from '@mui/icons-material';
import { amber, green, red } from '@mui/material/colors';

import PaginatedTable from './PaginatedTable';
import './CandidatesTable.css';


const statusStyles = {
  display : 'flex',
  alignItems: 'center',
};

export default function CandidatesTable({ candidates, fetchData, loading, totalCount, pageCount, pageSize}) {

  const locale = Intl.NumberFormat().resolvedOptions().locale;
  const navigate = useNavigate();

  // 1. Create Columns
  const columns = React.useMemo(() => {

    const getDate = (value) => {
      if (!value) return '';
      const date = new Date(value);
      return `${date.toLocaleDateString(locale, {dateStyle:'medium'})} ${date.toLocaleTimeString(locale, {timeStyle:'short'})}`;
    }

     return [{
         Header: 'Name',
         accessor: 'last_name',
         Cell: ({value, row}) => {
           const { id, first_name, last_name } = row.original;
           const url = `/candidate/${id}`;
           let name = last_name || '';
           if (first_name) name += `, ${first_name}`;
           return name ? <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} underline="hover">{name}</Link> : '';
         }
       },
       {
         Header: 'Email',
         accessor: 'email',
         Cell: ({value, row}) => {
           const { id, email, last_name } = row.original;
           const url = `/candidate/${id}`;
           return last_name ? email : <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} underline="hover">{value}</Link>;
         }
       },
       {
         Header: 'Phone',
         accessor: 'phone'
       },
       {
         Header: 'Employer Status',
         accessor: 'candidate_status',
         Cell: ({value, row}) => {
           const data = row.original;

           if (data.status === 'Inactive') {
              return 'Inactive';
           } else if (data.status === 'Hired') {
              return (data.placed) ? `Hired ${getDate(data.placed)}` : 'Hired';
           } else if (data.status === 'Offer') {
              return (data.offer) ? `Offer ${getDate(data.offer)}` : 'Offer';
           } else if (data.status === 'Offer Declined') {
              return (data.offer_declined) ? `Offer Declined ${getDate(data.offer_declined)}` : 'Offer Declined';
           } else if (data.status === 'Followed Up') {
              return (data.follow_up) ? `Followed Up ${getDate(data.follow_up)}` : 'Followed Up';
           } else if (data.status === 'Needs Follow Up') {
              return 'Needs Follow Up';
           } else if (data.status === 'Rejected') {
              return (data.rejected) ? `Rejected ${getDate(data.rejected)}` : 'Rejected';
           } else if (data.status === 'Interview') {
              return (data.interview) ? `Interview ${getDate(data.interview)}` : 'Interview';
           } else if (data.status === 'Interviewed But Rejected') {
              return (data.interviewed_but_rejected) ? `Interviewed But Rejected ${getDate(data.interviewed_but_rejected)}` : 'Interviewed But Rejected';
           
           } else if (data.under_review) {
              return `Under Review ${getDate(data.under_review)}`;
           } else if (data.resubmitted) {
              return `Resubmitted ${getDate(data.resubmitted)}`;
           } else if (data.submitted) {
              return `Submitted ${getDate(data.submitted)}`;
           } else if (data.approved) {
              return `Approved ${getDate(data.approved)}`;
           } else if (data.declined) {
              return `Declined ${getDate(data.declined)}`;
           } else if (data.no_openings) {
              return `No Openings ${getDate(data.no_openings)}`;
           } else if (data.passed) {
              return `Passed ${getDate(data.passed)}`;
           } else if (data.interested) {
              return `Interested ${getDate(data.interested)}`;
           } else if (data.blind_submission) {
              return `Blind Submission ${getDate(data.blind_submission)}`;
           } else {
              return row.candidate_status;
           }
         }
       },
       {
         Header: 'Candidate Status',
         accessor: 'status',
         Cell: ({value, row}) => {
           switch (row.status) {
             case 'Unknown':
             case 'Inactive':
               return <span style={statusStyles}><Unchecked sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
             case 'Hired':
             case 'FollowedUp':
               return <span style={statusStyles}><ThumbUp sx={{color: green[600], mr: '0.25rem'}}/> {value}</span>;
             case 'Offer Declined':
               return <span style={statusStyles}><ThumbDown sx={{opacity:'0.5', color: red[900], mr: '0.25rem' }}/> {value}</span>;
             case 'Need to Contact':
             case 'Needs Follow Up':
               return <span style={statusStyles}><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> {value}</span>;
             case null:
               return '--';
             default:
               return <span style={statusStyles}><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
           }
         }
       }];

     }, [locale, navigate]
   );

  const getRowClasses = (row) => {
    return {};
  };

  // 3. Return the HTML Table
  return (<>
    <PaginatedTable
      data={candidates}
      columns={columns}
      fetchData={fetchData}
      loading={loading}
      totalCount={totalCount}
      pageCount={pageCount}
      pageSize={pageSize}
      target="Candidates"
      getRowClasses={getRowClasses}
    />
  </>);
}

