import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, Link, Tooltip, Typography } from "@mui/material";
import { red } from '@mui/material/colors';

import { env } from '../env';
import { checkStatus } from '../checkStatus';
import BaseTable from './BaseTable';


function EmployerSubmissions({ approved, notifyOnline, notifySubmit, candidateId, readonly }) {
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const locale = Intl.NumberFormat().resolvedOptions().locale;

  React.useEffect(() => {

    // Maintain previous selections
    const submissionList = sessionStorage.getItem('submissionList');
    if (submissionList) {
      const list = submissionList.split(',');
      //console.log('submissionList: ', list);
      for (const employer of approved) {
        const found = list.find(id => id === employer.id);
        employer.selected = !!found;
      }
    } else {
      for (const employer of approved) {
        employer.selected = false;
      }
    }

    //console.log('EmployerSubmissions: ', approved);
    setData(approved);
  },[approved]);

  // After data changes, we turn the flag back off
  React.useEffect(() => {
    setSkipPageReset(false)
  }, [data])

  const onOtherSubmission = React.useCallback((event) => {
    event.preventDefault();
    const ids = event.target.value.split(':');
    const [, employerId, indexString] = ids;
    const index = parseInt(indexString, 10);
    const value = event.target.checked;
    setErrorMessage('');

    const url = `${env.baseUrl}/v1/candidate/${candidateId}/employer/${employerId}/otherSubmission`;
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ value }),
      headers:{ 'Content-Type':'application/json' },
      credentials:'include',
    })
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data.rowCount === 1) {
          setSkipPageReset(true);
          const list = [...data];
          const employer = list[index];
          employer.other_submission = value ? new Date().toISOString() : null;
          if (value)
            notifyOnline(employer);
          else
            notifySubmit(employer);
          setData(list);
        }
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message || 'Other submission failed');
      });
  }, [data, notifyOnline, notifySubmit, candidateId]);

  const onSelect = React.useCallback((event, value, row, index, data) => {
    row.selected = !row.selected;
    setData(data);
    //console.log('EmployerSubmissions.onSelect: ', data);

    // Add/remove to/from session submissionList selections
    const submissionList = sessionStorage.getItem('submissionList');
    const list = submissionList ? submissionList.split(',') : [];
    const found = list.findIndex(id => id === row.id);
    if (found >= 0 && !row.selected) {
      list.splice(found, 1);
    } else if (row.selected) {
      list.push(row.id);
    }
    const selectedSubmissionList = list.join(',');
    sessionStorage.setItem('submissionList', selectedSubmissionList);
    //console.log('submissionList: ', selectedSubmissionList);
  }, []);


  // 1. Create Columns
  const columns = React.useMemo(() => {
    const getDate = (value) => {
      if (!value) return '';
      const date = new Date(value);
      return `${date.toLocaleDateString(locale, {dateStyle:'medium'})} ${date.toLocaleTimeString(locale, {timeStyle:'short'})}`;
    }

    return [{
      Header: 'Company/Firm',
      accessor: 'company',
      Cell: ({value, row}) => {
        const { id, parent_id, name, company, parent_name, parent_company, status } = row.original;
        const url = `/employer/${parent_id || id}`;
        const cname = status === 'On Hold' ? 'on-hold-link' : '';
        const checkbox = <Checkbox name="submission" defaultChecked={row.original.selected} onChange={(evt) => onSelect(evt, evt.target.value, row.original, row.index, approved)} disabled={readonly} />;
        return <>
          <FormControlLabel className="company-control" control={checkbox} sx={{ margin:0 }} />
          <Tooltip title={parent_name || name}>
            <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{parent_company || company}</Link>
          </Tooltip>
        </>;
      }
    },
    {
      Header: 'Office',
      accessor: 'office_name',
      Cell: ({value, row}) => {
        const url = `/office/${row.original.employer_id}`;
        const cname = row.original.status === 'On Hold' ? 'on-hold-link' : '';
        return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{value}</Link>;
      }
    },
    /*{
      Header: 'Add to Submission List',
      accessor: 'submission_list',
      Cell: ({value, row}) => {
        const id = `${candidateId}:${row.original.id}:${row.index}`;
        const label = getDate(value);
        return <FormControlLabel control={<Checkbox name="submission_list" checked={!!value} value={id} onClick={onAddToSubmissionList} disabled={readonly} />} label={label} />;
      }
    },*/
    {
      Header: 'Online or Other Submission',
      accessor: 'other_submission',
      Cell: ({value, row}) => {
        const id = `${candidateId}:${row.original.id}:${row.index}`;
        const label = getDate(value);
        return <FormControlLabel control={<Checkbox name="other_submission" label="Other Submission" checked={!!value} value={id} onClick={onOtherSubmission} disabled={readonly} />} label={label} />;
      }
    }];
  }, [approved, onSelect, onOtherSubmission, candidateId, locale, navigate, readonly]
 );

  return (<>
    <BaseTable columns={columns} data={data} skipPageReset={skipPageReset} />
    <Typography sx={{ color: red[900], mt: '0 !important' }}>{ errorMessage }</Typography>
  </>);
}

export default EmployerSubmissions;
