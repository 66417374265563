import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Link, Menu, MenuItem, Tooltip } from '@mui/material';
import { Launch } from '@mui/icons-material';

import './LogoBar.css';
import matchLogo from '../assets/LateralMatch_Logo.png';


const FirmProspectsMenuItem = (props) => {
  return (
    <MenuItem><Link href="https://www.firmprospects.com" className="logobar-link" target="_blank" rel="noreferrer" underline="none">Firm Prospects <Launch/></Link></MenuItem>
  )
}

const WebsiteMenuItem = (props) => {
  return (
    <MenuItem><Link href="https://www.lateral-match.com" className="logobar-link" target="_blank" rel="noreferrer" underline="none">www.lateral-match.com <Launch/></Link></MenuItem>
  )
}

const MyAccountMenuItem = (props) => {
  const navigate = useNavigate();
  return (
    <MenuItem onClick={(e)=> props.onClick(e, navigate)} variant="outlined">My Account</MenuItem>
  )
}

const ChangePasswordMenuItem = (props) => {
  const navigate = useNavigate();
  return (
    <MenuItem onClick={(e)=> props.onClick(e, navigate)} variant="outlined">Change Password</MenuItem>
  )
}

const LogoutMenuItem = (props) => {
  const navigate = useNavigate();
  return (
    <MenuItem onClick={(e)=> props.onClick(e, navigate)} variant="outlined">Logout {props.name}</MenuItem>
  )
}

const defaultState = {
  anchorEL: null,
  open: false,
};

export default class LogoBar extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.logout = this.logout.bind(this);
    this.onMyAccount = this.onMyAccount.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.openActionsMenu = this.openActionsMenu.bind(this);
    this.closeActionsMenu = this.closeActionsMenu.bind(this);
  }

  onMyAccount(evt, nav) {
    this.closeActionsMenu();
    nav('/my-account');
  }

  onChangePassword(evt, nav) {
    this.closeActionsMenu();
    nav('/change-password');
  }

  logout(evt, nav) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    sessionStorage.removeItem('approvalList');
    sessionStorage.removeItem('submissionList');
    sessionStorage.removeItem('submittedEmployers');
    sessionStorage.removeItem('pathname');

    this.closeActionsMenu();
    const loginUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/login`;
    console.log(loginUrl);
    window.location.assign(loginUrl);
  }

  openActionsMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ open: true, anchorEL: event.currentTarget });
  }

  closeActionsMenu() {
    this.setState({ open: false, anchorEL: null });
  }

  render() {
    const homeLink = '/home';
    const user = JSON.parse(localStorage.getItem('user'));
    const name = user?.username || '--';

    return <div className="logobar">
      <a href={homeLink}><img className="match-logo" src={matchLogo} alt="Lateral Match logo" width="120" height="57" /></a>

      <Tooltip title={user?.email} arrow>
        <Button
          id="logobar-actions-btn"
          variant="contained"
          aria-label="User menu"
          aria-controls={this.state.open ? 'logobar-actions-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={this.state.open ? 'true' : undefined}
          onClick={this.openActionsMenu}
          sx={{ width: '2rem', minWidth: '2rem', maxWidth: '2rem', height: '2rem', minHeight: '2rem', maxHeight: '2rem', borderRadius: '50%' }}
        >
          { name[0] }
        </Button>
      </Tooltip>

      <Menu
        id="logobar-actions-menu"
        anchorEl={this.state.anchorEL}
        open={this.state.open}
        onClose={this.closeActionsMenu}
        MenuListProps={{ 'aria-labelledby': 'logobar-actions-btn' }}
      >
        <FirmProspectsMenuItem />
        <WebsiteMenuItem />
        <MyAccountMenuItem onClick={this.onMyAccount} />
        <ChangePasswordMenuItem onClick={this.onChangePassword} />
        <LogoutMenuItem onClick={this.logout} name={name} />
      </Menu>
    </div>;
  }
}

