import { Component } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Button, Checkbox, Container, FormControlLabel, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { Clear, Tune } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import styled from "styled-components";

import CandidatesTable from '../components/CandidatesTable';
import './MyCandidates.css';


const defaultState = {
  searchText: '',
  statuses: [],
  advancedSearch: false,
  loading: true,
  totalCount: 0,
  pageIndex: 0,
  pageSize: 50,
  pageCount: 0,
  errorMessage: null,
};

const H2 = styled.h2`
  margin: 0;
`;

const AddCandidateButton = (props) => {
  const navigate = useNavigate();
  const recruiterId = props.recruiterId;

  return (
    <Button onClick={()=> navigate(`/recruiter/${recruiterId}/add-candidate`)} variant="outlined" sx={{ mt: '0.5rem' }}>Add New Candidate</Button>
  )
}

export default class MyCandidates extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });

    if (this.props.setSearchText)
      this.props.setSearchText(value);
  }

  handleStatusChange(event) {
    const { name, checked } = event.target;
    if (name) {
      const statuses = this.state.statuses;
      if (checked)
        statuses[name] = checked;
      else
        delete statuses[name];
      this.setState({ statuses });

      if (this.props.setStatuses)
        this.props.setStatuses(statuses);

      const { pageSize } = this.state;
      this.props.fetchData({ pageSize, pageIndex: 0 });
    }
  }

  async componentDidMount() {
    this.searchLabel = `Search ${this.props.type || 'Candidates'} by Name/Email`;

    const { pageSize, pageIndex } = this.state;
    this.props.fetchData({ pageSize, pageIndex });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { pageSize } = this.state;
    this.props.fetchData({ pageSize, pageIndex: 0 });
  }

  onClearSearch(event) {
    if (event) event.preventDefault();

    if (this.props.setSearchText)
      this.props.setSearchText('');

    this.setState({ searchText: '' }, () => {
      const { pageSize, pageIndex } = this.state;
      this.props.fetchData({ pageSize, pageIndex });
    });
  }

  render() {
    return <Container className="my-candidates" sx={{ margin: '1rem 0 2rem', textAlign: 'left' }} maxWidth={false}>
      <Container component="div" sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', width: '100%', margin: '0' }} disableGutters={true} maxWidth={false}>
        <H2>{ this.props.title || 'Candidates' }</H2>

        <Box
          component="form"
          className="search-form"
          noValidate
          autoComplete="off"
          onSubmit={this.handleSubmit}
          sx={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '30rem' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center', width: '30rem' }}>
            { this.props.setStatuses &&
              <Tooltip title="Advanced Search" arrow><IconButton onClick={() => this.setState({ advancedSearch: !this.state.advancedSearch }) }><Tune /></IconButton></Tooltip>
            }
            <TextField name="searchText" value={this.state.searchText} label={this.searchLabel} variant="outlined" fullWidth onChange={this.handleInputChange} size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Clear onClick={this.onClearSearch} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                )
              }}
            />
            <Button type="submit" variant="contained">Search</Button>
            </Box>
          </Box>

      </Container>

      { this.state.advancedSearch && this.props.setStatuses && 
        <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '0' }} disableGutters={true}>
        <FormControlLabel control={<Checkbox name="Need to Contact"  onChange={this.handleStatusChange}/>} label="Need to Contact" className="candidate-status-label" />
        <FormControlLabel control={<Checkbox name="Contacted"        onChange={this.handleStatusChange}/>} label="Contacted" className="candidate-status-label" />
        <FormControlLabel control={<Checkbox name="Blind Submission" onChange={this.handleStatusChange}/>} label="Blind Submission" className="candidate-status-label" />
        <FormControlLabel control={<Checkbox name="Sent Firms"       onChange={this.handleStatusChange}/>} label="Sent Firms" className="candidate-status-label" />
        <FormControlLabel control={<Checkbox name="Submitted"        onChange={this.handleStatusChange}/>} label="Submitted" className="candidate-status-label" />
      </Container>
      <Container component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '0 0 0.25rem' }} disableGutters={true}>
        <FormControlLabel control={<Checkbox name="Interview"       onChange={this.handleStatusChange}/>} label="Interview" className="candidate-status-label" />
        <FormControlLabel control={<Checkbox name="Interviewed But Rejected" onChange={this.handleStatusChange}/>} label="Interviewed But Rejected" className="candidate-status-label" />
        <FormControlLabel control={<Checkbox name="Offer"           onChange={this.handleStatusChange}/>} label="Offer" className="candidate-status-label" />
        <FormControlLabel control={<Checkbox name="Offer Declined"  onChange={this.handleStatusChange}/>} label="Offer Declined" className="candidate-status-label" />
        <FormControlLabel control={<Checkbox name="Hired"           onChange={this.handleStatusChange}/>} label="Hired" className="candidate-status-label" />
        <FormControlLabel control={<Checkbox name="Needs Follow Up" onChange={this.handleStatusChange}/>} label="Needs Follow Up" className="candidate-status-label" />
        <FormControlLabel control={<Checkbox name="Followed Up"     onChange={this.handleStatusChange}/>} label="Followed Up" className="candidate-status-label" />
      </Container>
        </>
      }

      <CandidatesTable
        candidates={this.props.candidates}
        loading={this.props.loading}
        fetchData={this.props.fetchData}
        totalCount={this.props.totalCount}
        pageCount={this.props.pageCount}
        pageSize={this.props.pageSize}
        includePracticeAreas={this.props.includePracticeAreas}
    />
      <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>

      { this.props.setStatuses &&
        <AddCandidateButton recruiterId={this.props.recruiterId} />
      }
    </Container>;
  }
}

