import { Component } from 'react';
import { Container } from "@mui/material";
import About from '../components/About';

export default class AboutPage extends Component {
  render() {
    return (<>
      <Container sx={{ height: 'calc(100vh - 15rem)' }}>
        <About />
      </Container>
    </>);
  }
}

