import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Tooltip } from '@mui/material';
import Warning from '@mui/icons-material/WarningAmberOutlined';

import PaginatedTable from './PaginatedTable';
import './EmployersTable.css';
import { env } from '../env';


export default function ExpiredAttachmentsTable({ attachments, fetchData, loading, totalCount, pageCount, pageSize}) {

  const locale = Intl.NumberFormat().resolvedOptions().locale;
  const navigate = useNavigate();

  // 1. Create Columns
  const columns = React.useMemo( () => {

    const getUTCDate = (value) => {
      if (!value) return '';
      const date = new Date(value);
      const utc = convertDateToUTC(date);
      return `${utc.toLocaleDateString(locale, {dateStyle:'medium'})}`;
    }

    const convertDateToUTC = (date) => {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    return [{
       Header: 'Name',
       accessor: 'name',
       Cell: ({value, row}) => {
         const url = `${env.baseUrl}/v1/employer/${row.original.employer_id}/attachment/${row.original.id}/download`;
         return <Link href={url} target="_blank" rel="noreferrer" download underline="hover">{value}</Link>;
       }
     },
    {
      Header: 'Employer',
      accessor: 'company',
      Cell: ({value, row}) => {
        const { employer_id, company } = row.original;
        const url = `/employer/${employer_id}`;
        const cname = row.original.status === 'On Hold' ? 'on-hold-link' : '';
        return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{company}</Link>
      }
    },
     {
       Header: 'Category',
       accessor: 'category'
     },
     {
       Header: 'Notes',
       accessor: 'notes'
     },
     {
       Header: 'Terms',
       accessor: 'terms'
     },
     {
       Header: 'Executed',
       accessor: 'execution',
       Cell: ({value, row}) => {
         if (!value) return '--';
         return getUTCDate(value);
       }
     },
     {
       Header: 'Expiration',
       accessor: 'expiration',
       Cell: ({value, row}) => {
         if (!value) return '--';
         const { category, name, expired, willExpire } = row.original;
         const expiration = new Date(value);
         const expirationDate = getUTCDate(expiration);

         if (expired) {
           const msg = `${category || ''} ${name} expired on ${expirationDate}`;
           return <Tooltip title={msg} arrow><span style={{ display: 'flex', alignItems: 'center' }}><Warning sx={{color:'red'}}/> <strong>{expirationDate}</strong></span></Tooltip>;

         } else if (willExpire) {
           const msg = `${category || ''} ${name} will be expired on ${expirationDate}`;
           return <Tooltip title={msg} arrow><span style={{ display: 'flex', alignItems: 'center' }}><Warning sx={{color:'orange'}}/> <strong>{expirationDate}</strong></span></Tooltip>;
         }
         return expirationDate;
       }
    }];
  }, [navigate, locale]
 );

  const getRowClasses = (row) => {
    let classes = '';
    if (row.original.online_subs) {
      classes += ' online-subs';
    }
    if (row.original.blind_feelers) {
      classes += ' blind-feelers';
    }
    if (row.original.status === 'Active') {
      classes += ' active';
    }
    if (row.original.status === 'On Hold') {
      classes += ' on-hold';
    }
    return (classes) ? ({ className: classes }) : {};
  }

  // 3. Return the HTML Table
  return (<>
    <PaginatedTable
      data={attachments}
      columns={columns}
      fetchData={fetchData}
      loading={loading}
      totalCount={totalCount}
      pageCount={pageCount}
      pageSize={pageSize}
      target="Expired Attachments"
      getRowClasses={getRowClasses}
    />
  </>);
}

