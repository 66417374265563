import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "@mui/material";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { ThumbUp, ThumbDown, Warning } from '@mui/icons-material';
import { amber, green, red } from '@mui/material/colors';

import BaseTable from './BaseTable';
import './CandidatesTable.css';


const statusStyles = {
  display : 'flex',
  alignItems: 'center',
};

export default function SubmissionsTable({ submissions, fetchData, loading, totalCount }) {

  const navigate = useNavigate();
  const locale = Intl.NumberFormat().resolvedOptions().locale;

  const getSubmitType = (type) => {
    switch (type) {
      case 'blindSubmission':
        return 'Blind Submission';
      case 'submitCandidate':
        return 'Candidate Submission';
      case 'followUp':
        return 'Follow Up';
      default:
        return '--';
    }
  };

  // 1. Create Columns
  const columns = React.useMemo(
    () => {
      const getDate = (value) => {
        if (!value) return '';
        const date = new Date(value);
        return `${date.toLocaleDateString(locale, {dateStyle:'medium'})} ${date.toLocaleTimeString(locale, {timeStyle:'short'})}`;
      };

      const cols = [{
         Header: 'Submission Type',
         accessor: 'submit_type',
         Cell: ({value, row}) => {
           const { id } = row.original;
           const url = `/submission/${id}`;
           const submitType = getSubmitType(value);
           return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} underline="hover">{submitType}</Link>;
         }
      },
      {
         Header: 'Date',
         accessor: 'created_on',
         Cell: ({value, row}) => getDate(value)
      },
      {
         Header: 'Candidate Email',
         accessor: 'candidate_email',
         Cell: ({value, row}) => {
           const { id, email, last_name } = row.original;
           const url = `/candidate/${id}`;
           return last_name ? email : <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} underline="hover">{value}</Link>;
         }
      },
      {
         Header: 'Notes',
         accessor: 'notes',
         Cell: ({value, row}) => {
           const MAX_LENGTH = 84;
           return (value?.length > MAX_LENGTH) ?  value.substring(0, MAX_LENGTH) + '...' : value;
         }
      },
      {
         Header: '# Employers',
         accessor: 'count',
         Cell: ({value, row}) => (value || 0),
      },
      {
         Header: 'Status',
         accessor: 'status',
         Cell: ({value, row}) => {
           switch (value) {
             case 'Unknown':
               return <span style={statusStyles}><Unchecked sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
             case 'Hired':
             case 'FollowedUp':
               return <span style={statusStyles}><ThumbUp sx={{color: green[600], mr: '0.25rem'}}/> {value}</span>;
             case 'Offer Declined':
               return <span style={statusStyles}><ThumbDown sx={{opacity:'0.5', color: red[900], mr: '0.25rem' }}/> {value}</span>;
             case 'Need to Contact':
             case 'Needs Follow Up':
               return <span style={statusStyles}><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> {value}</span>;
             default:
               return <span style={statusStyles}><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
           }
         }
      }];
      return cols;
    }, [navigate, locale]
  );

  // 3. Return the HTML Table
  return (<>
    <BaseTable
      data={submissions}
      columns={columns}
      fetchData={fetchData}
      loading={loading}
    />
  </>);
}

