import { Component } from 'react';
import { Box, Button, CircularProgress, Container, InputAdornment, TextField, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Clear } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import styled from "styled-components";

import EmployersTable from '../components/EmployersTable';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  searchEmployerName: '',
  searchAddress: '',
  searchOfficeName: '',
  loading: true,
  totalCount: 0,
  pageIndex: 0,
  pageSize: 1000,
  pageCount: 0,
  employers: [],
  feeAgreements: 0,
  forward: null,
  errorMessage: null,
};

const H1 = styled.h1`
  margin-top: 1rem;
`;
const SearchForm = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const AddEmployerButton = () => {
  const navigate = useNavigate();
  return (
    <Button onClick={()=> navigate('/add-employer')} variant="outlined" sx={{ m: '1rem 0' }}>Add New Employer</Button>
  )
}

export default class EmployersPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSearchEmployers = this.onSearchEmployers.bind(this);
    this.onClearSearchEmployerName = this.onClearSearchEmployerName.bind(this);
    this.onClearSearchAddress = this.onClearSearchAddress.bind(this);
    this.onClearSearchOfficeName = this.onClearSearchOfficeName.bind(this);
    this.query = this.query.bind(this);
  }

  async componentDidMount() {
    const url = `${env.baseUrl}/v1/employer-fee-agreements`;
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        return this.setState({ feeAgreements: items.count });
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message || 'Unable to load employer', error });
      });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  populate(allEmployers) {
    //console.log('allEmployers: ', allEmployers);
    const parentEmployers = [];
    for (const e of allEmployers) {
      if (!e.parent_id) {
        parentEmployers.push(e);
      }
    }
    // office search
    if (!parentEmployers.length) return allEmployers;

    //console.log('parentEmployers: ', parentEmployers);
    for (const e of allEmployers) {
      if (e.parent_id) {
        const parent = parentEmployers.find(pe => pe.id === e.parent_id);
        if (parent) {
          if (!parent.subRows) parent.subRows = [];
          const child = parent.subRows.find(c => c.id === e.id);
          if (!child) {
            parent.subRows.push(e);
          }
        }
      }
    }
    //console.log('EmployersTable: ', parentEmployers);
    return parentEmployers;
  }

  query({ pageSize, pageIndex }) {
    this.setState({ pageSize, pageIndex, loading: true });
    let url = `${env.baseUrl}/v1/employer`;
    let queryParams = '';
    const searchEmployerName = this.state.searchEmployerName;
    const searchAddress = this.state.searchAddress;
    const searchOfficeName = this.state.searchOfficeName;
    if (searchEmployerName) {
      if (queryParams) queryParams += '&';
      queryParams += `searchEmployer=${searchEmployerName}`;
    }
    if (searchAddress) {
      if (queryParams) queryParams += '&';
      queryParams += `searchAddress=${searchAddress}`;
    }
    if (searchOfficeName) {
      if (queryParams) queryParams += '&';
      queryParams += `searchOffice=${searchOfficeName}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(result => {
        if (result.data) {
          const employers = (searchAddress || searchOfficeName) ? result.data : this.populate(result.data);
          const totalCount = employers?.length || 0;
          const pageCount = Math.ceil(totalCount / this.state.pageSize);
          this.setState({ employers, totalCount, pageCount, errorMessage: '' });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message || 'Unable to load employers', error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onSearchEmployers(event) {
    if (event) event.preventDefault();
    const { pageSize, pageIndex } = this.state;
    this.query({ pageSize, pageIndex });
  }

  onClearSearchEmployerName() {
    this.setState({ searchEmployerName: '' }, this.onSearchEmployers);
  }

  onClearSearchAddress() {
    this.setState({ searchAddress: '' }, this.onSearchEmployers);
  }

  onClearSearchOfficeName() {
    this.setState({ searchOfficeName: '' }, this.onSearchEmployers);
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }

    return <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'top', justifyContent: 'space-between', width: '100%', margin: '1rem 0' }} maxWidth={false}>
        <H1>Legal Employers</H1>

        <SearchForm>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={this.onSearchEmployers}
            sx={{ display: 'flex', gap: '1rem', alignItems: 'top' }}
          >
            <Box
              component="div"
              noValidate
              autoComplete="off"
              sx={{ display: 'flex', flexDirection:'column', gap: '1rem', alignItems: 'center' }}
            >
              <Box
                component="div"
                noValidate
                autoComplete="off"
                sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
              >
              <TextField name="searchEmployerName" value={this.state.searchEmployerName} label="Search by Company/Firm" variant="outlined" fullWidth onChange={this.handleInputChange}
                  sx={{ minWidth: '15rem' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Clear onClick={this.onClearSearchEmployerName} sx={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
              />
              <TextField name="searchOfficeName" value={this.state.searchOfficeName} label="Search by Office Name" variant="outlined" fullWidth onChange={this.handleInputChange}
                  sx={{ minWidth: '15rem' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Clear onClick={this.onClearSearchOfficeName} sx={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
              />
              </Box>
              <TextField name="searchAddress" value={this.state.searchAddress} label="Search by Address" variant="outlined" fullWidth onChange={this.handleInputChange}
                  sx={{ minWidth: '15rem' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Clear onClick={this.onClearSearchAddress} sx={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
              />
            </Box>
            <div>
              <Button type="submit" variant="contained" sx={{ padding: '0.5rem 2rem' }}>Search</Button>
            </div>
          </Box>
        </SearchForm>
      </Container>

      <Container sx={{ mt: '1rem', pb: '2rem', textAlign: 'left' }} maxWidth={false}>
        <AddEmployerButton />

        <EmployersTable
          employers={this.state.employers}
          loading={this.state.loading}
          fetchData={this.query}
          pageCount={this.state.pageCount}
          pageSize={this.state.pageSize}
          totalCount={this.state.totalCount}
        />

        { this.state.loading && 
          <Container sx={{ m: '2rem' }} disableGutters={true}>
            <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
            Loading Employers...
          </Container>
        }
        <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>

        <AddEmployerButton />

        <div>Number of Employers with Fee Agreements: {this.state.feeAgreements}</div>
      </Container>
    </>;
  }
}

