import React, { Component } from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

import { checkStatus } from '../checkStatus';
import { env } from '../env';


export default class EditCandidate extends Component {

  constructor(props) {
    super(props);

    const { signup_source, signup_completed_on, interested_locations, current_firm, law_school, graduated_on, preferred_locations, email, phone, address, city, state, zipcode, country, first_name, last_name, notes, description, role, practice_areas, secondary_practice_areas, preferred_practice_areas } = this.props.candidate;
 
    // TIMESTAMPS are 2022-10-01T05:00:00.000Z, TextField requires yyyyt-MM-dd
    const signupCompletedOn = signup_completed_on ? signup_completed_on.split('T')[0] : '';

    this.state = {
      email,
      signup_source: signup_source || '',
      signup_completed_on: signupCompletedOn || '',
      current_firm: current_firm || '',
      law_school: law_school || '',
      graduated_on: graduated_on || '',
      preferred_locations: preferred_locations || '',
      interested_locations: interested_locations || '',
      phone: phone || '',
      address: address || '',
      city: city || '',
      state: state || '',
      zipcode: zipcode || '',
      country: country || 'USA',
      first_name: first_name || '',
      last_name: last_name || '',
      notes: notes || '',
      description: description || '',
      role: role || '',
      practice_areas: practice_areas || '',
      secondary_practice_areas: secondary_practice_areas || '',
      preferred_practice_areas: preferred_practice_areas || '',

      emailError: null,
      errorMessage: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBooleanChange = this.handleBooleanChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  handleBooleanChange(event) {
    const { name, checked } = event.target;
    if (name)
      this.setState({ [name]: checked });
  }

  validate() {
    if (!this.state.email) {
      this.setState({ emailError: 'Email is required' });
      return false;
    } else
      this.setState({ emailError: null });

    const reg = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (reg.test(this.state.email) === false) {
      this.setState({ emailError: 'Email is invalid' });
      return false;
    } else
      this.setState({ emailError: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      const { signup_source, signup_completed_on, interested_locations, current_firm, law_school, graduated_on, preferred_locations, email, phone, address, city, state, zipcode, country, first_name, last_name, notes, description, role, practice_areas, secondary_practice_areas, preferred_practice_areas } = this.state;

      const url = `${env.baseUrl}/v1/candidate/${this.props.candidate.id}`;

      const payload = {
        signup_source,
        signup_completed_on,
        interested_locations,
        current_firm,
        law_school,
        graduated_on,
        preferred_locations,

        email: email?.trim(),
        phone: phone?.trim(),
        address: address?.trim(),
        city: city?.trim(),
        state: state?.trim(),
        zipcode: zipcode?.trim(),
        country: country?.trim(),
        first_name: first_name?.trim(),
        last_name: last_name?.trim(),

        notes,
        description,
        role,
        practice_areas,
        secondary_practice_areas,
        preferred_practice_areas,
      };

      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })
        .then(response => checkStatus(response))
        .then(value => {
          this.props.onSave(payload);
        })
        .catch(error => {
          console.error(error);
          this.setState({ errorMessage: error.message, error });
        });
    }
  }

  render() {
    return (<Container sx={{ m: '0 2rem' }}>
        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.3rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField name="email" value={this.state.email} label="Email" variant="outlined" fullWidth required onChange={this.handleInputChange} error={!this.state.email || !this.state.email.includes('@')} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.emailError }</Typography>

          <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }} disableGutters={true} >
            <TextField name="first_name" value={this.state.first_name} label="First name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
            <TextField name="last_name" value={this.state.last_name} label="Last name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
          </Container>

          <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true}>
            <TextField name="first_name" value={this.state.first_name} label="First name" variant="outlined" sx={{ width: '48%' }} onChange={this.handleInputChange} />
            <TextField name="last_name" value={this.state.last_name} label="Last name" variant="outlined" sx={{ width: '48%', ml: '1.65rem !important' }} onChange={this.handleInputChange} />
          </Container>
          <TextField name="phone" value={this.state.phone} label="Phone" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <TextField name="address" value={this.state.address} label="Address" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="city" value={this.state.city} label="City" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="state" value={this.state.state} label="State" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="zipcode" value={this.state.zipcode} label="Zipcode" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="country" value={this.state.country} label="Country" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="practice_areas" value={this.state.practice_areas} label="Practice Areas" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="secondary_practice_areas" value={this.state.secondary_practice_areas} label="Secondary Practice Areas" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="preferred_practice_areas" value={this.state.preferred_practice_areas} label="Preferred Practice Areas" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="current_firm" value={this.state.current_firm} label="Current Firm" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="law_school" value={this.state.law_school} label="Law School" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="graduated_on" value={this.state.graduated_on} type="number" label="Graduation Date" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <TextField name="signup_source" value={this.state.signup_source} label="Sign Up Source" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="signup_completed_on" value={this.state.signup_completed_on} label="Sign Completed On Source" type="date" InputLabelProps={{shrink: true}} variant="outlined" fullWidth onChange={this.handleInputChange} />

          <TextField name="preferred_locations" value={this.state.preferred_locations} label="Preferred Locations" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="interested_locations" value={this.state.interested_locations} label="Interested Locations" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <TextField name="description" value={this.state.description} label="Description" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="notes" value={this.state.notes} label="Notes" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />

          <Container sx={{ display: 'flex', gap: '0.5rem' }} disableGutters={true}>
            <Button type="submit" variant="contained">Save</Button>
            <Button type="button" variant="outlined" onClick={this.props.onCancel}>Cancel</Button>
          </Container>
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
        </Box>
      </Container>);
  }
}

