import React, { Component } from 'react';
import { Nav, NavLink, NavMenu } from "./MenuBarElements";

import { checkStatus } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  role: null,
};

export default class MenuBar extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.setUser = this.setUser.bind(this);
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      const recruiterUrl = `${env.baseUrl}/v1/recruiter/${user.id}`;
      fetch(recruiterUrl, {credentials:'include'})
        .then(result => checkStatus(result))
        .then(items => {
          if (items.data?.length) {
            const recruiter = items.data[0];
            this.setUser(recruiter);
          } else
            this.setState({ errorMessage: 'Unable to get user' });
        })
        .catch(error => {
          console.error(error);
          this.setState({ errorMessage: error.message || 'Unable to load user', error });
        });
    }
  }

  setUser(user) {
    this.setState({ user, role: user.role });
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role || 'Recruiter';
    const isAdmin = role === 'Manager' || role === 'Administrator';
    //<NavLink to="/search">Search Jobs</NavLink>

    return <Nav>
        <NavMenu>
          <NavLink to="/home" end>Home</NavLink>
          <NavLink to="/candidates">Candidates</NavLink>
          
          { isAdmin && 
            <NavLink to="/recruiters">Recruiters</NavLink>
          }

          <NavLink to="/employers">Legal Employers</NavLink>

          { isAdmin &&
            <NavLink to="/invoices">Invoices</NavLink>
          }

          <NavLink to="/exclusive">Exclusive</NavLink>
          <NavLink to="/do-not-contact">Do Not Contact</NavLink>
        </NavMenu>
      </Nav>;
  }
}

