import React, { Component } from 'react';
import { red } from '@mui/material/colors';
import { Box, Button, Container, Link, TextField, Typography } from "@mui/material";
import { useNavigate, Navigate } from "react-router-dom";

import { env } from '../env';
import { checkStatus } from '../checkStatus';


const defaultState = {
  old_password: null,
  password: null,
  confirm_password: null,

  successMessage: null,
  oldPasswordErrorMessage: null,
  passwordErrorMessage: null,
  confirmPasswordErrorMessage: null,
  anchorEL: null,
  open: false,
  errorMessage: null,
};

const BackButton = (props) => {
  const navigate = useNavigate();
  return (
    <Link href={props.url} onClick={(evt) => {evt.preventDefault();navigate(props.url)}} underline="hover">Back to {props.name}</Link>
  )
}

export default class ChangePasswordPage extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  validate() {
    if (!this.state.old_password) {
      this.setState({ oldPasswordErrorMessage: 'Old Password is required' });
      return false;
    } else
      this.setState({ oldPasswordErrorMessage: null });

    if (!this.state.password) {
      this.setState({ passwordErrorMessage: 'New Password is required' });
      return false;
    } else
      this.setState({ passwordErrorMessage: null });

    if (!this.state.confirm_password) {
      this.setState({ passwordErrorMessage: 'Confirm Password is required' });
      return false;
    } else
      this.setState({ passwordErrorMessage: null });

    if (this.state.password === this.state.old_password) {
      this.setState({ passwordErrorMessage: 'New Password must not match old password' });
      return false;
    } else
      this.setState({ passwordErrorMessage: null });

    if (this.state.password !== this.state.confirm_password) {
      this.setState({ confirmPasswordErrorMessage: 'Confirm Password must match password' });
      return false;
    } else
      this.setState({ confirmPasswordErrorMessage: null });
    return true;
  }

  openActionsMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ open: true, anchorEL: event.currentTarget });
  }

  closeActionsMenu() {
    this.setState({ open: false, anchorEL: null });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      const user = JSON.parse(localStorage.getItem('user'));
      const recruiterId = user?.id;
      const url = `${env.baseUrl}/v1/recruiter/${recruiterId}/change-password`;
      const payload = {
        old_password: this.state.old_password,
        password: this.state.password,
        confirm_password: this.state.confirm_password,
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers:{ 'Content-Type':'application/json' },
        credentials:'include',
      })
        .then(result => checkStatus(result))
        .then((result) => {
          if (result.rowCount === 1) {
            this.setState({ forward: '/home' });
          } else {
            this.setState({ errorMessage: 'Unable to change password' });
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({ errorMessage: error.message || 'Change password failed' });
        });
    }
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }

    const backUrl = `/home`;
    return <>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0 0', lg: '2rem 0 0', xl: '2rem 0 0' },
        margin: '0 auto 1rem',
        textAlign: 'left'
      }}>
        <h1>Change Password</h1>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField name="old_password" label="Old Password" type="password" variant="outlined" required fullWidth onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.oldPasswordErrorMessage }</Typography>

          <TextField name="password" label="New Password" type="password" variant="outlined" required fullWidth onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.passwordErrorMessage }</Typography>

          <TextField name="confirm_password" label="Confirm Password" type="password" variant="outlined" required fullWidth onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.confirmPasswordErrorMessage }</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', color: red[900] }}>
            <Button type="submit" variant="contained">Change Password</Button>
            <Typography>{ this.state.errorMessage }</Typography>
          </Box>

          <BackButton url={backUrl} name="Home" />
        </Box>

      </Container>
      </>;

  }
}

