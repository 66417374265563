import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Link, Tooltip } from "@mui/material";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { AccountCircle } from '@mui/icons-material';
import { useTable, useSortBy } from 'react-table';


export default function RecruitersTable({ recruiters }) {

  const navigate = useNavigate();

  // 1. Create Columns
  const columns = React.useMemo( () => {

    const onImpersonate = (event, recruiter) => {
      console.log('onImpersonate: ', recruiter);
      localStorage.setItem('user', JSON.stringify(recruiter));
      navigate('/home');
    }

    return [{
      Header: 'Name',
      accessor: 'last_name',
      Cell: ({value, row}) => {
        const { id, first_name, last_name } = row.original;
        const url = `/recruiter/${id}`;
        let name = last_name || '';
        if (first_name) name += `, ${first_name}`;
        return name ? <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} underline="hover">{name}</Link> : '';
      }
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({value, row}) => {
        const { id, first_name, last_name } = row.original;
        const url = `/recruiter/${id}`;
        return (!first_name && !last_name) ? <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} underline="hover">{value}</Link> : value;
      }
    },
    {
      Header: 'Phone',
      accessor: 'phone'
    },
    {
      Header: 'Username',
      accessor: 'username'
    },
    {
      Header: 'Role',
      accessor: 'role'
    },
    {
      Header: 'Enabled',
      accessor: 'enabled',
      Cell: ({value, row}) => (!!value ? <Checked sx={{opacity:'0.3'}}/> : <Unchecked sx={{opacity:'0.3'}}/>)
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({value, row}) => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.id === value) return '';
        return <Tooltip title="Impersonate"><IconButton onClick={(event) => onImpersonate(event, row.original)}><AccountCircle/></IconButton></Tooltip>;
      }
    }];
    }, [navigate]
  );

  // 2. Call Use Table
  const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
  } = useTable({ columns, data: recruiters }, useSortBy);

  // 3. Return the HTML Table
  return (<>
    <table {...getTableProps()}>
      <thead>
          {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                  ))}
              </tr>
          ))}
      </thead>
      <tbody {...getTableBodyProps()}>
          {rows.map(
              (row) => {
                  prepareRow(row);
                  return (
                      <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                              return (
                                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              );
                          })}
                      </tr>
                  );
              }
          )}
      </tbody>
    </table>

    { recruiters?.length === 0 &&
      <p className="empty-message">No Recruiters</p>
    }
    { recruiters?.length > 0 &&
      <div className="total-count-row">
        Total Count: {" "}
        <strong>{recruiters?.length}</strong>
      </div>
    }
  </>);
}

