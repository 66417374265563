import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import PaginatedTable from './PaginatedTable';
import { env } from '../env';


export default function InvoicesTable({ invoices, fetchData, loading, totalCount, pageCount, pageSize, role, onEditInvoice }) {

  const navigate = useNavigate();
  const locale = Intl.NumberFormat().resolvedOptions().locale;

  // 1. Create Columns
  const columns = React.useMemo( () => {
    const getUTCDate = (value) => {
      if (!value) return '';
      const date = new Date(value);
      const utc = convertDateToUTC(date);
      return `${utc.toLocaleDateString(locale, {dateStyle:'medium'})}`;
    }

    const convertDateToUTC = (date) => {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    return [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({value, row}) => {
        const { id, name, employer_id } = row.original;
        const url = `${env.baseUrl}/v1/employer/${employer_id}/attachment/${id}/download`;
        return <Tooltip title={name}><Link href={url} target="_blank" rel="noreferrer" download underline="hover">{name}</Link></Tooltip>;
      }
    },
    {
      Header: 'Invoice',
      accessor: 'invoice',
    },
    {
      Header: 'Executed',
      accessor: 'execution',
      Cell: ({value, row}) => {
        if (!value) return '--';
        return getUTCDate(value);
      }
    },
    {
      Header: 'Employer',
      accessor: 'company',
      Cell: ({value, row}) => {
        const { employer_id, company } = row.original;
        const url = `/employer/${employer_id}`;
        return <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} underline="hover">{company}</Link>;
      }
    },

    {
      Header: 'Actions',
      Cell: ({row}) => {
        return <EditIcon onClick={(event) => onEditInvoice(event, row.original, row.index)} sx={{ cursor: 'pointer' }}/>;
      }
    }
      
    ]},
  [navigate, locale, onEditInvoice]
 );

  const getRowClasses = (row) => {
    let classes = '';
    return (classes) ? ({ className: classes }) : {};
  }

  // 3. Return the HTML Table
  return (<>
    <PaginatedTable
      data={invoices}
      columns={columns}
      fetchData={fetchData}
      loading={loading}
      totalCount={totalCount}
      pageCount={pageCount}
      pageSize={pageSize}
      getRowClasses={getRowClasses}
      target="Invoices"
    />
  </>);
}

