import { Component } from 'react';
import { Container } from "@mui/material";

import AddEmployer from '../components/AddEmployer';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  searchText: null,
  data: []
};

export default class AddEmployerPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
  }

  async componentDidMount() {
    const url = `${env.baseUrl}/v1/employer`;
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        return this.setState({ data: items.data});
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message || 'Unable to load employer', error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    return <>
      <Container component="div" sx={{ margin: '1rem 0' }}>
        <h1>Add Legal Employer</h1>
        <AddEmployer />
      </Container>
    </>;
  }
}
