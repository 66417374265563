import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Tooltip } from '@mui/material';
import Warning from '@mui/icons-material/Warning';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import PaginatedTable from './PaginatedTable';


export default function DoNotCallTable({ phoneNumbers, fetchData, loading, totalCount, pageCount, pageSize, onEdit, onDelete }) {

  const [data, setData] = useState(phoneNumbers);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const navigate = useNavigate();
  const locale = Intl.NumberFormat().resolvedOptions().locale;


  // After data changes, we turn the flag back off
  React.useEffect(() => {
    setData(phoneNumbers);
    setSkipPageReset(false);
  }, [phoneNumbers]);

  // 1. Create Columns
  const columns = React.useMemo( () => {
    const getUTCDate = (value) => {
      if (!value) return '';
      const date = new Date(value);
      const utc = convertDateToUTC(date);
      return `${utc.toLocaleDateString(locale, {dateStyle:'medium'})}`;
    }

    const convertDateToUTC = (date) => {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    return [
    {
      Header: 'Company/Firm',
      accessor: 'company',
      Cell: ({value, row}) => {
        const { employer_id, company } = row.original;
        const url = `/employer/${employer_id}`;
        const cname = row.original.status === 'On Hold' ? 'on-hold-link' : '';
        return <Tooltip title={company}><Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} className={cname} underline="hover">{company}</Link></Tooltip>;
      }
    },
    {
      Header: 'Coordinators',
      tipText: 'Recruiting Coordinator names & emails',
      accessor: 'email_to',
      Cell: ({value, row}) => {
        const { first_name, last_name, email_to } = row.original;
        const { first_name2, last_name2, email_to2 } = row.original;
        const { first_name3, last_name3, email_to3 } = row.original;
        const { first_name4, last_name4, email_to4 } = row.original;

        const name1 = `${first_name || ''} ${last_name || ''}`.trim();
        const name2 = `${first_name2 || ''} ${last_name2 || ''}`.trim();
        const name3 = `${first_name3 || ''} ${last_name3 || ''}`.trim();
        const name4 = `${first_name4 || ''} ${last_name4 || ''}`.trim();

        let coords = email_to ? (name1 ? `${name1} <${email_to}>` : email_to) : '';
        if (email_to2) {
          coords += email_to2 ? (name2 ? `\n${name2} <${email_to2}>` : `\n${email_to2}`) : '';
        }
        if (email_to3) {
          coords += email_to3 ? (name3 ? `\n${name3} <${email_to3}>` : `\n${email_to3}`) : '';
        }
        if (email_to4) {
          coords += email_to4 ? (name4 ? `\n${name4} <${email_to4}>` : `\n${email_to4}`) : '';
        }
        return <div className="wrap-cell">{ coords }</div>;
      }
    },
    {
      Header: 'Phone',
      accessor: 'phone'
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      Cell: ({value, row, index}) => {
        return value?.length > 64 ? value.substring(0, 63)+'...' : value;
      }
    },
    {
      Header: 'Start',
      accessor: 'start_time',
      Cell: ({value, row}) => {
        if (!value) return '--';
        return getUTCDate(value);
      }
    },
    {
       Header: 'Expiration',
       accessor: 'expiration',
       Cell: ({value, row}) => {
         if (!value) return '--';
         const expiration = new Date(value);
         const expirationDate = getUTCDate(expiration);
         const now = Date.now();
         const twoWeeks = 2*7*24*60*60*1000;

         if (expiration.getTime() < now) {
           const msg = `Expired on ${expirationDate}`;
           return <Tooltip title={msg} arrow><span style={{ display: 'flex', alignItems: 'center' }}><Warning sx={{color:'red'}}/> <strong>{expirationDate}</strong></span></Tooltip>;

         } else if (expiration.getTime() < (now + twoWeeks)) {
           const msg = `Will be expired on ${expirationDate}`;
           return <Tooltip title={msg} arrow><span style={{ display: 'flex', alignItems: 'center' }}><Warning sx={{color:'orange'}}/> <strong>{expirationDate}</strong></span></Tooltip>;
         }
         return expirationDate;
       }
     },

    {
      Header: 'Actions',
      Cell: ({value, row, index}) => {
        return <>
          <Tooltip title="Edit"><EditIcon onClick={(event) => onEdit(event, row.original, row.index)} sx={{ cursor: 'pointer' }}/></Tooltip>
          <Tooltip title="Delete"><DeleteIcon onClick={(event) => onDelete(event, row.original, row.index)} sx={{ cursor: 'pointer' }}/></Tooltip>
        </>;
      }
    },
    ]},
  [navigate, locale, onEdit, onDelete]
 );

  const getRowClasses = (row) => {
    let classes = '';
    return (classes) ? ({ className: classes }) : {};
  }

  // 3. Return the HTML Table
  return (<>
    <PaginatedTable
      data={data}
      columns={columns}
      fetchData={fetchData}
      loading={loading}
      totalCount={totalCount}
      pageCount={pageCount}
      pageSize={pageSize}
      getRowClasses={getRowClasses}
      skipPageReset={skipPageReset}
      target="Contacts"
    />
  </>);
}

