import { Component } from 'react';
import { Container } from "@mui/material";

import AddCandidate from '../components/AddCandidate';


export default class AddCandidatePage extends Component {

  render() {
    return <>
      <Container component="div" sx={{ margin: '1rem 0' }}>
        <h1>Add Candidate</h1>
        <AddCandidate />
      </Container>

    </>;
  }
}
