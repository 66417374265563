import React, { Component } from 'react';
import { Box, Button, Checkbox, Container, FormControlLabel, TextField, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import styled from "styled-components";

import { checkStatus } from '../checkStatus';
import { env } from '../env';


const H2 = styled.h2`
  margin: 1rem 0 0 !important;
`;
const H3 = styled.h3`
  margin: 1.5rem 0 0 !important;
`;
const P = styled.p`
  margin: 0 !important;
`;

export const MoreButton = (props) => (
  <Button onClick={props.onClick} disabled={props.disabled} size="small" variant="outlined" startIcon={<Add/>} >More</Button>
);
export const LessButton = (props) => (
  <Button onClick={props.onClick} disabled={props.disabled} size="small" variant="outlined" startIcon={<Remove/>} >Less</Button>
);

export default class EditEmployer extends Component {

  constructor(props) {
    super(props);

    const { name, company, office_name, first_name, last_name, description, address, city, state, zipcode, country, email_to, email_cc, email_bcc, website, phone, practice_areas, online_subs, blind_feelers, status } = this.props.employer;
    const { email_to2,first_name2,last_name2, email_to3,first_name3,last_name3, email_to4,first_name4,last_name4 } = this.props.employer;
    const { notes, offers } = this.props;

    let numEmails = 1;
    if (email_to2) numEmails++;
    if (email_to3) numEmails++;
    if (email_to4) numEmails++;

    this.state = {
      numEmails,
      name: name || '',
      company: company || '',
      office_name,

      email_to: email_to || '',
      first_name: first_name || '',
      last_name: last_name || '',

      email_to2: email_to2 || '',
      first_name2: first_name2 || '',
      last_name2: last_name2 || '',
      email_to3: email_to3 || '',
      first_name3: first_name3 || '',
      last_name3: last_name3 || '',
      email_to4: email_to4 || '',
      first_name4: first_name4 || '',
      last_name4: last_name4 || '',

      description: description || '',
      address: address || '',
      city: city || '',
      state: state || '',
      zipcode: zipcode || '',
      country: country || 'USA',
      email_cc: email_cc || '',
      email_bcc: email_bcc || '',
      website: website || '',
      phone: phone || '',
      practice_areas: practice_areas || '',
      online_subs,
      blind_feelers,
      status: status || 'Unknown',
      notes: notes || '',
      offers: offers || '',

      companyError: null,
      errorMessage: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBooleanChange = this.handleBooleanChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAddAnother = this.onAddAnother.bind(this);
    this.onRemoveCoordinator = this.onRemoveCoordinator.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  handleBooleanChange(event) {
    const { name, checked } = event.target;
    if (name)
      this.setState({ [name]: checked });
  }

  validate() {
    if (!this.state.company) {
      this.setState({ companyError: 'Company name is required' });
      return false;
    } else
      this.setState({ companyError: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      const { name, company, office_name, first_name, last_name, description, address, city, state, zipcode, country, website, phone, email_to, email_cc, email_bcc, practice_areas, online_subs, blind_feelers, status, notes, offers } = this.state;

      const url = `${env.baseUrl}/v1/employer/${this.props.employerId}`;

      const payload = {
        name: name?.trim(),
        company: company?.trim(),
        office_name: office_name?.trim(),
        first_name: first_name?.trim(),
        last_name: last_name?.trim(),
        address: address?.trim(),
        city: city?.trim(),
        state: state?.trim(),
        zipcode: zipcode?.trim(),
        country: country?.trim(),
        website: website?.trim(),
        phone: phone?.trim(),
        email_to: email_to?.trim(),
        email_cc: email_cc?.trim(),
        email_bcc: email_bcc?.trim(),

        description,
        practice_areas,
        online_subs,
        blind_feelers,
        status,
        notes,
        offers,
      };

      if (this.state.numEmails >= 2) {
        payload.first_name2 = this.state.first_name2?.trim();
        payload.last_name2 = this.state.last_name2?.trim();
        payload.email_to2 = this.state.email_to2?.trim();
      }
      if (this.state.numEmails >= 3) {
        payload.first_name3 = this.state.first_name3?.trim();
        payload.last_name3 = this.state.last_name3?.trim();
        payload.email_to3 = this.state.email_to3?.trim();
      }
      if (this.state.numEmails >= 4) {
        payload.first_name4 = this.state.first_name4?.trim();
        payload.last_name4 = this.state.last_name4?.trim();
        payload.email_to4 = this.state.email_to4?.trim();
      }

      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })
        .then(response => checkStatus(response))
        .then(value => {
          this.props.onSave({ ...payload });
        })
        .catch(error => {
          console.error(error);
          this.setState({ errorMessage: error.message, error });
        });
    }
  }

  onAddAnother() {
    this.setState({ numEmails: this.state.numEmails + 1 });
  }
  onRemoveCoordinator() {
    if (this.state.numEmails > 1) {
      this.setState({ numEmails: this.state.numEmails - 1 });
    }
  }

  render() {
    return (<>
        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField name="company" value={this.state.company} label="Company / Firm" variant="outlined" fullWidth required onChange={this.handleInputChange} error={!this.state.company} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.companyError }</Typography>

          <TextField name="name" value={this.state.name} label="Preferred Name" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <H2>Recruiting Coordinators</H2>
          <P>You can add up to four recruiting coordinators that will get sent as email to.</P>
          <Container>
            <TextField name="email_to" value={this.state.email_to} label="Email To" placeholder="Email To" variant="outlined" fullWidth required onChange={this.handleInputChange} sx={{ mb:'0.5rem' }} error={!this.state.email_to || !this.state.email_to.includes('@')} />
            <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }} disableGutters={true} >
              <TextField name="first_name" value={this.state.first_name} label="First name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
              <TextField name="last_name" value={this.state.last_name} label="Last name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
            </Container>
            <Container sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex', justifyContent:'space-between' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true}>
              <TextField name="first_name" value={this.state.first_name} label="First name" variant="outlined" sx={{ width: '48%' }} onChange={this.handleInputChange} />
              <TextField name="last_name" value={this.state.last_name} label="Last name" variant="outlined" sx={{ width: '48%', ml: '1.65rem !important' }} onChange={this.handleInputChange} />
            </Container>
          </Container>

          { this.state.numEmails > 1 &&
              <>
              <H3>Recruiting Coordinator #2</H3>
            <Container>
              <TextField name="email_to2" value={this.state.email_to2} label="Email To #2" placeholder="Email To" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ mb:'0.75rem' }} />
              <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true} >
                <TextField name="first_name2" value={this.state.first_name2} label="First name #2" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
                <TextField name="last_name2" value={this.state.last_name2} label="Last name #2" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
              </Container>
              <Container sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }, justifyContent:'space-between' }} disableGutters={true}>
                <TextField name="first_name2" value={this.state.first_name2} label="First name #2" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%' }} />
                <TextField name="last_name2" value={this.state.last_name2} label="Last name #2" variant="outlined" onChange={this.handleInputChange} sx={{ width: '49.7%', ml: '1.65rem !important' }} />
              </Container>
            </Container>
            </>
          }

          { this.state.numEmails > 2 &&
            <>
              <H3>Recruiting Coordinator #3</H3>
            <Container>
              <TextField name="email_to3" value={this.state.email_to3} label="Email To #3" placeholder="Email To" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ mb:'0.75rem' }} />
              <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true} >
                <TextField name="first_name3" value={this.state.email_to3} label="First name #3" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
                <TextField name="last_name3" value={this.state.email_to3} label="Last name #3" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
              </Container>
              <Container sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }, justifyContent:'space-between' }} disableGutters={true}>
                <TextField name="first_name3" value={this.state.email_to3} label="First name #3" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%' }} />
                <TextField name="last_name3" value={this.state.email_to3} label="Last name #3" variant="outlined" onChange={this.handleInputChange} sx={{ width: '49.7%', ml: '1.65rem !important' }} />
              </Container>
            </Container>
            </>
          }

          { this.state.numEmails > 3 &&
            <>
              <H3>Recruiting Coordinator #4</H3>
            <Container>
              <TextField name="email_to4" value={this.state.email_to4} label="Email To #4" placeholder="Email To" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ mb:'0.75rem' }} />
              <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true} >
                <TextField name="first_name4" value={this.state.first_name4} label="First name #4" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
                <TextField name="last_name4" value={this.state.last_name4} label="Last name #4" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
              </Container>
              <Container sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }, justifyContent:'space-between' }} disableGutters={true}>
                <TextField name="first_name4" value={this.state.first_name4} label="First name #4" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%' }} />
                <TextField name="last_name4" value={this.state.last_name4} label="Last name #4" variant="outlined" onChange={this.handleInputChange} sx={{ width: '49.7%', ml: '1.65rem !important' }} />
              </Container>
            </Container>
            </>
          }

          <Container sx={{ display:'flex', gap:'0.5rem', mt:'1rem !important', mb:'2rem !important' }} disableGutters={true}>
            <MoreButton onClick={this.onAddAnother} disabled={this.state.numEmails >= 4} />
            <LessButton onClick={this.onRemoveCoordinator} disabled={this.state.numEmails <= 1} />
          </Container>

          <TextField name="email_cc" value={this.state.email_cc} label="Email CC" placeholder="Email CC (comma seperated)" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="email_bcc" value={this.state.email_bcc} label="Email BCC" placeholder="Email BCC (comma seperated)" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <TextField name="website" value={this.state.website} label="Website" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="phone" value={this.state.phone} label="Phone" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="address" value={this.state.address} label="Address" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="city" value={this.state.city} label="City" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="state" value={this.state.state} label="State" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="zipcode" value={this.state.zipcode} label="Zipcode" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="country" value={this.state.country} label="Country" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="practice_areas" value={this.state.practice_areas} label="Practice Areas" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <div><FormControlLabel control={<Checkbox name="online_subs" checked={this.state.online_subs} onChange={this.handleBooleanChange}/>} label="Online Subs?" /></div>
          <div><FormControlLabel control={<Checkbox name="blind_feelers" checked={this.state.blind_feelers} onChange={this.handleBooleanChange}/>} label="Blind Feelers?" /></div>

          <TextField name="description" value={this.state.description} label="Description" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="notes" value={this.state.notes} label="Notes" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="offers" value={this.state.offers} label="Offers" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />

          <Container sx={{ display: 'flex', gap: '0.5rem' }} disableGutters={true}>
            <Button type="submit" variant="contained">Save</Button>
            <Button type="button" variant="outlined" onClick={this.props.onCancel}>Cancel</Button>
          </Container>
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
        </Box>
    </>);
  }
}

