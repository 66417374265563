import React, { Component } from 'react';
import { Box, Button, CircularProgress, Container, InputAdornment, TextField, Typography } from "@mui/material";
import { red } from '@mui/material/colors';
import { Clear } from '@mui/icons-material';
import styled from "styled-components";

import CandidatesTable from '../components/CandidatesTable';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  searchText: '',
  searchPAText: '',
  loading: true,
  totalCount: 0,
  pageIndex: 0,
  pageSize: 50,
  pageCount: 0,
  candidates: [],
  errorMessage: null,
};

const H1 = styled.h1`
  margin: 0;
`;

const SearchForm = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0;
`;

export default class CandidatesPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSearchCandidates = this.onSearchCandidates.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onClearPASearch = this.onClearPASearch.bind(this);
    this.query = this.query.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  query({ pageSize, pageIndex }) {
    this.setState({ pageSize, pageIndex, loading: true });
    let url = `${env.baseUrl}/v1/candidate`;
    let queryParams = '';
    if (this.state.searchText) {
      if (queryParams) queryParams += '&';
      queryParams += `search=${this.state.searchText}`;
    }
    if (this.state.searchPAText) {
      if (queryParams) queryParams += '&';
      queryParams += `searchPracticeAreas=${this.state.searchPAText}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        const totalCount = items.data.length ? items.data[0].count : 0;
        this.setState({ totalCount, pageCount: Math.ceil(totalCount / this.state.pageSize) });
        this.setState({ errorMessage: '' });
        this.setState({ candidates: items.data});
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: 'Unable to get candidates' });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onSearchCandidates(event) {
    if (event) event.preventDefault();
    const { pageSize, pageIndex } = this.state;
    this.query({ pageSize, pageIndex });
  }

  onClearSearch() {
    this.setState({ searchText: '' }, this.onSearchCandidates);
  }

  onClearPASearch() {
    this.setState({ searchPAText: '' }, this.onSearchCandidates);
  }

  render() {
    return <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', width: '100%', margin: '1rem 0' }} maxWidth={false}>
        <H1>Candidates</H1>

        <SearchForm>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={this.onSearchCandidates}
            sx={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '30rem' }}
          >
            <TextField name="searchText" value={this.state.searchText} label="Search Candidates by Name/Email" variant="outlined" fullWidth size="small" onChange={this.handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Clear onClick={this.onClearSearch} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                )
              }}
            />
            <Button type="submit" variant="contained">Search</Button>
          </Box>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={this.onSearchCandidates}
            sx={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '30rem' }}
          >
            <TextField name="searchPAText" value={this.state.searchPAText} label="Search Candidates by Practice Areas" variant="outlined" fullWidth size="small" onChange={this.handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Clear onClick={this.onClearPASearch} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                )
              }}
            />
            <Button type="submit" variant="contained">Search</Button>
          </Box>
        </SearchForm>
      </Container>

      <Container sx={{ pb: '2rem', textAlign: 'left' }} maxWidth={false}>
        <CandidatesTable
          candidates={this.state.candidates}
          loading={this.state.loading}
          fetchData={this.query}
          totalCount={this.state.totalCount}
          pageCount={this.state.pageCount}
          pageSize={this.state.pageSize}
          includeRecruiter={true}
          includePracticeAreas={true}
        />
        <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
        { this.state.loading &&
          <Container sx={{ m: '2rem' }} disableGutters={true}>
            <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
            Loading Candidates...
          </Container>
        }
      </Container>
    </>;
  }
}

