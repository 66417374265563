import React, { Component } from 'react';
import { Container } from "@mui/material";


export default class About extends Component {
  render() {
    return <section id="about" className="about">
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0', lg: '2rem 0', xl: '2rem 0' },
        margin: '0 auto',
        textAlign: 'left'
      }}>

        <h1>About Lateral Match Tools</h1>
        <p>Version: 1.0.0</p>
        <p>These are tools used by our Recruiters to facilitate placing our Candidates.</p>

      </Container>
    </section>;

  }
}

