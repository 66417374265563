import { Component } from 'react';
import { useParams } from 'react-router';
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { ThumbUp, ThumbDown, Warning } from '@mui/icons-material';
import { amber, green, red } from '@mui/material/colors';

import { env } from '../env';
import { checkStatus, goToLogin } from '../checkStatus';


const defaultState = {
  current_firm: null,
  signup_source: null,
  signup_completed_on: null,
  preferred_locations: null,
  interested_locations: null,
  first_name: null,
  last_name: null,
  description: null,
  address: null,
  city: null,
  state: null,
  zipcode: null,
  country: 'USA',
  email: null,
  phone: null,
  law_school: null,
  graduated_on: null,
  status: 'Unknown',
  role: null,
  notes: null,

  forward: null,
  errorMessage: null,
  emailError: null,
  companyError: null,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class AddCandidate extends Component {
 
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBooleanChange = this.handleBooleanChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isEmailInvalid = this.isEmailInvalid.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  handleBooleanChange(event) {
    const { name, checked } = event.target;
    if (name)
      this.setState({ [name]: checked });
  }

  validate() {
    if (!this.state.email) {
      this.setState({ emailError: 'Email is required' });
      if (this.state.email === null) this.setState({ email: '' });
      return false;
    } else
      this.setState({ emailError: null });

    const reg = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (reg.test(this.state.email) === false) {
      this.setState({ emailError: 'Email is invalid' });
      return false;
    } else
      this.setState({ emailError: null });

    if (!this.state.current_firm) {
      this.setState({ companyError: 'Current Company/Firm is required' });
      if (this.state.current_firm === null) this.setState({ current_firm: '' });
      return false;
    } else
      this.setState({ companyError: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      const {
        current_firm,
        signup_source,
        signup_completed_on,
        preferred_locations,
        interested_locations,
        first_name,
        last_name,
        description,
        company,
        address,
        city,
        state,
        country,
        phone,
        email,
        law_school,
        graduated_on,
        status,
        role,
        notes,
      } = this.state;

      const signupCompletedOn = signup_completed_on ? new Date(signup_completed_on).toISOString() : null;

      const payload = {
        current_firm,
        signup_source,
        signup_completed_on: signupCompletedOn,
        preferred_locations,
        interested_locations,
        company,
        first_name: first_name?.trim(),
        last_name: last_name?.trim(),
        address: address?.trim(),
        city: city?.trim(),
        state: state?.trim(),
        country: country?.trim(),
        phone: phone?.trim(),
        email: email?.trim(),
        description,
        law_school,
        graduated_on,
        status,
        role,
        notes,
      };

      const recruiterId = this.props.params.id;
      const url = `${env.baseUrl}/v1/recruiter/${recruiterId}/candidate`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
        credentials:'include',
      })
      .then(result => checkStatus(result))
      .then(items => {
        this.setState({ forward: `/home` });
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message || 'Add candidate failed', error });
      });
    }
  }

  isEmailInvalid(email) {
    return this.state.email==='' || (this.state.email && !this.state.email.includes('@'));
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }
    const bgClass = this.props.bg || 'none';
    return <section id="add-candidate" className={bgClass}>
      <Container sx={{
        maxWidth: { xs: 'calc(100% - 1rem)', sm: 'calc(100% - 1rem)', md: 800, lg: 800, xl: 800 },
        margin: { xs: '0 0.5rem', sm: '0 0.5rem', md: '0 auto', lg: '0 auto', xl: '0 auto' },
        pb: '2rem',
      }} disableGutters={true}>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField name="email" label="Email" variant="outlined" fullWidth required onChange={this.handleInputChange} error={this.isEmailInvalid()} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.emailError }</Typography>

          <TextField name="current_firm" label="Current Company / Firm" variant="outlined" fullWidth required onChange={this.handleInputChange} error={this.state.current_firm === ''} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.companyError }</Typography>

          <TextField name="signup_source" label="Sign Up Source" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="signup_completed_on" label="Sign Up Completed On" type="date" InputLabelProps={{shrink: true}} variant="outlined" fullWidth onChange={this.handleInputChange} />

          <TextField name="preferred_locations" label="Preferred Locations" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="interested_locations" label="Interested Locations" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true} >
            <TextField name="first_name" label="First name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
            <TextField name="last_name" label="Last name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
          </Container>

          <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }} disableGutters={true}>
            <TextField name="first_name" label="First name" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%' }} />
            <TextField name="last_name" label="Last name" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%', ml: '1.65rem !important' }} />
          </Container>

          <TextField name="address" label="Address" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="city" label="City" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="state" label="State/Province" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="country" label="Country" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="phone" label="Phone" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="law_school" label="Law School" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="graduated_on" label="Graduated On" type="number" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="role" label="Role" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <FormControl variant="standard" sx={{ m: 1, minWidth: '10rem' }}>
            <InputLabel id="status-label" className="select-label" sx={{ ml: '1rem' }}>Status</InputLabel>
            <Select
              name="status"
              labelId="status-label"
              variant="outlined" fullWidth onChange={this.handleInputChange}
              value={this.state.status}
            >
              <MenuItem value="Unknown"><Unchecked sx={{opacity:'0.5', mr: '0.25rem'}}/> Unknown</MenuItem>
              <MenuItem value="Need to Contact"><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> Need to Contact</MenuItem>
              <MenuItem value="Contacted"><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> Contacted</MenuItem>
              <MenuItem value="Blind Submission"><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> Blind Submission</MenuItem>
              <MenuItem value="Sent Firms"><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> Sent Firms</MenuItem>
              <MenuItem value="Submitted"><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> Submitted</MenuItem>
              <MenuItem value="Interview"><Checked sx={{opacity:'0.5', mr: '0.25rem' }}/> Interview</MenuItem>
              <MenuItem value="Offer"><Checked sx={{opacity:'0.5', mr: '0.25rem' }}/> Offer</MenuItem>
              <MenuItem value="Offer Declined"><ThumbDown sx={{opacity:'0.5', color: red[900], mr: '0.25rem' }}/> Offer Declined</MenuItem>
              <MenuItem value="Hired"><ThumbUp sx={{color: green[600], mr: '0.25rem' }}/> Hired</MenuItem>
              <MenuItem value="Needs Follow Up"><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> Needs Follow Up</MenuItem>
              <MenuItem value="Followed Up"><ThumbUp sx={{color: green[600], mr: '0.25rem' }}/> Followed Up</MenuItem>
            </Select>
          </FormControl>

          <TextField name="description" label="Description" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="notes" label="Notes" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', color: red[900] }}>
            <Button type="submit" variant="contained">Add</Button>
            <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
            <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.emailError }</Typography>
            <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.companyError }</Typography>
          </Box>
        </Box>

      </Container>

    </section>;

  }
}

export default withParams(AddCandidate);

