import { Component } from 'react';
import { Box, Button, CircularProgress, Container, InputAdornment, TextField, Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { Clear } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import styled from 'styled-components';

import InvoicesTable from '../components/InvoicesTable';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  searchText: '',
  loading: true,
  totalCount: 0,
  pageIndex: 0,
  pageSize: 25,
  pageCount: 0,
  showUpload: false,
  attachButtonText: 'Add Attachment',
  invoices: [],
  totalSize: 0,
  forward: null,
  errorMessage: null,
};

const H1 = styled.h1`
  margin-top: 1rem;
`;
const SearchForm = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

export default class InvoicesPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.query = this.query.bind(this);
    this.onShowUpload = this.onShowUpload.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onEditInvoice = this.onEditInvoice.bind(this);
    this.onDeleteInvoice = this.onDeleteInvoice.bind(this);
  }

  async componentDidMount() {
    // Get the recruiter's role to use for permissions
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({ user });

    const { pageSize, pageIndex } = this.state; 
    this.query({ pageSize, pageIndex });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  query({ pageSize, pageIndex }) {
    this.setState({ pageSize, pageIndex, loading: true });
    let url = `${env.baseUrl}/v1/invoice`;
    let queryParams = '';
    if (this.state.searchText) {
      if (queryParams) queryParams += '&';
      queryParams += `search=${this.state.searchText}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(result => {
        if (result.data) {
          const invoices = result.data;
          const totalCount = invoices?.length || 0;
          const pageCount = Math.ceil(totalCount / this.state.pageSize);
          this.setState({ invoices, totalCount, pageCount, errorMessage: '' });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message || 'Unable to load invoices', error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onShowUpload() {
    const attachButtonText = this.state.showUpload ? 'Add Invoice' : 'Hide Add Invoice';
    this.setState({ showUpload: !this.state.showUpload, attachButtonText });
  }

  onFilesAdded(files) {
    this.setState({ files });

    const file = files[0];
    const formData = new FormData();
    formData.append('file', file);

    const employerId = 'c2010140-1d81-4214-a60c-346bbe66f145';

    const url = `${env.baseUrl}/v1/employer/${employerId}/attach`;
    fetch(url, {
      method: 'POST',
      body: formData,
      credentials:'include',
    })
      .then(result => checkStatus(result))
      .then(response => {
        const { id, name, size, type } = response;
        const attachment = { id, name, size, type };

        const attachments = [...this.state.attachments];
        attachments.push(attachment);

        const totalAttachmentsSize = this.state.totalAttachmentsSize + file.size;
        this.setState({ attachments, totalAttachmentsSize, showUpload: false, attachButtonText: 'Add Attachment' });
      })
      .catch(error => {
        console.error(error);
      });
  }

  onEditInvoice(event, attachment, index) {
    this.setState({ forward: `/invoice/${attachment.id}` });
  }

  onDeleteInvoice(event, attachment, index) {
    event.preventDefault();
    //const attachment = this.state.attachments?.length ? this.state.attachments[index] : null;
    if (!attachment) {
      console.error(`Unable to delete attachment @ ${index}`);
      return;
    }
    const url = `${env.baseUrl}/v1/invoice/${this.state.invoiceId}`;

    fetch(url, {
      method: 'DELETE',
      body: '{}',
      headers:{ 'Content-Type':'application/json' },
      credentials: 'include',
    })
    .then(response => checkStatus(response))
    .then(response => {
      if (response.rowCount === 1) {
        this.setSuccessMessage('Attachment deleted');
        const invoices = [...this.state.invoices];
        invoices.splice(index, 1);

        const totalSize = this.state.totalSize - invoices.size;
        this.setState({ invoices, totalSize, showUpload: false, attachButtonText: 'Add Invoice' });
      } else
        this.setState({ errorMessage: 'Unable to delete invoice' });
    })
    .catch(error => {
      console.error(error);
      this.setState({ errorMessage: 'Delete attachment failed' });
    });
  }

  onSearch(event) {
    if (event) event.preventDefault();
    const { pageSize, pageIndex } = this.state;
    this.query({ pageSize, pageIndex });
  }

  onClearSearch() {
    this.setState({ searchText: '' }, this.onSearch);
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }

    return <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '1rem 0' }} maxWidth={false}>
        <H1>Invoices</H1>

        <SearchForm>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={this.onSearch}
            sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
          >
            <TextField name="searchText" value={this.state.searchText} label="Search by Name" variant="outlined" fullWidth onChange={this.handleInputChange}
                sx={{ minWidth: '15rem' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Clear onClick={this.onClearSearch} sx={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  )
                }}
            />
            <Button type="submit" variant="contained" sx={{ padding: '0.5rem 2rem' }}>Search</Button>
          </Box>
        </SearchForm>
      </Container>

      <Container sx={{ mt: '1rem', pb: '2rem', textAlign: 'left' }} maxWidth={false}>

        <InvoicesTable
          invoices={this.state.invoices}
          loading={this.state.loading}
          fetchData={this.query}
          pageCount={this.state.pageCount}
          pageSize={this.state.pageSize}
          totalCount={this.state.totalCount}
          role={this.state.user?.role}
          onEditInvoice={this.onEditInvoice}
        />

        { this.state.loading && 
          <Container sx={{ m: '2rem' }} disableGutters={true}>
            <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
            Loading Invoices...
          </Container>
        }
        <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
        <Typography>You can add invoices as an Attachment on the Employer page with Attachment Category of <i>Invoice</i>.</Typography>

      </Container>
    </>;
  }
}

