import { Component } from 'react';
import { Box, Button, CircularProgress, Container, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { red } from '@mui/material/colors';
import styled from "styled-components";

import RecruitersTable from '../components/RecruitersTable';
import RecruitersStatsTable from '../components/RecruitersStatsTable';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  loading: true,
  searchText: null,
  data: [],
  stats: [],
};

const H1 = styled.h1`
  margin: 0;
`;
const H2 = styled.h2`
  margin: 2rem 0 0;
`;

const SearchForm = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const AddRecruiterButton = () => {
  const navigate = useNavigate();

  return (
    <Button onClick={()=> navigate('/add-recruiter')} variant="outlined" sx={{ mt: '1rem' }}>Add New Recruiter</Button>
  )
}

export default class RecruitersPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  async componentDidMount() {
    this.query();
    this.getStats();
  }

  query() {
    let url = `${env.baseUrl}/v1/recruiter`;
    if (this.state.searchText) {
      url += `?search=${this.state.searchText}`;
    }
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(items => {
        this.setState({ data: items.data});
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message || 'Unable to load recuiter' });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getStats() {
    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const start = firstDay.toISOString();
    const end = lastDay.toISOString();
    const statsUrl = `${env.baseUrl}/v1/recruiter-stats?start=${start}&end=${end}`;
    fetch(statsUrl, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(result => {
        return this.setState({ stats: result.data });
      })
      .catch(err => {
        console.error(err);
        this.setState({ errorMessage: err.message });
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.query();
  }

  render() {
    if (this.state.loading) {
      return <Container sx={{ m: '2rem' }}>
        <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
        Loading Recruiters...
      </Container>;
    }

    return <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '1rem 0' }} maxWidth={false}>
        <H1>Recruiters</H1>

        <SearchForm>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={this.handleSubmit}
            sx={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '30rem' }}
          >
            <TextField name="searchText" label="Search Recruiters by Name/Email" variant="outlined" fullWidth onChange={this.handleInputChange} />
            <Button type="submit" variant="contained">Search</Button>
          </Box>
        </SearchForm>
      </Container>

      <Container sx={{ mt:'1rem', pb:'2rem', textAlign:'left' }} maxWidth={false}>
        <RecruitersTable recruiters={this.state.data} />
        <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
        <AddRecruiterButton />

        <H2>Recruiters Stats</H2>
        <RecruitersStatsTable stats={this.state.stats} sx={{ margin:'0 2rem 2rem' }} />
      </Container>
    </>;
  }
}

