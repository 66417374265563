import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { amber } from '@mui/material/colors';
import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Protected from './Protected';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';

import EmployersPage from './pages/EmployersPage';
import EmployerPage from './pages/EmployerPage';
import AddEmployerPage from './pages/AddEmployerPage';

import RecruitersPage from './pages/RecruitersPage';
import RecruiterPage from './pages/RecruiterPage';
import AddRecruiterPage from './pages/AddRecruiterPage';

import CandidatesPage from './pages/CandidatesPage';
import CandidatePage from './pages/CandidatePage';
import AddCandidatePage from './pages/AddCandidatePage';
import SubmitCandidatePage from './pages/SubmitCandidatePage';

import AttachmentPage from './pages/AttachmentPage';

import InvoicesPage from './pages/InvoicesPage';
import EmplAttachmentPage from './pages/EmplAttachmentPage';
import AddEmplAttachmentPage from './pages/AddEmplAttachmentPage';

import SubmissionPage from './pages/SubmissionPage';

import OfficePage from './pages/OfficePage';
import AddOfficePage from './pages/AddOfficePage';

import ExclusiveListPage from './pages/ExclusiveListPage';
import ExclusivePage from './pages/ExclusivePage';

import DoNotContactListPage from './pages/DoNotContactListPage';
import DoNotContactPage from './pages/DoNotContactPage';

import SearchPage from './pages/SearchPage';
import JobPage from './pages/JobPage';
import AboutPage from './pages/AboutPage';
import NotFoundPage from './pages/NotFoundPage';
import MyAccountPage from './pages/MyAccountPage';
import ChangePasswordPage from './pages/ChangePasswordPage';

import CacheBuster from './components/CacheBuster';
import Header from './components/Header';

import useToken from './useToken';


const theme = createTheme({
  palette: {
    primary: {
      main: '#1664a0'
    },
    secondary: {
      main: amber[600]
    }
  }
});

export default function App() {
  const { getToken, setToken } = useToken();
  const location = useLocation();

  useEffect(() => {
    document.title = 'Lateral Match Tools';

    const path = window.location.pathname;
    if (path.startsWith('/candidate')) {
      document.title = 'Candidates | Lateral Match Tools';
    } else if (path.startsWith('/recruiter')) {
      document.title = 'Recruiters | Lateral Match Tools';
    } else if (path.startsWith('/employer')) {
      document.title = 'Employers | Lateral Match Tools';
    } else if (path.startsWith('/office')) {
      document.title = 'Offices | Lateral Match Tools';
    } else if (path.startsWith('/search')) {
      document.title = 'Search | Lateral Match Tools';
    } else if (path.startsWith('/job')) {
      document.title = 'Jobs | Lateral Match Tools';
    }
  }, [location]);

  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;
  const role = user?.role;
  const isAdmin = role === 'Manager' || role === 'Administrator';

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
      }
    
      return (
    <ThemeProvider theme={theme}>
      { !!getToken() &&
        <Header />
      }

      <Routes>
        <Route exact path='/' element={<LoginPage setToken={setToken} />} />
        <Route path='/login' element={<LoginPage setToken={setToken} />} />

        <Route path='/home' element={<Protected isLoggedIn={getToken}><HomePage/></Protected>} />
        <Route path='/about' element={<Protected isLoggedIn={getToken}><AboutPage/></Protected>} />
        <Route path='/my-account' element={<Protected isLoggedIn={getToken}><MyAccountPage/></Protected>} />
        <Route path='/change-password' element={<Protected isLoggedIn={getToken}><ChangePasswordPage/></Protected>} />

        <Route path='/candidates' element={<Protected isLoggedIn={getToken}><CandidatesPage/></Protected>} />
        <Route path='/candidate/:id' element={<Protected isLoggedIn={getToken}><CandidatePage/></Protected>} />

        <Route path='/blind-submit-candidate/:id' element={<Protected isLoggedIn={getToken}><SubmitCandidatePage submitType="blindSubmission" /></Protected>} />
        <Route path='/submit-candidate/:id' element={<Protected isLoggedIn={getToken}><SubmitCandidatePage submitType="submitCandidate" /></Protected>} />
        <Route path='/follow-up-candidate/:id' element={<Protected isLoggedIn={getToken}><SubmitCandidatePage submitType="followUp" /></Protected>} />

        <Route path='/attachment/:id' element={<Protected isLoggedIn={getToken}><AttachmentPage/></Protected>} />

        <Route path='/employers' element={<Protected isLoggedIn={getToken}><EmployersPage/></Protected>} />
        <Route path='/employer/:id' element={<Protected isLoggedIn={getToken}><EmployerPage/></Protected>} />
        <Route path='/employer/:id/add-empl-attachment' element={<Protected isLoggedIn={getToken}><AddEmplAttachmentPage/></Protected>} />

        <Route path='/empl-attachment/:id' element={<Protected isLoggedIn={getToken}><EmplAttachmentPage/></Protected>} />
        <Route path='/add-employer' element={<Protected isLoggedIn={getToken}><AddEmployerPage/></Protected>} />

        <Route path='/submission/:id' element={<Protected isLoggedIn={getToken}><SubmissionPage/></Protected>} />

        <Route path='/office/:id' element={<Protected isLoggedIn={getToken}><OfficePage/></Protected>} />
        <Route path='/employer/:id/add-office' element={<Protected isLoggedIn={getToken}><AddOfficePage/></Protected>} />

        <Route path='/exclusive/:id' element={<Protected isLoggedIn={getToken}><ExclusivePage/></Protected>} />
        <Route path='/exclusive' element={<Protected isLoggedIn={getToken}><ExclusiveListPage/></Protected>} />

        <Route path='/do-not-contact/:id' element={<Protected isLoggedIn={getToken}><DoNotContactPage/></Protected>} />
        <Route path='/do-not-contact' element={<Protected isLoggedIn={getToken}><DoNotContactListPage/></Protected>} />

        <Route path='/recruiter/:id/add-candidate' element={<Protected isLoggedIn={getToken}><AddCandidatePage/></Protected>} />

        { isAdmin && 
          <>
            <Route path='/recruiters' element={<Protected isLoggedIn={getToken}><RecruitersPage/></Protected>} />
            <Route path='/recruiter/:id' element={<Protected isLoggedIn={getToken}><RecruiterPage/></Protected>} />
            <Route path='/add-recruiter' element={<Protected isLoggedIn={getToken}><AddRecruiterPage/></Protected>} />

            <Route path='/invoice/:id' element={<Protected isLoggedIn={getToken}><EmplAttachmentPage/></Protected>} />
            <Route path='/invoices' element={<Protected isLoggedIn={getToken}><InvoicesPage/></Protected>} />

            <Route path='/search' element={<Protected isLoggedIn={getToken}><SearchPage/></Protected>} />
            <Route path='/job/:id' element={<Protected isLoggedIn={getToken}><JobPage/></Protected>} />
          </>
        }

        <Route path='*' element={<NotFoundPage/>} />
      </Routes>
    </ThemeProvider>
        );
      }}
    </CacheBuster>
  );
}

