import React, { Component } from 'react';
import { Alert, CircularProgress, Container } from '@mui/material';
import { Navigate } from "react-router-dom";
import styled from "styled-components";

import MyCandidates from '../components/MyCandidates';
import ExpiredAttachments from '../components/ExpiredAttachments';

import { env } from '../env';
import { checkStatus, goToLogin } from '../checkStatus';


const defaultState = {
  loading: true,
  forward: null,
  recruiter: null,
  statuses: [],
  candidates: [],
  totalCount: 0,
  pageIndex: 0,
  pageSize: 50,
  pageCount: 0,
  searchText: '',

  hiredTotalCount: 0,
  hiredPageCount: 0,
  hiredLoading: false,
  hiredErrorMessage: '',
  hiredCandidates: [],
  hiredSearchText: '',

  inactiveTotalCount: 0,
  inactivePageCount: 0,
  inactiveLoading: false,
  inactiveErrorMessage: '',
  inactiveCandidates: [],
  inactiveSearchText: '',

  errorMessage: null,
  alertMessage: null,
  alertType: 'error',
};

const H1 = styled.h1`
  margin-bottom: 0;
`;
const H2 = styled.h2`
  margin: 0;
`;

export default class Home extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.onAttachments = this.onAttachments.bind(this);
    this.getMyCandidates = this.getMyCandidates.bind(this);
    this.getHiredCandidates = this.getHiredCandidates.bind(this);
    this.getInactiveCandidates = this.getInactiveCandidates.bind(this);
    this.setStatuses = this.setStatuses.bind(this);
    this.setSearchText = this.setSearchText.bind(this);
    this.setHiredSearchText = this.setHiredSearchText.bind(this);
    this.setInactiveSearchText = this.setInactiveSearchText.bind(this);
  }

  componentDidMount() {
    this.getRecruiter();
  }

  getRecruiter() {
    const recruiterUrl = this.getRecruiterUrl();
    fetch(recruiterUrl, { method: 'GET', headers: { 'Content-Type': 'application/json' }, credentials: 'include'})
      .then(result => checkStatus(result))
      .then(items => {
        if (items.data?.length) {
          const [recruiter] = items.data;
          this.setState({ recruiter });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message || 'Unable to load recruiter', error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getRecruiterUrl() {
    const user = JSON.parse(localStorage.getItem('user'));
    return `${env.baseUrl}/v1/recruiter/${user.id}`;
  }

  getMyCandidatesUrl(recruiterId, pageIndex, pageSize) {
    let url = `${env.baseUrl}/v1/recruiter/${recruiterId}/my-candidate`;
    let queryParams = '';
    if (this.state.searchText) {
      if (queryParams) queryParams += '&';
      queryParams += `search=${this.state.searchText}`;
    }
    const statuses = Object.keys(this.state.statuses);
    if (statuses.length) {
      if (queryParams) queryParams += '&';
      queryParams += `status=${statuses.join(',')}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    return url;
  }

  async getMyCandidates({ pageSize, pageIndex }) {
    this.setState({ pageSize, pageIndex });

    const user = JSON.parse(localStorage.getItem('user'));

    const url = this.getMyCandidatesUrl(user.id, pageIndex, pageSize);
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data?.length) {
          const totalCount = result.data[0].count;
          this.setState({ totalCount, pageCount: Math.ceil(totalCount / this.state.pageSize) });
          this.setState({ loading: false, errorMessage: '' });
          this.setState({ candidates: result.data });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message });
      });
  }

  getHiredCandidatesUrl(recruiterId, pageIndex, pageSize) {
    let url = `${env.baseUrl}/v1/recruiter/${recruiterId}/hired-candidate`;
    let queryParams = '';
    if (this.state.hiredSearchText) {
      if (queryParams) queryParams += '&';
      queryParams += `search=${this.state.hiredSearchText}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    return url;
  }

  async getHiredCandidates({ pageSize, pageIndex }) {
    this.setState({ hiredPageSize: pageSize, hiredPageIndex: pageIndex });

    const user = JSON.parse(localStorage.getItem('user'));

    const url = this.getHiredCandidatesUrl(user.id, pageIndex, pageSize, 'hired-candidate');
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data?.length) {
          const hiredTotalCount = result.data[0].count;
          this.setState({ hiredTotalCount, hiredPageCount: Math.ceil(hiredTotalCount / this.state.hiredPageSize) });
          this.setState({ hiredLoading: false, hiredErrorMessage: '' });
          this.setState({ hiredCandidates: result.data });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ hiredErrorMessage: error.message });
      });
  }

  getInactiveCandidatesUrl(recruiterId, pageIndex, pageSize) {
    let url = `${env.baseUrl}/v1/recruiter/${recruiterId}/inactive-candidate`;
    let queryParams = '';
    if (this.state.inactiveSearchText) {
      if (queryParams) queryParams += '&';
      queryParams += `search=${this.state.inactiveSearchText}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    return url;
  }

  async getInactiveCandidates({ pageSize, pageIndex }) {
    this.setState({ inactivePageSize: pageSize, inactivePageIndex: pageIndex });

    const user = JSON.parse(localStorage.getItem('user'));

    const url = this.getInactiveCandidatesUrl(user.id, pageIndex, pageSize, 'inactive-candidate');
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then((result) => {
        if (result.data?.length) {
          const inactiveTotalCount = result.data[0].count;
          this.setState({ inactiveTotalCount, inactivePageCount: Math.ceil(inactiveTotalCount / this.state.inactivePageSize) });
          this.setState({ inactiveLoading: false, inactiveErrorMessage: '' });
          this.setState({ inactiveCandidates: result.data });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ inactiveErrorMessage: error.message });
      });
  }

  setStatuses(statuses) {
    this.setState({ statuses });
  }

  setSearchText(searchText) {
    this.setState({ searchText });
  }

  setHiredSearchText(hiredSearchText) {
    this.setState({ hiredSearchText });
  }

  setInactiveSearchText(inactiveSearchText) {
    this.setState({ inactiveSearchText });
  }

  onAttachments(attachments) {
    if (attachments.length) {
      for (const att of attachments) {
        if (att.expired) {
          this.setState({ alertType: 'error', alertMessage: 'Expired Employer Attachments exist' });
          return;
        }
      }
      this.setState({ alertType: 'warning', alertMessage: 'Employer Attachments will expire within two weeks' });
    }
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }
    if (this.state.loading) {
      return <Container sx={{ m: '2rem' }}>
        <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
        Loading My Candidates...
      </Container>;
    }

    const r = this.state.recruiter;
    //console.log('recruiter: ', r);

    return (<>
      { r.role !== 'Recruiter' && this.state.alertMessage &&
        <Alert severity={this.state.alertType || 'error'} onClose={() => this.setState({ alertMessage:'' })}>{this.state.alertMessage}</Alert>
      }

      <H1>Lateral Match Tools</H1>

      { r &&
        <H2>{r.first_name} {r.last_name}</H2>
      }

      <MyCandidates
        title="My Candidates"
        type="Candidates"
        loading={this.state.loading}
        candidates={this.state.candidates}
        recruiterId={this.state.recruiter?.id}
        totalCount={this.state.totalCount}
        pageCount={this.state.pageCount}
        pageSize={this.state.pageSize}
        setStatuses={this.setStatuses}
        setSearchText={this.setSearchText}
        fetchData={this.getMyCandidates}
        includePracticeAreas={true}
        sx={{ width: '100%' }}
      />

      <MyCandidates
        title="My Hired Candidates"
        type="Hired Candidates"
        loading={this.state.hiredLoading}
        candidates={this.state.hiredCandidates}
        recruiterId={this.state.recruiter?.id}
        totalCount={this.state.hiredTotalCount}
        pageCount={this.state.hiredPageCount}
        pageSize={this.state.hiredPageSize}
        setSearchText={this.setHiredSearchText}
        fetchData={this.getHiredCandidates}
        includePracticeAreas={true}
        sx={{ width: '100%' }}
      />

      <MyCandidates
        title="My Inactive Candidates"
        type="Inactive Candidates"
        loading={this.state.inactiveLoading}
        candidates={this.state.inactiveCandidates}
        recruiterId={this.state.recruiter?.id}
        totalCount={this.state.inactiveTotalCount}
        pageCount={this.state.inactivePageCount}
        pageSize={this.state.inactivePageSize}
        setSearchText={this.setInactiveSearchText}
        fetchData={this.getInactiveCandidates}
        sx={{ width: '100%' }}
      />

      { r.role !== 'Recruiter' &&
      <ExpiredAttachments sx={{ width: '100%' }} onAttachments={this.onAttachments} />
      }

      { r.role !== 'Recruiter' && this.state.alertMessage &&
        <Alert severity={this.state.alertType || 'error'} onClose={() => this.setState({ alertMessage:'' })}>{this.state.alertMessage}</Alert>
      }
    </>);
  }
}
