import { Component } from 'react';
import { Button, CircularProgress, Container, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { Navigate } from "react-router-dom";
import { useParams } from "react-router";
import styled from "styled-components";

import MyAccount from '../components/MyAccount';
import EditMyAccount from '../components/EditMyAccount';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const H1 = styled.h1`
  margin: 0;
`;
const H2 = styled.h2`
  margin: 0 0 0 1.5rem;
`;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const defaultState = {
  loading: true,
  recruiter: null,
  notes: '',
  role: 'Recruiter',
  editMode: false,
  anchorEL: null,
  open: false,
  openDeleteDialog: false,
  errorMessage: null,
};

class MyAccountPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.openActionsMenu = this.openActionsMenu.bind(this);
    this.closeActionsMenu = this.closeActionsMenu.bind(this);
    this.onSaveRecruiter = this.onSaveRecruiter.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onUpdateRecruiter = this.onUpdateRecruiter.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      console.error('Not logged in');
      return;
    }
    const recruiterId = user.id;

    const url = `${env.baseUrl}/v1/recruiter/${recruiterId}`;
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        const [recruiter] = data;
        const notes = recruiter.notes || '';
        return this.setState({ recruiter, notes, role: recruiter.role });
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message, error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  setSuccessMessage(msg) {
    this.setState({ successMessage: msg, errorMessage: '' });
    setTimeout(() => this.setState({ successMessage: '' }), 5000);
  }

  openActionsMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ open: true, anchorEL: event.currentTarget });
  }

  closeActionsMenu() {
    this.setState({ open: false, anchorEL: null });
  }

  onUpdateRecruiter() {
    this.setState({ editMode: true });
    this.closeActionsMenu();
  }

  onSaveRecruiter(save) {
    const recruiter = { ...this.state.recruiter };
    Object.assign(recruiter, save);
    this.setState({ editMode: false, recruiter });
  }

  onCancelEdit() {
    this.setState({ editMode: false });
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }
    if (this.state.loading) {
      return <Container sx={{ m: '2rem' }}>
        <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
        Loading Account...
      </Container>;
    }

    const r = this.state.recruiter;
    if (!r || r === []) {
      return <p>Unable to find recruiter</p>;
    }
    const { first_name, last_name } = r;
    const name = `${first_name || ''} ${last_name || ''}`;

    let address2 = r.city || '';
    if (address2) address2 += ',';
    if (r.state) address2 += ` ${r.state}`;
    if (r.zipcode) address2 += ` ${r.zipcode}`;
    if (r.country && !r.country.startsWith('US')) address2 += ` ${r.country}`;

    return <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: '1rem', padding:'1rem 2rem 2rem' }} disableGutters={true} maxWidth={false}>
        { !this.state.editMode &&
          <H1>My Account</H1>
        }
        { this.state.editMode &&
          <H1>Edit MyAccount</H1>
        }

        <div className="candidate-actions">
        <Button
            id="recruiter-actions-btn"
            variant="contained"
            aria-controls={this.state.open ? 'recruiter-actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={this.state.open ? 'true' : undefined}
            onClick={this.openActionsMenu}
          >
            Actions
            <ArrowDropDown sx={{ml: '0.25rem'}}/>
          </Button>
          <Menu
            id="recruiter-actions-menu"
            anchorEl={this.state.anchorEL}
            open={this.state.open}
            onClose={this.closeActionsMenu}
            MenuListProps={{
              'aria-labelledby': 'recruiter-actions-btn',
            }}
          >
            <MenuItem onClick={this.onUpdateRecruiter}>Edit My Account</MenuItem>
          </Menu>
        </div>
      </Container>

      <Container sx={{ padding:'1rem 2rem 2rem', textAlign:'left' }} disableGutters={true} maxWidth={false}>

        { !this.state.editMode &&
          <>
            <H2>{name}</H2>
            <MyAccount recruiter={this.state.recruiter} />
          </>
        }
        { this.state.editMode &&
          <EditMyAccount recruiter={this.state.recruiter} onSave={this.onSaveRecruiter} onCancel={this.onCancelEdit} />
        }
      </Container>
    </>;
  }
}

export default withParams(MyAccountPage);

