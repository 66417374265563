
export function checkStatus(result) {
  return result.json().then(json => {
    if (result.status !== 200 || json.message) {
      const error = new Error(json.message);
      error.code = result.status;
      error.name = result.statusText;
      throw error;
    }
    return json;
  });
}

export function goToLogin() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  sessionStorage.setItem('pathname', window.location.pathname);

  const loginUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/login`;
  window.location.assign(loginUrl);
}

