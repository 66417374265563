import { Component } from 'react';
import { Container, Typography } from "@mui/material";
import { red } from '@mui/material/colors';
import styled from "styled-components";

import ExpiredAttachmentsTable from '../components/ExpiredAttachmentsTable';
import { checkStatus } from '../checkStatus';
import { env } from '../env';


const H2 = styled.h2`
  margin: 0;
`;

const defaultState = {
  loading: true,
  pageSize: 50,
  pageIndex: 0,
  pageCount: 0,
  attachments: [],
  errorMessage: null,
};

export default class ExpiredAttachments extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.query = this.query.bind(this);
  }

  async componentDidMount() {
    this.query();
  }

  query() {
    const url = `${env.baseUrl}/v1/empl-attachment/expired`;
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then((result) => {
        const attachments = result.data;
        if (attachments) {
          for (const att of attachments) {
            const expiration = new Date(att.expiration);
            const now = Date.now();
            const twoWeeks = 2*7*24*60*60*1000;

            if (expiration.getTime() < now) {
              att.expired = true;

            } else if (expiration.getTime() < (now + twoWeeks)) {
              att.willExpire = true;
            }
          }
          this.setState({ attachments });
          if (this.props.onAttachments)
            this.props.onAttachments(result.data);
        } else {
          const errorMessage = result.message || 'Unable to load expired attachments';
          this.setState({ errorMessage });
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message });
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    if (this.state.loading || !this.state.attachments?.length) {
      return <></>;
    }
    return <Container sx={{ m: '1rem 0', textAlign: 'left' }} maxWidth={false}>
        <H2>Attachments Expiration</H2>

        <ExpiredAttachmentsTable
          attachments={this.state.attachments}
          fetchData={this.query}
          loading={this.state.loading}
          pageCount={this.state.pageCount}
          pageSize={this.state.pageSize}
        />
        <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
      </Container>;
  }
}

