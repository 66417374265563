import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "@mui/material";
import Checked from '@mui/icons-material/CheckBox';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import { ThumbUp, ThumbDown, Warning } from '@mui/icons-material';
import { amber, green, red } from '@mui/material/colors';

import PaginatedTable from './PaginatedTable';
import './CandidatesTable.css';


const statusStyles = {
  display : 'flex',
  alignItems: 'center',
};

export default function CandidatesTable({ candidates, fetchData, loading, totalCount, pageCount, pageSize, includeRecruiter, includePracticeAreas }) {

  const navigate = useNavigate();

  // 1. Create Columns
  const columns = React.useMemo(
     () => {
       var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;

       const cols = [{
         Header: 'Name',
         accessor: 'last_name',
         Cell: ({value, row}) => {
           const { id, first_name, last_name } = row.original;
           const url = `/candidate/${id}`;
           let name = last_name || '';
           if (first_name) name += `, ${first_name}`;
           return name ? <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} underline="hover">{name}</Link> : '';
         }
       },
       {
         Header: 'Email',
         accessor: 'email',
         Cell: ({value, row}) => {
           const { id, email, last_name } = row.original;
           const url = `/candidate/${id}`;
           return last_name ? email : <Link href={url} onClick={(evt) => {evt.preventDefault();navigate(url)}} underline="hover">{value}</Link>;
         }
       },
       {
         Header: 'Phone',
         accessor: 'phone'
       }];

       if (includePracticeAreas && width > 768) {
         cols.push({
           Header: 'PracticeAreas',
           accessor: 'practice_areas',
           Cell: ({value, row}) => {
             return row.original.practice_areas;
           }
         });
       }

       cols.push({
         Header: 'Status',
         accessor: 'status',
         Cell: ({value, row}) => {
           switch (value) {
             case 'Unknown':
             case 'Inactive':
               return <span style={statusStyles}><Unchecked sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
             case 'Hired':
             case 'FollowedUp':
               return <span style={statusStyles}><ThumbUp sx={{color: green[600], mr: '0.25rem'}}/> {value}</span>;
             case 'Offer Declined':
               return <span style={statusStyles}><ThumbDown sx={{opacity:'0.5', color: red[900], mr: '0.25rem' }}/> {value}</span>;
             case 'Need to Contact':
             case 'Needs Follow Up':
               return <span style={statusStyles}><Warning sx={{opacity:'0.5', color: amber[900], mr: '0.25rem' }}/> {value}</span>;
             case null:
               return '--';
             default:
               return <span style={statusStyles}><Checked sx={{opacity:'0.5', mr: '0.25rem'}}/> {value}</span>;
           }
         }
       });

       if (includeRecruiter === true) {
         cols.push({
           Header: 'Recruiter',
           accessor: 'recruiter_name',
           Cell: ({value, row}) => {
             const { recruiter_id, recruiter_name } = row.original;
             const url = `/recruiter/${recruiter_id}`;
             return recruiter_name ? <Link href={url} onClick={() => navigate(url)} underline="hover">{recruiter_name}</Link> : '';
           }
         });
       }

       return cols;
     }, [navigate, includeRecruiter, includePracticeAreas]
    );

  const getRowClasses = (row) => {
    return {};
  };

  // 3. Return the HTML Table
  return (<>
    <PaginatedTable
      data={candidates}
      columns={columns}
      fetchData={fetchData}
      loading={loading}
      totalCount={totalCount}
      pageCount={pageCount}
      pageSize={pageSize}
      target="Candidates"
      getRowClasses={getRowClasses}
    />
  </>);
}

