import { Component } from 'react';
import { Container, Typography } from "@mui/material";
import { red } from '@mui/material/colors';
import styled from "styled-components";

import CandidatesTable from '../components/CandidatesTable';
import { checkStatus } from '../checkStatus';
import { env } from '../env';


const H2 = styled.h2`
  margin: 0;
`;

const defaultState = {
  candidates: [],
  searchText: null,
  loading: true,
  pageIndex: 0,
  pageSize: 50,
  pageCount: 0,
  errorMessage: null,
};

export default class Candidates extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.query = this.query.bind(this);
  }

  async componentDidMount() {
    const { pageSize, pageIndex } = this.state;
    this.query({ pageSize, pageIndex });
  }

  async query({ pageSize, pageIndex }) {
    const recruiterId = this.props.recruiterId;

    let url = null;
    if (recruiterId) {
      url = `${env.baseUrl}/v1/recruiter/${this.props.recruiterId}/candidate`;
    } else {
      url = `${env.baseUrl}/v1/candidate`;
    }

    let queryParams = '';
    if (this.state.searchText) {
      if (queryParams) queryParams += '&';
      queryParams += `search=${this.state.searchText}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }

    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(({ data }) => {
        return this.setState({ candidates: data });
      })
      .catch(error => {
        console.error(error);
        this.setState({ errorMessage: error.message });
      });
  }

  render() {
    return <Container sx={{ margin: '1rem 0 1rem' }} disableGutters={true} maxWidth={false}>
        <H2>{ this.props.title || 'Candidates' }</H2>
        <CandidatesTable
          candidates={this.state.candidates}
          loading={this.state.loading}
          fetchData={this.query}
          pageCount={this.state.pageCount}
          pageSize={this.state.pageSize}
        />
        <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>
      </Container>;
  }
}

