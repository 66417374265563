import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Container, InputAdornment, TextField, Typography } from '@mui/material';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import styled from 'styled-components';

import ExclusiveTable from '../components/ExclusiveTable';
import { checkStatus, goToLogin } from '../checkStatus';
import { env } from '../env';


const defaultState = {
  searchText: '',
  loading: true,
  totalCount: 0,
  pageIndex: 0,
  pageSize: 1000,
  pageCount: 0,
  listings: [],
  openDeleteDialog: false,
  listing: null,
  listingIndex: -1,
  errorMessage: null,
};

const H1 = styled.h1`
  margin-top: 1rem;
`;
const SearchForm = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

export default class ExclusiveListPage extends Component {

  constructor() {
    super();
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.query = this.query.bind(this);
    this.onEditContact = this.onEditContact.bind(this);
    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.onDeleteConfirmationClose = this.onDeleteConfirmationClose.bind(this);
    this.onDeleteContact = this.onDeleteContact.bind(this);
  }

  async componentDidMount() {
    const { pageSize, pageIndex } = this.state;
    this.query({ pageSize, pageIndex });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  query({ pageSize, pageIndex }) {
    this.setState({ pageSize, pageIndex, loading: true });

    let url = `${env.baseUrl}/v1/exclusive`;
    let queryParams = '';
    if (this.state.searchText) {
      if (queryParams) queryParams += '&';
      queryParams += `search=${this.state.searchText}`;
    }
    if (!isNaN(pageIndex)) {
      if (queryParams) queryParams += '&';
      queryParams += `pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      if (queryParams) queryParams += '&';
      queryParams += `pageSize=${pageSize}`;
    }
    if (queryParams) {
      url += '?'+queryParams;
    }
    fetch(url, {credentials:'include'})
      .then(result => checkStatus(result))
      .then(result => {
        if (result.data) {
          const listings = result.data;
          const totalCount = listings?.length || 0;
          const pageCount = Math.ceil(totalCount / this.state.pageSize);
          this.setState({ listings, totalCount, pageCount, errorMessage: '' });
        }
      })
      .catch(error => {
        console.error(error);
        if (error.code === 401) {
          return goToLogin();
        }
        this.setState({ errorMessage: error.message || 'Unable to load exclusive-listings', error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onSearch(event) {
    if (event) event.preventDefault();
    const { pageSize, pageIndex } = this.state;
    this.query({ pageSize, pageIndex });
  }

  onClearSearch() {
    this.setState({ searchText: '' }, this.onSearch);
  }

  onDeleteConfirmation(event, listing, listingIndex) {
    this.setState({ openDeleteDialog: true, listing, listingIndex });
  };

  onDeleteConfirmationClose() {
    this.setState({ openDeleteDialog: false, listing: null, listingIndex: -1 });
  }

  onEditContact(event, listing, listingIndex) {
    this.setState({ forward: `/exclusive/${listing.id}` });
  }

  onDeleteContact() {
    const listingId = this.state.listing.id;
    const url = `${env.baseUrl}/v1/exclusive/${listingId}`;
    fetch(url, {
        method: 'DELETE',
        body: '{}',
        headers:{ 'Content-Type':'application/json' },
        credentials: 'include',
    })
      .then(result => checkStatus(result))
      .then(response => {
        if (response.data.rowCount === 1) {
          const listings = [...this.state.listings];
          listings.splice(this.state.listingIndex, 1);
          const totalCount = listings?.length || 0;
          const pageCount = Math.ceil(totalCount / this.state.pageSize);
          this.setState({ listings, totalCount, pageCount, errorMessage: '' });
          this.onDeleteConfirmationClose();
        }
      });
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }

    return <>
      <Container component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '1rem 0' }} maxWidth={false}>
        <H1>Exclusive Listings and Firmwide Job Orders</H1>

        <SearchForm>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={this.onSearch}
            sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
          >
            <TextField name="searchText" value={this.state.searchText} label="Search by Employer" variant="outlined" fullWidth onChange={this.handleInputChange}
                sx={{ minWidth: '15rem' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Clear onClick={this.onClearSearch} sx={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  )
                }}
            />
            <Button type="submit" variant="contained" sx={{ padding: '0.5rem 2rem' }}>Search</Button>
          </Box>
        </SearchForm>
      </Container>

      <Container component="div" maxWidth={false} sx={{ textAlign:'left' }}>These are employers/firms where we have exclusive placements.</Container>

      <Container sx={{ mt: '1rem', pb: '2rem', textAlign: 'left' }} maxWidth={false}>

        <ExclusiveTable
          listings={this.state.listings}
          loading={this.state.loading}
          fetchData={this.query}
          pageCount={this.state.pageCount}
          pageSize={this.state.pageSize}
          totalCount={this.state.totalCount}
          onEdit={this.onEditContact}
          onDelete={this.onDeleteConfirmation}
        />

        { this.state.loading && 
          <Container sx={{ m: '2rem' }} disableGutters={true}>
            <CircularProgress size="1rem" sx={{mr:'0.5rem'}} />
            Loading Exclusive Listings...
          </Container>
        }
        <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.errorMessage }</Typography>

      </Container>

      <Dialog open={this.state.openDeleteDialog} onClose={this.onDeleteConfirmationClose}>
        <DialogTitle sx={{ textAlign: 'left' }}>Delete from Exclusive Listings</DialogTitle>
        <DialogContent sx={{ textAlign: 'left' }}>
          <DialogContentText>
            Do you want to delete <b>{this.state.listing?.company || 'Employer'}</b> from Exclusive Listings?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onDeleteContact} color="primary" variant="contained" size="small">Delete</Button>
          <Button onClick={this.onDeleteConfirmationClose} color="primary" variant="outlined" size="small" autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>

    </>;
  }
}

