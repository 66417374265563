import React, { Component } from 'react';
import { red } from '@mui/material/colors';
import { Box, Button, Checkbox, Container, FormControlLabel, Link, TextField, Typography } from "@mui/material";
import { Add, Remove } from '@mui/icons-material';
import { useNavigate, Navigate } from "react-router-dom";
import { useParams } from "react-router";
import styled from "styled-components";

import { env } from '../env';
import { checkStatus, goToLogin } from '../checkStatus';

const H2 = styled.h2`
  margin: 1rem 0 0 !important;
`;
const H3 = styled.h3`
  margin: 1rem 0 0;
`;
const P = styled.p`
  margin: 0 !important;
`;

export const MoreButton = (props) => (
  <Button onClick={props.onClick} disabled={props.disabled} size="small" variant="outlined" startIcon={<Add/>} >More</Button>
);
export const LessButton = (props) => (
  <Button onClick={props.onClick} disabled={props.disabled} size="small" variant="outlined" startIcon={<Remove/>} >Less</Button>
);

const defaultState = {
  office_name: null,

  numEmails: 1,
  email_to: null,
  first_name: null,
  last_name: null,
  email_to2: null,
  first_name2: null,
  last_name2: null,
  email_to3: null,
  first_name3: null,
  last_name3: null,
  email_to4: null,
  first_name4: null,
  last_name4: null,

  description: null,
  address: null,
  city: null,
  state: null,
  zipcode: null,
  country: 'USA',
  email_cc: null,
  email_bcc: null,
  phone: null,
  practice_areas: null,
  online_subs: null,
  blind_feelers: null,
  notes: null,

  successMessage: null,
  officeNameError: null,
  forward: null,
  errorMessage: null,
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const BackButton = (props) => {
  const navigate = useNavigate();
  return (
    <Link href={props.url} onClick={(evt) => {evt.preventDefault();navigate(props.url)}} underline="hover">Back to {props.name}</Link>
  )
}

class AddOfficePage extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBooleanChange = this.handleBooleanChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAddAnother = this.onAddAnother.bind(this);
    this.onRemoveCoordinator = this.onRemoveCoordinator.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name)
      this.setState({ [name]: value });
  }

  handleBooleanChange(event) {
    const { name, checked } = event.target;
    if (name)
      this.setState({ [name]: checked });
  }

  validate() {
    if (!this.state.office_name) {
      this.setState({ officeNameError: 'Office Name is required' });
      return false;
    } else
      this.setState({ officeNameError: null });
    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      const payload = {
        office_name: this.state.office_name?.trim(),

        first_name: this.state.first_name?.trim(),
        last_name: this.state.last_name?.trim(),
        email_to: this.state.email_to?.trim(),

        address: this.state.address?.trim(),
        city: this.state.city?.trim(),
        state: this.state.state?.trim(),
        zipcode: this.state.zipcode?.trim(),
        country: this.state.country?.trim(),
        email_cc: this.state.email_cc?.trim(),
        email_bcc: this.state.email_bcc?.trim(),
        phone: this.state.phone?.trim(),

        description: this.state.description,
        practice_areas: this.state.practice_areas,
        online_subs: this.state.online_subs,
        blind_feelers: this.state.blind_feelers,
        notes: this.state.notes,
      };

      if (this.state.email_to2) {
        payload.first_name2 = this.state.first_name2?.trim();
        payload.last_name2 = this.state.last_name2?.trim();
        payload.email_to2 = this.state.email_to2?.trim();
      }
      if (this.state.email_to3) {
        payload.first_name3 = this.state.first_name3?.trim();
        payload.last_name3 = this.state.last_name3?.trim();
        payload.email_to3 = this.state.email_to3?.trim();
      }
      if (this.state.email_to4) {
        payload.first_name4 = this.state.first_name4?.trim();
        payload.last_name4 = this.state.last_name4?.trim();
        payload.email_to4 = this.state.email_to4?.trim();
      }

      const url = `${env.baseUrl}/v1/employer/${this.props.params.id}/office`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        credentials: 'include',
      })
        .then(response => checkStatus(response))
        .then(value => {
          const forward = `/employer/${this.props.params.id}`;
          this.setState({ forward });
        })
        .catch(error => {
          console.error(error);
          if (error.code === 401) {
            return goToLogin();
          }
          this.setState({ errorMessage: error.message || 'Add office failed' });
        });
    }
  }

  onAddAnother() {
    this.setState({ numEmails: this.state.numEmails + 1 });
  }
  onRemoveCoordinator() {
    if (this.state.numEmails > 1) {
      this.setState({ numEmails: this.state.numEmails - 1 });
    }
  }

  render() {
    if (this.state.forward) {
      return <Navigate to={this.state.forward} />;
    }

    const backUrl = `/employer/${this.props.params.id}`;
    return <>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0 0', lg: '2rem 0 0', xl: '2rem 0 0' },
        margin: '0 auto 1rem',
        textAlign: 'left'
      }}>
        <h1>Add Office</h1>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField name="office_name" label="Office Name" variant="outlined" required fullWidth onChange={this.handleInputChange} error={!this.state.office_name} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.officeNameError }</Typography>

          <H2>Recruiting Coordinators</H2>
          <P>You can add up to four recruiting coordinators that will get sent as email to.</P>
          <Container sx={{ '& > :not(style)': { m: '0.25rem 0' } }} disableGutters={true}>
            <TextField name="email_to" label="Email To" placeholder="Email To" variant="outlined" fullWidth required onChange={this.handleInputChange} error={!this.state.email_to || !this.state.email_to.includes('@')} />
            <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true} >
              <TextField name="first_name" label="First name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
              <TextField name="last_name" label="Last name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
            </Container>
            <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }} disableGutters={true}>
              <TextField name="first_name" label="First name" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%' }} />
              <TextField name="last_name" label="Last name" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%', ml: '1.65rem !important' }} />
            </Container>
          </Container>

          { this.state.numEmails > 1 &&
            <Container sx={{ '& > :not(style)': { m: '0.25rem 0' } }} disableGutters={true}>
              <H3>Recruiting Coordinator #2</H3>
              <TextField name="email_to2" label="Email To #2" placeholder="Email To" variant="outlined" fullWidth onChange={this.handleInputChange} error={this.state.email_to2 && !this.state.email_to2.includes('@')} />
              <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true} >
                <TextField name="first_name2" label="First name #2" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
                <TextField name="last_name2" label="Last name #2" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
              </Container>
              <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }} disableGutters={true}>
                <TextField name="first_name2" label="First name #2" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%' }} />
                <TextField name="last_name2" label="Last name #2" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%', ml: '1.65rem !important' }} />
              </Container>
            </Container>
          }

          { this.state.numEmails > 2 &&
            <Container sx={{ '& > :not(style)': { m: '0.25rem 0' } }} disableGutters={true}>
              <H3>Recruiting Coordinator #3</H3>
              <TextField name="email_to3" label="Email To #3" placeholder="Email To" variant="outlined" fullWidth onChange={this.handleInputChange} error={this.state.email_to3 && !this.state.email_to3.includes('@')} />
              <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true} >
                <TextField name="first_name3" label="First name #3" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
                <TextField name="last_name3" label="Last name #3" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
              </Container>
              <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }} disableGutters={true}>
                <TextField name="first_name3" label="First name #3" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%' }} />
                <TextField name="last_name3" label="Last name #3" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%', ml: '1.65rem !important' }} />
              </Container>
            </Container>
          }

          { this.state.numEmails > 3 &&
            <Container sx={{ '& > :not(style)': { m: '0.25rem 0' } }} disableGutters={true}>
              <H3>Recruiting Coordinator #4</H3>
              <TextField name="email_to4" label="Email To #4" placeholder="Email To" variant="outlined" fullWidth onChange={this.handleInputChange} error={this.state.email_to4 && !this.state.email_to4.includes('@')} />
              <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true} >
                <TextField name="first_name4" label="First name #4" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
                <TextField name="last_name4" label="Last name #4" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
              </Container>
              <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }} disableGutters={true}>
                <TextField name="first_name4" label="First name #4" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%' }} />
                <TextField name="last_name4" label="Last name #4" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%', ml: '1.65rem !important' }} />
              </Container>
            </Container>
          }

          <Container sx={{ display:'flex', gap:'0.5rem' }} disableGutters={true}>
            <MoreButton onClick={this.onAddAnother} disabled={this.state.numEmails >= 4} />
            <LessButton onClick={this.onRemoveCoordinator} disabled={this.state.numEmails <= 1} />
          </Container>
          <p>&nbsp;</p>

          <TextField name="email_cc" label="Email CC" placeholder="Email CC (comma separated)" variant="outlined" fullWidth onChange={this.handleInputChange} error={this.state.email_cc && !this.state.email_cc.includes('@')} />
          <TextField name="email_bcc" label="Email BCC" placeholder="Email BCC (comma separated)" variant="outlined" fullWidth onChange={this.handleInputChange} error={this.state.email_bcc && !this.state.email_bcc.includes('@')} />

          <TextField name="practice_areas" label="Practice Areas" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="address" label="Address" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="city" label="City" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="state" label="State/Province" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="zipcode" label="Zipcode" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="country" label="Country" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="phone" label="Phone" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <div><FormControlLabel control={<Checkbox name="online_subs" onChange={this.handleBooleanChange}/>} label="Online Subs?" /></div>
          <div><FormControlLabel control={<Checkbox name="blind_feelers" onChange={this.handleBooleanChange}/>} label="Blind Feelers?" /></div>

          <TextField name="description" label="Description" variant="outlined" minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />
          <TextField name="notes" label="Notes" variant="outlined" multiline minRows="4" maxRows="20" fullWidth onChange={this.handleInputChange} />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', color: red[900] }}>
            <Button type="submit" variant="contained">Add Office</Button>
            <Typography>{ this.state.errorMessage }</Typography>
          </Box>

          <BackButton url={backUrl} name="Employer" />
        </Box>

      </Container>
      </>;

  }
}

export default withParams(AddOfficePage);

